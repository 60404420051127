import { TestStatusResponse } from 'services/tenantManagementService';
import { TEST_CASE_STATUSES_FETCHING, TEST_CASE_STATUSES_SET } from './action';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';

export const testCaseStatusReducer = (state = new PersistItemsReducer<TestStatusResponse>(), action: PersistItemsActionType<TestStatusResponse> | FetchingActionType): PersistItemsReducer<TestStatusResponse> => {
	switch (action.type) {
		case TEST_CASE_STATUSES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TEST_CASE_STATUSES_SET:
			const { items } = action as PersistItemsActionType<TestStatusResponse>;

			const itemsMap: { [id: number]: TestStatusResponse } = items.reduce(
                (map: { [id: number]: TestStatusResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

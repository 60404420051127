import { useCallback, useMemo } from 'react';
import { PersistActiveItemsReducer, PersistItemsReducer, PersistProjectItemsReducer, PersistValueReducer } from 'features/Persist/reducerTypes';
import { BusinessPartnerResponseModel, IdNameResponse, ImpactResponse, PriorityResponse, ProjectIsActiveResponse, ProjectResponse, ProjectTeamMemberResponse, TenantIsActiveResponse, TenantResponseModel, TicketAssignedGroupsResponse, TicketCategoryResponse, UrgencyResponse, UserModel } from 'services/tenantManagementService';
import { StatusResponse } from 'features/StatusResponse/statusResponse';
import { colorToHex } from 'utils/colorHelper';
import { getPriority } from 'features/Priority/helper';
import { EntityPrefixEnum, getFormatedId } from 'utils/commonHelper';
import { formatCurrency } from 'utils/currencyUtils';
import { useSelector } from 'react-redux';
import { RootState } from 'base/reducer/reducer';
import { MapItem, convertToAbsoluteName, getSelectedItem } from 'components/Form/controls/MapPicker/MapPicker/helpers';
import { removeUndefinedItemsFromArray } from 'utils/arrayUtil';

export const useUsernameCallback = (persistedModel: PersistActiveItemsReducer<UserModel>) => {
	return useCallback(
		(id: number | undefined) => {
			const user = id && persistedModel.itemsMap[id];
			return user ? user.username : '';
		},
		[persistedModel]
	)
}

export const useUserFullNameCallback = (persistedModel: PersistActiveItemsReducer<UserModel>) => {
	return useCallback(
		(id: number | undefined) => {
			const user = id && persistedModel.itemsMap[id];
			return user ? `${user.firstName} ${user.lastName}` : '';
		},
		[persistedModel]
	)
}

export const useCountryCallback = (persistedModel: PersistItemsReducer<IdNameResponse>) => {
	return useCallback(
		(id: number | undefined) => {
			const country = id && persistedModel.itemsMap[id];
			return country ? country.name : '';
		},
		[persistedModel]
	)
}

export const useOrganizationalUnitCallback = () => {
	return useCallback(
		(id: number | undefined, options: MapItem[] = []) => {
			const selectedOption = getSelectedItem(id, options);
			if (selectedOption) {
				return convertToAbsoluteName(selectedOption, options);
			}

			return '';
		},
		[]
	)
}

export const useUserIdCallback = (persistedModel: PersistProjectItemsReducer<ProjectTeamMemberResponse>) => {
	return useCallback(
		(id: number) => {
			const member = persistedModel.itemsMap[id];
			return member && member.userId;
		},
		[persistedModel]
	)
}

export const useProjectIsActiveNameCallback = (persistedModel: PersistProjectItemsReducer<ProjectIsActiveResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useTenantIsActiveCallback = (persistedModel: PersistItemsReducer<TenantIsActiveResponse>) => {
	return useCallback(
		(id: number | undefined) => {
			if (!id) {
				return '';
			}
			const model = persistedModel.itemsMap[id];
			return model?.name || '';
		},
		[persistedModel]
	)
}

export const useTicketAssignedGroupsCallback = (persistedModel: PersistItemsReducer<TicketAssignedGroupsResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useMaintainScopeConfigCallback = (persistedModel: PersistProjectItemsReducer<ProjectIsActiveResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useTicketCategoriesCallback = (persistedModel: PersistItemsReducer<TicketCategoryResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useEnvironmentCallback = (persistedModel: PersistActiveItemsReducer<TenantIsActiveResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useTicketImpactsCallback = (persistedModel: PersistItemsReducer<ImpactResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useTicketUrgenciesCallback = (persistedModel: PersistItemsReducer<UrgencyResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useTicketPriorityCallback = (persistedModel: PersistItemsReducer<PriorityResponse>) => {
	return useCallback(
		(impactId: number, urgencyId: number) => {
			const priority = getPriority(persistedModel.items, impactId, urgencyId);

			if (priority) {
				return priority.name;
			} else {
				return ''
			}
		},
		[persistedModel]
	)
}

export const useTicketPriorityByIdCallback = (persistedModel: PersistItemsReducer<PriorityResponse>) => {
	return useCallback(
		(id: number) => {
			const model = persistedModel.itemsMap[id];
			return model && model.name;
		},
		[persistedModel]
	)
}

export const useStatusCallback = <P extends StatusResponse>(persistedModel: PersistItemsReducer<P>) => {
	return useCallback(
		(id: number) => {
			const status = persistedModel.itemsMap[id];
			return status?.name || '';
		},
		[persistedModel]
	)
}

export const applyColor = (element: HTMLElement | undefined, color: string | undefined) => {
	if (!element || !color) {
		return;
	}
	const hexColor = colorToHex(color)!;
	element.style.color = hexColor;
	element.style.fontWeight = 'bold';
}

export const useStatusColorCallback = <P extends StatusResponse>(persistedModel: PersistItemsReducer<P>) => {
	return useCallback(
		(id: number) => {
			const status = persistedModel.itemsMap[id];
			return status && status.color;
		},
		[persistedModel]
	)
}

export const useApplyStatusColorCallback = <P extends StatusResponse>(persistedModel: PersistItemsReducer<P>) => {
	return useCallback(
		(id: number, element: HTMLElement) => {
			const status = persistedModel.itemsMap[id];
			applyColor(element, status?.color);
		},
		[persistedModel]
	)
}

export const useIdNameCallback = (persistedModel: PersistItemsReducer<IdNameResponse>) => {
	return useCallback(
		(id: number) => {
			const idName = persistedModel.itemsMap[id];
			return idName && idName.name;
		},
		[persistedModel]
	)
}

// pdf export library is implemented in a way it doesn't see our redux Provider, so this method is added in those cases
export const useFormattedCurrencyValueWithArgsCallback = (persistedCurrency: PersistItemsReducer<IdNameResponse>, persistedTenant: PersistValueReducer<TenantResponseModel>) => {
	return useCallback(
		(value: number | undefined, showCurrencySymbol?: boolean) => {
			const currencyId = persistedTenant.value.currencyId;
			const idName = persistedCurrency.itemsMap[currencyId];
			const symbol = showCurrencySymbol && idName && idName.symbol;
			const formattedValue = formatCurrency(value) || '';

			if (value !== undefined && symbol) {
				return `${formattedValue} ${symbol}`
			} else if (value !== undefined) {
				return formattedValue;
			} else {
				return ''
			}
		},
		[persistedCurrency, persistedTenant]
	)
}

export const useFormattedCurrencyValueCallback = () => {
	const { persistedCurrency, persistedTenant } = useSelector((state: RootState) => state);

	return useFormattedCurrencyValueWithArgsCallback(persistedCurrency, persistedTenant)
}

export const useFormattedCurrencyValueByIdCallback = () => {
	const { persistedCurrency } = useSelector((state: RootState) => state);

	return useCallback(
		(value: number | undefined, currencyId: number | undefined) => {
			const idName = currencyId ? persistedCurrency.itemsMap[currencyId] : undefined;
			const symbol = idName && idName.symbol;
			const formattedValue = formatCurrency(value);

			if (value !== undefined && symbol) {
				return formattedValue;
			} else if (value !== undefined) {
				return formattedValue;
			} else {
				return ''
			}
		},
		[persistedCurrency]
	)
}

export const useCurrencySymbolByIdCallback = (persistedCurrency: PersistItemsReducer<IdNameResponse>) => {
	return useCallback(
		(currencyId: number | undefined) => {
			if (currencyId === undefined) {
				return '';
			}

			const idName = persistedCurrency.itemsMap[currencyId];
			return idName && ` ${idName.symbol}`;
		},
		[persistedCurrency]
	)
}

export const usePartnerCallback = (persistedModel: PersistItemsReducer<BusinessPartnerResponseModel>) => {
	return useCallback(
		(id: number) => {
			const partner = persistedModel.itemsMap[id];
			return partner && partner.name;
		},
		[persistedModel]
	)
}

export const useActiveTeamMembersMemo = (persistedTeamMember: PersistProjectItemsReducer<ProjectTeamMemberResponse>, persistedUser: PersistActiveItemsReducer<UserModel>, projectId: number, userId?: number, userIds?: number[]) => {
	const getUserId = useUserIdCallback(persistedTeamMember);
	return useMemo(
		() => {
			const activeTeamMemberUsers = persistedTeamMember.projectMap[projectId]?.items.filter(tm => tm.isActive).map(tm => persistedUser.activeItemsMap[getUserId(tm.id)!]) || [];
			const filteredActiveTeamMemberUsers = removeUndefinedItemsFromArray(activeTeamMemberUsers);

			// user can be blocked in meantime, so we need to find it and display it in Select
			if (userId) {
				const user = persistedUser.itemsMap[userId];
				if (user) {
					if (!filteredActiveTeamMemberUsers.find(item => item.id === user.id)) {
						filteredActiveTeamMemberUsers.push(user);
					}
				}
			}

			// users can be blocked...
			if (userIds && userIds.length > 0) {
				for (const userId of userIds) {
					const user = persistedUser.itemsMap[userId];
					if (user) {
						if (!filteredActiveTeamMemberUsers.find(item => item.id === user.id)) {
							filteredActiveTeamMemberUsers.push(user);
						}
					}
				}
			}

			return filteredActiveTeamMemberUsers;
		},
		[persistedTeamMember.projectMap, getUserId, persistedUser, projectId, userId, userIds]
	)
}

export const useFormattedProjectIdNameCallback = (persistedProject: PersistItemsReducer<ProjectResponse>) => {
	return useCallback(
		(id: number) => {
			const project = persistedProject.itemsMap[id];
			return project ?
				`${getFormatedId(EntityPrefixEnum.PROJECT, id)} - ${project.name}` :
				getFormatedId(EntityPrefixEnum.PROJECT, id);
		},
		[persistedProject]
	)
}

import { getLinks, linkFormatterUrlParam, linksCellFormatterElementsIdPrefix, oneLinkHeight } from './utils';

const containerPadding = 8

const DEFAULT_FORMATTER_PARAMS = {
	getLinks: getLinks,
	getLinkTitle: link => link,
	getLinkUrl: link => link,
	linkTarget: '_blank',
}

const createATag = (formatterParams, link, index, cell) => {
	const a = document.createElement("a");
	a.id = `${linksCellFormatterElementsIdPrefix}_link_${index}`;
	a.setAttribute('style', `overflow: hidden; text-overflow: ellipsis;`);
	a.href = linkFormatterUrlParam(formatterParams.getLinkUrl(link));
	if (formatterParams.linkTarget) {
		a.target = '_blank';
	}
	a.innerHTML = formatterParams.getLinkTitle(link);
	a.onclick = e => {
		e.stopPropagation();
		formatterParams.onClickLink && formatterParams.onClickLink(e, link, cell);
	};

	return a;
}

const createRow = (formatterParams, link, index, editorDisabled, paddingTop, paddingBottom, cell, direction) => {
	const row = document.createElement("div");
	row.id = `${linksCellFormatterElementsIdPrefix}_row_${index}`;
	row.setAttribute('title', formatterParams.getLinkTitle(link));

	if (direction === 'row') {
		row.setAttribute('style', `padding-right: ${paddingBottom}px; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;`);
	} else {
		row.setAttribute('style', `width: 100%; height: ${oneLinkHeight - 2 * containerPadding + paddingTop + paddingBottom}px; padding-top: ${paddingTop}px; padding-bottom: ${paddingBottom}px; display: flex; flex-direction: row;`);
	}

	const a = createATag(formatterParams, link, index, cell);
	row.appendChild(a);

	if (!editorDisabled) {
		const filler = document.createElement("div");
		filler.id = `${linksCellFormatterElementsIdPrefix}_filler_${index}`;
		filler.setAttribute('style', 'display: flex; flex: 1 1 0; min-width: 30px');
		row.appendChild(filler);
	}

	return row;
}

export const linksCellFormatter = (cell, formatterParams = DEFAULT_FORMATTER_PARAMS, onRendered, editorDisabled, direction = 'column') => {
	const fParams = {
		...DEFAULT_FORMATTER_PARAMS,
		...formatterParams,
	}

	const value = cell.getValue();
	const links = fParams.getLinks(value);

	const container = document.createElement("div");
	container.id = `${linksCellFormatterElementsIdPrefix}_container`;
	container.setAttribute('style', `width: 100%; height: 100%; display: flex; flex-direction: ${direction};`);

	links.forEach((link, index) => {
		const paddingTop = index === 0 ? 0 : containerPadding
		const paddingBottom = index === links.length - 1 ? 0 : containerPadding
		container.appendChild(createRow(fParams, link, index, editorDisabled, paddingTop, paddingBottom, cell, direction));
	});

	return container;
}

import styles from './shorten.module.scss';

type Props = {
	children: any
	title?: string
}

export const Shorten = ({ title, children }: Props) => {
	return (
		<div className={styles.shorten} title={title}>
			{children}
		</div>
	)
}

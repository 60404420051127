import store from "base/reducer/store";
import { FetchingProjectValueActionType, PersistProjectValueActionType } from "features/Persist/actionTypes";
import { getTenantId } from "features/Tenant/tenantUtils";
import { TimesheetRequiredFieldsClient, TimesheetRequiredFieldsRequest, TimesheetRequiredFieldsResponse } from "services/tenantManagementService";
import { tryCatchJsonByAction } from "utils/fetchUtils";

export const getTimesheetRequiredFieldsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetRequiredFieldsClient();
	return client.get(tenantId, projectId);
}

export const updateTimesheetRequiredFieldsAction = (projectId: number, model: TimesheetRequiredFieldsRequest | undefined) => {
	const tenantId = getTenantId()!;
	const client = new TimesheetRequiredFieldsClient();
	return client.update(tenantId, projectId, model);
}

export const persistTimesheetRequiredFieldsAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedTimesheetRequiredFields.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setTimesheetRequiredFieldsFetchingAction(true, projectId));

	const bindedAction = getTimesheetRequiredFieldsAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success) {
		store.dispatch(setTimesheetRequiredFieldsAction(projectId, response.value || new TimesheetRequiredFieldsResponse()));
	} else {
		store.dispatch(setTimesheetRequiredFieldsFetchingAction(false, projectId));
	}
}

// redux
export const TIMESHEET_REQUIRED_FIELDS_FETCHING = 'TIMESHEET_REQUIRED_FIELDS_FETCHING';
export const TIMESHEET_REQUIRED_FIELDS_SET = 'TIMESHEET_REQUIRED_FIELDS_SET';

const setTimesheetRequiredFieldsFetchingAction = (fetching: boolean, projectId: number): FetchingProjectValueActionType => {
	return {
		type: TIMESHEET_REQUIRED_FIELDS_FETCHING,
		fetching,
		projectId
	}
}

const setTimesheetRequiredFieldsAction = (projectId: number, value: TimesheetRequiredFieldsResponse): PersistProjectValueActionType<TimesheetRequiredFieldsResponse> => {
	return {
		type: TIMESHEET_REQUIRED_FIELDS_SET,
		value,
		projectId
	}
}

import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { getCssVariableValue } from 'utils/cssVariablesUtils';
import Element, { ElementType } from './Element';

interface ElementListProps {
	isDraggingOver: boolean
	activeColor: string
}

const ElementList = styled.div<ElementListProps>`
	transition: background-color 0.2s ease;
	background-color: ${(props) => (props.isDraggingOver ? props.activeColor : 'unset')};
	min-height: 300px;
	height: 100%;
`;

export class ColumnType {
	id: string = '';
	title: string = '';
	elementIds: Array<string> = [];

	constructor(column?: ColumnType) {
		if (column) {
			this.id = column.id;
			this.title = column.title;
			this.elementIds = [...column.elementIds];
		}
	}
}

export type ColumnKeyValuePairType = {
	[key: string]: ColumnType;
};

type Props = {
	column: ColumnType;
	elements: Array<ElementType>;
};

const Column = ({ column, elements }: Props) => {
	const activeColor = getCssVariableValue('--dragndrop-over-active-color');

	return (
		<>
			<h5>{column.title}</h5>
			<Droppable droppableId={column.id}>
				{(provided, snapshot) => (
					<ElementList
						ref={provided.innerRef}
						{...provided.droppableProps}
						isDraggingOver={snapshot.isDraggingOver}
						activeColor={activeColor}
					>
						{elements.map((element, index) => (
							<Element key={element.id} data={element} index={index} />
						))}
					</ElementList>
				)}
			</Droppable>
		</>
	);
};

export default Column;

import { Subtract } from 'utility-types';
import { MapMultiPicker, MapMultiPickerProps } from '../controls';
import { Field, FieldProps, ControlsCommonProps } from './Field';

export const MapMultiField = (props: Subtract<MapMultiPickerProps, ControlsCommonProps<number[] | undefined>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={MapMultiPicker}
		/>
	)
}

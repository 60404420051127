import { FetchingActionType } from 'features/Persist/actionTypes';
import { PersistValueReducer } from 'features/Persist/reducerTypes';
import { SET_CONFIGURE_VIEW, CONFIGURE_VIEW_FETCHING, ConfigureViewActionType, SET_CONFIGURE_VIEW_TABLE, ConfigureViewTableActionType } from './action';

export const configureViewReducer = (state = new PersistValueReducer<ConfigureViewType>(), action: ConfigureViewActionType | ConfigureViewTableActionType | FetchingActionType): PersistValueReducer<ConfigureViewType> => {
	switch (action.type) {
		case CONFIGURE_VIEW_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case SET_CONFIGURE_VIEW_TABLE:
			const { key, visibleColumns } = action as ConfigureViewTableActionType;

			return {
				value: {
					...state.value,
					[key]: visibleColumns,
				},
				fetching: false,
				isInitialized: true
			}
			case SET_CONFIGURE_VIEW:
				const { configureView } = action as ConfigureViewActionType;

				return {
					value: configureView,
					fetching: false,
					isInitialized: true
				}
		default:
			return state;
	}
};

export type ConfigureViewType = {
	[key: string]: string[]
}

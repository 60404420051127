import { useMemo, useCallback, useState, useEffect } from 'react';
import { Input, InputNumber } from '../';
import { ControlsCommonProps } from '../../fields';
import { convertTimeToTicks, availableMinutes, convertTicksToTime } from 'utils/dateTimeUtils';
import styles from './timeSpan.module.scss';
import { Select } from '../Select/Select';
import { Cover } from 'components/Cover';

export type TimeSpanProps = ControlsCommonProps<number | undefined> & {
	placeholder?: string
	min?: number
	max?: number
	focus?: boolean
	editor?: boolean
};

export const TimeSpan = ({ value, onChange, onBlur, disabled, placeholder, min = 0, max, focus = false, editor = false }: TimeSpanProps) => {
	const { hours, minutes, displayValue } = useMemo(
		() => {
			if (value === undefined) {
				return {
					hours: 0,
					minutes: 0,
					displayValue: '',
				};
			} else {
				const { hours, minutes} = convertTicksToTime(value);

				const minutesString = String(minutes).padStart(2, '0');
				const hoursString = String(hours).padStart(2, '0');

				return {
					hours,
					minutes,
					displayValue: `${hoursString}:${minutesString}`,
				}
			}
		},
		[value]
	)

	const [open, setOpen] = useState(focus);

	// on handleMinutesChanged we want to call onChange and onBlur
	// problem is if parent container has some extra logic in onBlur handler and
	// onChange is usually some useState setter that is asynchronous so onBlur is not aware of last value
	const [blurred, setBlurred] = useState(false);

	const openDropdownCallback = useCallback(
		() => {
			if (disabled) {
				return;
			}

			setOpen(true);
			setBlurred(false);
		},
		[disabled]
	);

	const closeDropdownCallback = useCallback(
		() => {
			setOpen(false);
			setBlurred(true);
		},
		[]
	);

	useEffect(
		() => {
			if (blurred && onBlur) {
				onBlur();
			}
		},
		[blurred, onBlur]
	)

	const handleHoursChanged = useCallback(
		(newHours) => {
			const ticks = convertTimeToTicks(newHours, minutes);
			if (isNaN(ticks)) {
				return;
			}
			onChange && onChange(ticks);
		},
		[onChange, minutes]

	)

	const handleMinutesChanged = useCallback(
		(newMinutes: number) => {
			const ticks = convertTimeToTicks(hours, newMinutes);
			onChange && onChange(ticks);
			closeDropdownCallback();
		},
		[onChange, hours, closeDropdownCallback]
	)

	return (
		<div className={styles.container} onClick={openDropdownCallback}>
			<Input
				value={displayValue}
				disabled={disabled}
				placeholder={placeholder}
				hideMaxLength
			/>

			{open && <Cover onClick={closeDropdownCallback} transparent />}
			<div className={`${styles.dropdown} ${open ? styles.open : ''} ${editor ? styles.editor : ''}`} >
				<div className={styles.time_span}>
					<InputNumber
						value={hours}
						min={min}
						max={max}
						onChange={handleHoursChanged}
					/>
					<Select
						value={minutes}
						items={availableMinutes}
						getItemId={(item: number) => item}
						getItemText={(item: number) => String(item).padStart(2, '0')}
						onChange={handleMinutesChanged}
					/>
				</div>
			</div>
		</div>
	)
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { GenericFilterModel, GenericFilterModelCollection, GenericSortModel } from "services/tenantManagementService"
import { Subject } from 'rxjs';
import { fixDateFilters } from "utils/dateTimeUtils";
import { tryCatchJsonByAction } from "utils/fetchUtils";
import { BaseColumnModel, BaseTable, defaultPaginationSize } from "../BaseTable";
import { capitalizeFirstLetter } from "utils/stringUtil";
import { emptyArray } from "utils/commonHelper";

const emptyGenericSortModel = new GenericSortModel()

type Props = {
	columns: BaseColumnModel[]
	filtersModel:  GenericFilterModelCollection
	fetchFunction: (genericFilter: GenericFilterModelCollection) => Promise<any>
	rowSelectionChanged?: (data: any[]) => void
	filtersModelChanged: (filtersModel: GenericFilterModelCollection) => void
	subscriptionTopic?: Subject<unknown>
	reorderColumns?: (newColumns: string[]) => void
	mapResponse?: (items: any) => any
	cellEdited?: (cell: any) => void
	validationFailed?: (cell: any) => void
	cellEditCancelled?: (cell: any) => void
	rowDoubleClick?: (rowData: any) => void
	isLoading?: boolean
	options?: any
	hidePagination?: boolean
}

export const RemoteTable = ({
	columns,
	filtersModel,
	filtersModelChanged,
	fetchFunction,
	rowSelectionChanged,
	subscriptionTopic,
	reorderColumns,
	mapResponse,
	cellEdited,
	validationFailed,
	cellEditCancelled,
	rowDoubleClick,
	isLoading,
	options,
	hidePagination
}: Props) => {
	const [count, setCount] = useState(0);
	const [refetching, setRefetching] = useState(false);
	const [rowsData, setRowsData] = useState(emptyArray);

	const fetchDataCallback = useCallback(
		async () => {
			setRefetching(true);
			const newFiltersModel = new GenericFilterModelCollection(filtersModel);

			// if parent component didn't set limit, set it and don't send request, this callback will be auto triggered again
			if (!newFiltersModel.limit) {
				newFiltersModel.limit = defaultPaginationSize;
				filtersModelChanged(newFiltersModel);
				return;
			}

			newFiltersModel.filters = fixDateFilters(newFiltersModel.filters)

			const bindedAction = fetchFunction.bind(null, newFiltersModel);
			const response = await tryCatchJsonByAction(bindedAction);
			if (response.success) {
				let items = response.items || emptyArray;
				if (mapResponse) {
					items = mapResponse(items);
				}
				setRowsData(items);
				setCount(response.count);
			}
			setRefetching(false);

		},
		[fetchFunction, filtersModel, mapResponse, filtersModelChanged]
	)

	useEffect(
		() => {
			const subscription = subscriptionTopic?.subscribe(fetchDataCallback);
			return () => {
				subscription?.unsubscribe();
			}
		},
		[fetchDataCallback, subscriptionTopic]
	)

	const setSortCallback = useCallback(
		(dbFilterFieldPath: string) => {
			const newFilters = new GenericFilterModelCollection(filtersModel);
			const sort = newFilters.sorts?.find(sort => sort.property === dbFilterFieldPath);
			const newSortFilter = new GenericSortModel({
				property: dbFilterFieldPath,
				isAsc: sort === undefined ? true : !sort.isAsc
			});

			newFilters.sorts = [newSortFilter];
			newFilters.offset = 0;

			filtersModelChanged(newFilters);
		},
		[filtersModel, filtersModelChanged]
	)

	const pageChangedCallback = useCallback(
		(offset: number) => {
			const newFilters = new GenericFilterModelCollection(filtersModel);
			newFilters.offset = offset;
			filtersModelChanged(newFilters);
		},
		[filtersModel, filtersModelChanged]
	)

	const setFiltersCallback = useCallback(
		(newFilters: GenericFilterModel[]) => {
			const model = new GenericFilterModelCollection(filtersModel);
			model.filters = newFilters;
			model.offset = 0;
			filtersModelChanged(model);
		},
		[filtersModel, filtersModelChanged]
	)

	const convertedColumns: BaseColumnModel[] = useMemo(
		() => {
			return columns.reduce(
				(result: BaseColumnModel[], column) => {
					if (column.visible) {
						const item: BaseColumnModel = {
							...column,
							dbFilterFieldPath: column.dbFilterFieldPath || capitalizeFirstLetter(column.field)
						}

						result.push(item)
					}

					return result;
				},
				[]
			)
		},
		[columns]
	)

	const pagination = useMemo(
		() => {
			if (hidePagination) {
				return undefined;
			}
			return {
				offset: filtersModel.offset,
				limit: filtersModel.limit,
				count,
				onChange: pageChangedCallback
			}
		},
		[hidePagination, filtersModel.offset, filtersModel.limit, count, pageChangedCallback]
	)

	return (
		<BaseTable
			fetchFunction={fetchDataCallback}
			isLoading={isLoading || refetching}
			columns={convertedColumns}
			rowsData={rowsData}
			filters={filtersModel.filters || emptyArray}
			setFilters={setFiltersCallback}
			sort={filtersModel.sorts ? filtersModel.sorts[0] : emptyGenericSortModel}
			setSort={setSortCallback}
			pagination={pagination}
			reorderColumns={reorderColumns}
			rowSelectionChanged={rowSelectionChanged}
			cellEdited={cellEdited}
			validationFailed={validationFailed}
			cellEditCancelled={cellEditCancelled}
			rowDoubleClick={rowDoubleClick}
			options={options}
		/>
	)
}

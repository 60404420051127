import { ControlsCommonProps } from "components/Form/fields"
import { useCallback, useMemo } from "react"
import { MapItem } from "./MapPicker/helpers"
import { MapPicker } from "./MapPicker/MapPicker"

export type MapSinglePickerProps = ControlsCommonProps<number | undefined> & {
	items: MapItem[]
	description?: string
	loading?: boolean
}

export const MapSinglePicker = (props: MapSinglePickerProps) => {
	const {	value, onChange, ...rest } = props;

	const initialSelected = useMemo(
		() => value ? [value] : [],
		[value]
	)

	const changeCallback = useCallback(
		(selected: number[]) => {
			if (selected.length === 1) {
				onChange && onChange(selected[0]);
			} else {
				onChange && onChange(undefined);
			}
		},
		[onChange]
	)

	return (
		<MapPicker
			{...rest}
			initialSelected={initialSelected}
			onChange={changeCallback}
			single
		/>
	)
}

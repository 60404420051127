import { Dialog } from "components/Dialog"
import { useCallback, useMemo, useState } from "react"
import { MapItem, convertToAbsoluteName, getSelectedItem } from "./helpers"
import { Item } from "./Item"
import { RowContainer } from "components/Layout"
import Button from "components/Button/Button"
import styles from './mapPicker.module.scss'
import { TreeStructureIcon } from "components/icons/icons"
import { OverlaySpinner } from "components/Spinner"
import { InfoPopup, POSITION } from "components/Popup"
import { Shorten } from "components/Shorten"

type Props = {
    disabled?: boolean | undefined
    items: MapItem[]
    description?: string | undefined
    loading?: boolean | undefined
	initialSelected: number[]
	onChange(selected: number[]): void
	single?: boolean // single or multi select
}

export const MapPicker = (props: Props) => {
	const {
		disabled, items, description, loading,
		initialSelected, onChange, single
	} = props;

	const [selected, setSelected] = useState(initialSelected);
	const [open, setOpen] = useState(false);

	const openCallback = useCallback(
		() => !disabled && setOpen(true),
		[disabled]
	)

	const closeCallback = useCallback(
		() => setOpen(false),
		[]
	)

	const selectCallback = useCallback(
		(selectedItem: MapItem) => {
			setSelected((state) => {
				if (single) {
					return [selectedItem.id];
				}

				// multi
				if (state.includes(selectedItem.id)) {
					return state.filter((item) => item !== selectedItem.id);
				} else {
					return [
						...state,
						selectedItem.id
					]
				}
			})
		},
		[single]
	)

	const changeCallback = useCallback(
		() => {
			onChange && onChange(selected);
			closeCallback();
		},
		[onChange, selected, closeCallback]
	)

	const clearCallback = useCallback(
        () => {
            setSelected([]);
            onChange && onChange([]);
            closeCallback();
        },
        [onChange, closeCallback]
    )

	const displayValue = useMemo(
		() => {
			if (selected.length === 0) {
				return '';
			} else if (selected.length === 1) {
				const selectedItem = getSelectedItem(selected[0], items);
				return selectedItem ? convertToAbsoluteName(selectedItem, items) : '';
			} else {
				return `Selected ${selected.length} items.`
			}
		},
		[selected, items]
	)

	return (
		<div className={styles.container}>
			<div className={styles.picker_container}>
				<div
					className={`${styles.picker} ${(disabled || loading) ? styles.disabled : ''}`}
					onClick={openCallback}
					title={displayValue}
				>
					<Shorten>
						{displayValue}
					</Shorten>
				</div>
				{/* arrow */}
				<div className={styles.arrow}>
					<TreeStructureIcon width={18} height={18} fill='currentColor' />
				</div>
				{/* loading */}
				{loading && <OverlaySpinner />}
			</div>

			{/* description */}
			{description &&
				<div className={styles.description_container}>
					<InfoPopup
						message={description}
						position={POSITION.TOP_CENTER}
					/>
				</div>
			}

			{/* dialog */}
			<Dialog
				open={open}
				title='Select item'
				onClose={closeCallback}
			>
				<div>
					{items.map((item) => (
						<Item
							key={item.id}
							item={item}
							onClick={selectCallback}
							selected={selected}
							expandedByDefault
						/>
					))}

					<RowContainer justifyContent="flex-end">
						<Button
							text='Select'
							onClick={changeCallback}
							disabled={disabled}
						/>
						<Button
                            text='Clear'
                            color='neutral'
                            disabled={disabled}
                            onClick={clearCallback}
                        />
						<Button
							text='Cancel'
							color='neutral'
							disabled={disabled}
							onClick={closeCallback}
						/>
					</RowContainer>
				</div>
			</Dialog>
		</div>
	)
}

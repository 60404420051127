import { TenantIsActiveDeltaRequest  , TenantIsActiveResponse, TicketConfigClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getTicketTypesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.getTicketTypes(tenantId);
}

export const updateTicketTypesAction = (ticketTypesDelta: TenantIsActiveDeltaRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.updateTicketTypes(tenantId, ticketTypesDelta);
}

export const persistTicketTypesAction = async () => {
	const state = store.getState();

	if (state.persistedTicketType.fetching) {
		return;
	}

	store.dispatch(setTicketTypesFetchingAction(true));

	const response = await tryCatchJsonByAction(getTicketTypesAction);
	if (response.success) {
		store.dispatch(setTicketTypesAction(response.items || []));
	} else {
		store.dispatch(setTicketTypesFetchingAction(false));
	}
}

// redux

export const TICKET_TYPES_FETCHING = 'TICKET_TYPES_FETCHING';
export const TICKET_TYPES_SET = 'TICKET_TYPES_SET';

const setTicketTypesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_TYPES_FETCHING,
		fetching
	}
}

const setTicketTypesAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: TICKET_TYPES_SET,
		items
	}
}

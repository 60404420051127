import { useCallback, useMemo } from 'react';
import { FormContext, IFormContext, ValidatorFunctionType } from 'components/Form';

export type ItemWrapperProps = {
	children: any
	index: number
	values: any
	errors: any
	disabled?: boolean
	onFieldChange(index: number, id: string, va: any): void
	registerValidators(index: number, id: string, validatorFunctions: Array<ValidatorFunctionType>): void
	validateField(index: number, id: string, value?: any): void
}

export const ItemWrapper = (props: ItemWrapperProps) => {
	const { children, index, disabled, values, registerValidators, errors = {}, onFieldChange, validateField } = props;

	const registerValidatorsCallback = useCallback(
		(id: string, validatorFunctions: Array<ValidatorFunctionType>) => registerValidators(index, id, validatorFunctions),
		[index, registerValidators]
	)

	const onFieldChangeCallback = useCallback(
		(id: string, value: any) => {
			onFieldChange(index, id, value);
		},
		[index, onFieldChange]
	)

	const validateFieldCallback = useCallback(
		(id: string, value?: any) => {
			validateField(index, id, value);
		},
		[index, validateField]
	)

	const context: IFormContext = useMemo(
		() => {
			return {
				values,
				errors,
				disabled,
				onFieldChange: onFieldChangeCallback,
				validateField: validateFieldCallback,
				registerValidators: registerValidatorsCallback
			}
		},
		[values, errors, disabled, validateFieldCallback, onFieldChangeCallback, registerValidatorsCallback]
	)

	return (
		<FormContext.Provider value={context}>
			{children}
		</FormContext.Provider>
	)
}

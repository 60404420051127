import { FetchingProjectItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { ProjectTeamMemberResponse } from 'services/tenantManagementService';
import { TEAM_MEMBERS_FETCHING, TEAM_MEMBERS_SET } from './action';

export const teamMembersReducer = (state = new PersistProjectItemsReducer<ProjectTeamMemberResponse>(), action: PersistProjectItemsActionType<ProjectTeamMemberResponse> | FetchingProjectItemsActionType): PersistProjectItemsReducer<ProjectTeamMemberResponse> => {
	switch (action.type) {
		case TEAM_MEMBERS_FETCHING: {
			// fetching is only for this projectId
			const { fetching, projectId } = action as FetchingProjectItemsActionType;

			// isAnyFetching is checking if fetching for any project is in progress
			const isAnyFetching = fetching || !!Object.values(state.projectMap).find((item) => item?.fetching === true)

			return {
				...state,
				fetching: isAnyFetching,
				projectMap: {
					...state.projectMap,
					[projectId]: {
						items: state.projectMap[projectId]?.items || [],
						fetching
					}
				}
			}
		}
		case TEAM_MEMBERS_SET:
			const { items, projectId } = action as PersistProjectItemsActionType<ProjectTeamMemberResponse>;

			const itemsMap = {
				...state.itemsMap,
				...items.reduce(
					(map: { [id: number]: ProjectTeamMemberResponse }, item) => {
						map[item.id] = item;
						return map;
					},
					{}
				)
			}

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					fetching: false,
					items
				}
			}

			const filteredItems = state.items.filter(x => x.projectId !== projectId);
			const newItems = [...items, ...filteredItems];

			return {
				items: newItems,
				itemsMap,
				fetching: false,
				isInitialized: true,
				projectMap
			}
		default:
			return state;
	}
};

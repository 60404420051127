import { useMemo } from 'react';
import { ControlsCommonProps } from '../../fields';
import styles from './radio.module.scss';
import { RadioItem, RadioItemType } from './RadioItem';

export type {
	RadioItemType
}

export type RadioProps = ControlsCommonProps<string | number | boolean> & {
	items: RadioItemType[]
	column?: boolean
}

export const Radio = ({ value, onChange, disabled, onBlur, items = [], column }: RadioProps) => {
	const itemsContent = useMemo(
		() => {
			return items.map((item) => {
				return (
					<RadioItem
						key={item.id.toString()}
						{...item}
						column={column}
						disabled={disabled}
					    isChecked={value === item.id}
					    onClick={onChange}
					/>
				)
			});
		},
		[value, items, onChange, disabled, column]
	)

	return (
		<div className={column ? styles.container_column : styles.container} >
			{itemsContent}
	  </div>
	)
}

import store from 'base/reducer/store';
import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { TimeTravelConfigClient, TimeTravelConfigResponse, UpdateTimeTravelConfigRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getVacationGeneralConfigAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.getVacationConfig(tenantId);
}

export const updateVacationGeneralConfigAction = (updateRequest: UpdateTimeTravelConfigRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.updateVacationConfig(tenantId, updateRequest);
}


export const persistVacationGeneralConfigurationAction = async () => {
	const state = store.getState();

	if (state.persistedVacationGeneralConfiguration.fetching) {
		return;
	}

	store.dispatch(setVacationGeneralConfigurationFetchingAction(true));

	const response = await tryCatchJsonByAction(getVacationGeneralConfigAction);
	if (response.success) {
		store.dispatch(setVacationGeneralConfigurationAction(response.value || new TimeTravelConfigResponse()));
	} else {
		store.dispatch(setVacationGeneralConfigurationFetchingAction(false));
	}
}

// redux

export const VACATION_GENERAL_CONFIGURATION_FETCHING = 'VACATION_GENERAL_CONFIGURATION_FETCHING';
export const VACATION_GENERAL_CONFIGURATION_SET = 'VACATION_GENERAL_CONFIGURATION_SET';

const setVacationGeneralConfigurationFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: VACATION_GENERAL_CONFIGURATION_FETCHING,
		fetching
	}
}

const setVacationGeneralConfigurationAction = (value: TimeTravelConfigResponse): PersistValueActionType<TimeTravelConfigResponse> => {
	return {
		type: VACATION_GENERAL_CONFIGURATION_SET,
		value
	}
}

import { ColumnKeyValuePairType } from 'components/DragNDrop/Column';
import { ElementKeyValuePairType } from 'components/DragNDrop/Element';
import { MultiListType } from 'components/DragNDrop/MultiList';
import { ConfigureViewColumnType } from './ConfigureView';
import { emptySpaceColumnId } from 'components/Table';

export const getMultiListDataByTableColumns = (tableColumns: ConfigureViewColumnType[]): MultiListType => {
	const elements: ElementKeyValuePairType = tableColumns.reduce(
		(elementsObject: ElementKeyValuePairType, tableColumn) => {
			if (tableColumn.field !== emptySpaceColumnId) {
				elementsObject[tableColumn.field] = {
					id: tableColumn.field,
					content: tableColumn.title,
				};
			}

			return elementsObject;
		},
		{}
	);

	const availableColumns: string[] = [];
	const selectedColumns: string[] = [];

	for (let i = 0; i < tableColumns.length; i++) {
		let column = tableColumns[i];
		if (column.field === emptySpaceColumnId) {
			continue;
		}

		if (column.visible || column.visible === undefined) {
			selectedColumns.push(column.field);
		} else {
			availableColumns.push(column.field);
		}
	}

	const columns: ColumnKeyValuePairType = {
		available: { id: 'available', title: 'Hidden', elementIds: availableColumns },
		selected: { id: 'selected', title: 'Visible columns', elementIds: selectedColumns },
	};

	return { elements, columns, columnOrder: ['available', 'selected'] };
}

export const getTableColumnsDataByMultiList = (multiList: MultiListType, defaultTableColumns: ConfigureViewColumnType[]): ConfigureViewColumnType[] => {
	const result: ConfigureViewColumnType[] = [];
	const multiListKeys = [
		...multiList.columns['selected'].elementIds,
		...multiList.columns['available'].elementIds,
	];
	multiListKeys.forEach((key) => {
		const tableColumnElement = defaultTableColumns.find((tableColumn) => tableColumn.field === key)!

		const newTableColumnElement = {
			...tableColumnElement,
			visible: multiList.columns.selected.elementIds.includes(key),
		}

		result.push(newTableColumnElement);
	});

	return result;
}

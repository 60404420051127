import { Subtract } from 'utility-types';
import { CustomItems, CustomItemsProps } from '../controls';
import { ItemsFieldProps, ItemsControlCommonProps, useItemsField } from '../controls/Table/wrappers/useItemsField';

export const ItemsField = ({ id, validator, disabled, uniqueFieldNames, ...rest }: ItemsFieldProps & Subtract<CustomItemsProps, ItemsControlCommonProps>) => {
	const itemsFieldProps = useItemsField({
		id,
		validator,
		disabled,
		uniqueFieldNames
	} as ItemsFieldProps)

	const tableSpecificProps = rest as Subtract<CustomItemsProps, ItemsControlCommonProps>;

	return (
		<CustomItems
			{...tableSpecificProps}
			{...itemsFieldProps}
		/>
	)
}

import { FormContext, IFormContext, ValidatorFunctionType, CheckboxField } from 'components/Form';
import { useCallback, memo, useMemo } from 'react';
import { getActiveModuleLabel } from './projectActiveModulesHelper';

type Props = {
	index: number
	values: any
	errors: any
	disabled?: boolean
	onFieldChange(index: number, id: string, value: any): void
	registerValidators(index: number, id: string, validatorFunctions: Array<ValidatorFunctionType>): void
	validateField(index: number, id: string, value?: any): void
}

export const ProjectActiveModuleCheckbox = ({ index, disabled, values, registerValidators, errors = {}, onFieldChange, validateField }: Props) => {
	const registerValidatorsCallback = useCallback(
		(id: string, validatorFunctions: Array<ValidatorFunctionType>) => registerValidators(index, id, validatorFunctions),
		[index, registerValidators]
	)

	const onFieldChangeCallback = useCallback(
		(id: string, value: any) => {
			onFieldChange(index, id, value);
		},
		[index, onFieldChange]
	)

	const validateFieldCallback = useCallback(
		(id: string, value?: any) => {
			validateField(index, id, value);
		},
		[index, validateField]
	)

	const context: IFormContext = useMemo(
		() => {
			return {
				values,
				errors,
				disabled,
				onFieldChange: onFieldChangeCallback,
				validateField: validateFieldCallback,
				registerValidators: registerValidatorsCallback
			}
		},
		[values, errors, disabled, validateFieldCallback, onFieldChangeCallback, registerValidatorsCallback]
	)

	return (
		<FormContext.Provider value={context}>
			<CheckboxField
				id='isActive'
				label={getActiveModuleLabel(context.values.id)}
			/>
		</FormContext.Provider>
	);
};

export default memo(ProjectActiveModuleCheckbox);

import { ChangeRequestStatusClient, SimpleStatusRequestUpdateSimpleStatusRequestItems, ChangeRequestStatusResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getChangeRequestStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestStatusClient();
	return client.getAll(tenantId);
}

export const updateChangeRequestStatusesAction = (model: SimpleStatusRequestUpdateSimpleStatusRequestItems) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestStatusClient();
	return client.update(tenantId, model);
}

export const persistChangeRequestStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedChangeRequestStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getChangeRequestStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const CHANGE_REQUEST_STATUS_FETCHING = 'CHANGE_REQUEST_STATUS_FETCHING';
export const CHANGE_REQUEST_STATUS_SET = 'CHANGE_REQUEST_STATUS_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: CHANGE_REQUEST_STATUS_FETCHING,
		fetching
	}
}

const setAction = (items: ChangeRequestStatusResponse[]): PersistItemsActionType<ChangeRequestStatusResponse> =>  {
	return {
		type: CHANGE_REQUEST_STATUS_SET,
		items
	}
}

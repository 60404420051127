import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistActiveItemsReducer } from 'features/Persist/reducerTypes';
import { TicketAssignedGroupsResponse } from 'services/tenantManagementService';
import { TICKET_ASSIGNED_GROUP_SET, TICKET_ASSIGNED_GROUP_FETCHING } from './action';

export const ticketAssignedGroupsReducer = (state = new PersistActiveItemsReducer<TicketAssignedGroupsResponse>(), action: PersistItemsActionType<TicketAssignedGroupsResponse > | FetchingActionType): PersistActiveItemsReducer<TicketAssignedGroupsResponse > => {
	switch (action.type) {
		case TICKET_ASSIGNED_GROUP_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TICKET_ASSIGNED_GROUP_SET:
			const { items } = action as PersistItemsActionType<TicketAssignedGroupsResponse >;

			const itemsMap: { [id: number]: TicketAssignedGroupsResponse  } = items.reduce(
                (map: { [id: number]: TicketAssignedGroupsResponse  }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			const activeItems = items.filter(x => x.isActive);

			const activeItemsMap: { [id: number]: TicketAssignedGroupsResponse } = items.reduce(
                (map: { [id: number]: TicketAssignedGroupsResponse }, item) => {
					if (item.isActive) {
						map[item.id] = item;
					}
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				activeItems,
				activeItemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { useCallback } from 'react'
import styles from './semaphore.module.scss'

export type SemaphoreItemType = {
	id: string | number | boolean
	color: string
}

type Props = SemaphoreItemType & {
	isSelected?: boolean
	onClick?(id?: string | number | boolean): void
	disabled?: boolean
}

export const SemaphoreItem = ({ id, color, isSelected, onClick, disabled }: Props) => {
	const handleItemChange = useCallback(
		() => {
			onClick && onClick(isSelected ? undefined : id);
		},
		[id, isSelected, onClick]
	)

	const className = `${styles.semaphore_item} ${disabled ? styles.disabled : ''} ${isSelected ? styles.selected : ''}`

	return (
		<div
			onClick={handleItemChange}
			className={className}
			style={{
				background: isSelected ? color.toLowerCase() : undefined
			}}
		/>
	);
}

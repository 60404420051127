import { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import styles from './errorBoundaryFallback.module.scss'
import Button from 'components/Button';
import { ColumnContainer, RowContainer } from 'components/Layout';

export type ErrorBoundaryFallbackProps = {
	// props from Error Boundary
	error: Error;
	componentStack: string | null;
	eventId: string | null;
	resetError(): void;

	// custom fallback props
	showButtons?: boolean;
}

const ErrorBoundaryFallback = ({ error, componentStack, eventId, resetError, showButtons = true }: ErrorBoundaryFallbackProps) => {
	const history = useHistory();

	useEffect(
		() => {
			if (history) {
				const unregister = history.listen(resetError)
				return unregister;
			}
		},
		[history, resetError]
	)

	const goBackCallback = useCallback(
		() => history.length > 0 ? history.goBack() : undefined,
		[history]
	)

	const goHomeCallback = useCallback(
		() => history.push('/'),
		[history]
	)

	return (
		<div className={styles.container}>
			<ColumnContainer>
				<RowContainer
					alignItems='center'
					justifyContent='center'
				>
					<p className={styles.message}>
						Something went wrong!
					</p>
				</RowContainer>
				{
					showButtons &&
					<RowContainer
						alignItems='center'
						justifyContent='center'
					>
						<Button
							text='Go Back'
							onClick={goBackCallback}
						/>
						<Button
							text='Go Home'
							color='neutral'
							onClick={goHomeCallback}
						/>
					</RowContainer>
				}
			</ColumnContainer>
		</div>
	)};

export default ErrorBoundaryFallback;

import { Subtract } from 'utility-types';
import { Semaphore, SemaphoreProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const SemaphoreField = (props: Subtract<SemaphoreProps, ControlsCommonProps<string | number | boolean>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={Semaphore}
		/>
	)
}
import { UserClient, InsertUserRequestModel, UpdateUserDataRequestModel, UserModel, ChangePasswordRequestModel, ExportDataModel, GenericFilterModelCollection, UpdateUserActivityRequestModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// async api calls

export const getAllUsersAction = () => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.getAll(tenantId, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
}

export const getAllUsersGenericAction = (genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.getAllGenericFilter(tenantId, genericFilter);
}

export const getUserAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.get(tenantId, id);
}

export const createUserAction = (model: InsertUserRequestModel) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.insert(tenantId, model);
}

export const updateUserAction = (model: UpdateUserDataRequestModel) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.update(tenantId, model)
}

export const deleteUserAction = (id: number) => {
	// INFO: not possible for now, backend will not be implemented for this one
	return Promise.resolve();
}

export const changeMyPasswordAction = (model: ChangePasswordRequestModel) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.updatePassword(tenantId, model);
}

export const updateUserActivityAction = (model: UpdateUserActivityRequestModel) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.updateActivity(tenantId, model);
}

export const exportUsersAction = (exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new UserClient();
	return client.export(tenantId, exportDataModel);
}

export const persistUsersAction = async () => {
	const state = store.getState();

	if (state.persistedUser.fetching) {
		return;
	}

	store.dispatch(setUsersFetchingAction(true));
	const response = await tryCatchJsonByAction(getAllUsersAction);
	if (response.success && response.items) {
		store.dispatch(setUsersAction(response.items || []));
	} else {
		store.dispatch(setUsersFetchingAction(false));
	}
}

// persist users redux

export const USERS_FETCHING = 'USERS_FETCHING';
export const USERS_SET = 'USERS_SET';

const setUsersFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: USERS_FETCHING,
		fetching
	}
}

const setUsersAction = (items: UserModel[]): PersistItemsActionType<UserModel> => {
	return {
		type: USERS_SET,
		items
	}
}

import { capitalizeFirstLetter } from './stringUtil';

// example:
//
// type A = {
// 	sava: string,
// 	pera: number
// }

// propertyOf<A>('pera')

export const propertyOf = <TObj>(name: keyof TObj) => name;

// similar to propertyOf, returns string with first letter upper case
export const propertyOfToCapital = <TObj>(name: keyof TObj) => capitalizeFirstLetter(name as string);

import { FetchingProjectValueActionType, PersistProjectValueActionType } from 'features/Persist/actionTypes';
import { PersistProjectValueReducer } from 'features/Persist/reducerTypes';
import { TimesheetRequiredFieldsResponse } from 'services/tenantManagementService';
import { TIMESHEET_REQUIRED_FIELDS_FETCHING, TIMESHEET_REQUIRED_FIELDS_SET } from './action';

export const timesheetRequiredFieldsReducer = (state = new PersistProjectValueReducer<TimesheetRequiredFieldsResponse>(), action: PersistProjectValueActionType<TimesheetRequiredFieldsResponse> | FetchingProjectValueActionType): PersistProjectValueReducer<TimesheetRequiredFieldsResponse> => {
	switch (action.type) {
		case TIMESHEET_REQUIRED_FIELDS_FETCHING: {
			// fetching is only for this projectId
			const { fetching, projectId } = action as FetchingProjectValueActionType;

			// isAnyFetching is checking if fetching for any project is in progress
			const isAnyFetching = fetching || !!Object.values(state.projectMap).find((item) => item?.fetching === true);

			return {
				...state,
				fetching: isAnyFetching,
				projectMap: {
					...state.projectMap,
					[projectId]: {
						value: state.projectMap[projectId]?.value || new TimesheetRequiredFieldsResponse(),
						fetching
					}
				}
			}
		}
		case TIMESHEET_REQUIRED_FIELDS_SET: {
			const { value, projectId } = action as PersistProjectValueActionType<TimesheetRequiredFieldsResponse>;

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					fetching: false,
					value
				}
			}

			return {
				value,
				fetching: false,
				isInitialized: true,
				projectMap
			}
		}
		default:
			return state;
	}
};

import store from 'base/reducer/store';
import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { TenantClient, TenantResponseModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTenantId } from './tenantUtils';

// api

const fetchTenant = () => {
	const tenantId = getTenantId()!;
	const client = new TenantClient();
	return client.getTenant(tenantId);
}

export const persistTenantAction = async () => {
	const state = store.getState();

	if (state.persistedTenant.fetching) {
		return;
	}

	store.dispatch(setTenantFetchingAction(true));

	const response = await tryCatchJsonByAction(fetchTenant);
	if (response.success) {
		store.dispatch(setTenantAction(response.value || new TenantResponseModel()));
	} else {
		store.dispatch(setTenantFetchingAction(false));
	}
}

// redux

export const TENANT_FETCHING = 'TENANT_FETCHING';
export const TENANT_SET = 'TENANT_SET';

const setTenantFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TENANT_FETCHING,
		fetching
	}
}

const setTenantAction = (value: TenantResponseModel): PersistValueActionType<TenantResponseModel> => {
	return {
		type: TENANT_SET,
		value
	}
}

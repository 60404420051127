import styled from 'styled-components';
import { Draggable } from 'react-beautiful-dnd';
import { getCssVariableValue } from 'utils/cssVariablesUtils';

interface ElementWrapperProps {
	isDragging: boolean
	borderColor: string
	activeColor: string
}

const ElementWrapper = styled.div<ElementWrapperProps>`
	padding: 8px;
	border: 1px solid ${(props) => (props.borderColor)};
	border-radius: 2px;
	margin-bottom: 8px;
	background-color: ${(props) => (props.isDragging ? props.activeColor : 'unset')};
`;

export class ElementType {
	id: string = '';
	content: string = '';

	constructor(element?: ElementType) {
		if (element) {
			this.id = element.id;
			this.content = element.content;
		}
	}
}

export type ElementKeyValuePairType = {
	[key: string]: ElementType;
};

type Props = {
	data: ElementType;
	index: number;
};

const Element = ({ data, index }: Props) => {
	const borderColor = getCssVariableValue('--dragndrop-border-color');
	const activeColor = getCssVariableValue('--dragndrop-active-color');

	return (
		<Draggable draggableId={data.id} index={index}>
			{(provided, snapshot) => (
				<ElementWrapper
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					ref={provided.innerRef}
					isDragging={snapshot.isDragging}
					borderColor={borderColor}
					activeColor={activeColor}
				>
					{data.content}
				</ElementWrapper>
			)}
		</Draggable>
	);
};

export default Element;

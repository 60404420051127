import React from 'react';
import styles from '../colorPicker.module.scss';

const ColorPickerOpener = ({ color, onClick }) => {
	return (
		<div onClick={onClick}
			className={styles.color_picker_opener}
			style={{
				backgroundColor: color,
				// if there is no color, show border
				borderColor: color === undefined ? '' : 'transparent'
			}}
		/>
	);
}

export default ColorPickerOpener;

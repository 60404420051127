import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { IdNameResponse } from 'services/tenantManagementService';
import { CURRENCIES_FETCHING, CURRENCIES_SET } from './action';

export const currencyReducer = (state = new PersistItemsReducer<IdNameResponse>(), action: PersistItemsActionType<IdNameResponse> | FetchingActionType): PersistItemsReducer<IdNameResponse> => {
	switch (action.type) {
		case CURRENCIES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case CURRENCIES_SET:
			const { items } = action as PersistItemsActionType<IdNameResponse>;

			const itemsMap: { [id: number]: IdNameResponse } = items.reduce(
                (map: { [id: number]: IdNameResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

type RgbColor = {
	r: number
	g: number
	b: number
}

type HslColor = {
	h: number
	s: number
	l: number
}

export const getRGBComponents = (hexColor: string): RgbColor | undefined=> {
	if (hexColor === undefined) {
		return;
	}

	if (hexColor.charAt(0) !== '#') {
		hexColor = colorToHex(hexColor) as string;
	}

	let noOfChars;
	if (hexColor.length === 4) {
		noOfChars = 1;
	} else if (hexColor.length === 7) {
		noOfChars = 2;
	} else {
		return;
	}

	let r = hexColor.substring(1, 1 + noOfChars);
	let g = hexColor.substring(1 + noOfChars, 1 + 2 * noOfChars);
	let b = hexColor.substring(1 + 2 * noOfChars, 1 + 3 * noOfChars);

	if (noOfChars === 1) {
		r += r;
		g += g;
		b += b;
	}

	return {
		r: parseInt(r, 16),
		g: parseInt(g, 16),
		b: parseInt(b, 16)
	};
}


export const getContrastingColor = (hexColor?: string) => {
	if (hexColor === undefined) {
		return '#fff';
	}

	const rgb = getRGBComponents(hexColor);
	if (rgb === undefined) {
		return '#fff';
	}

	// this formula was found in react-color\lib\helpers\color.js in getContrastingColor
	const yiq = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
	return yiq >= 170 ? '#000' : '#fff';
}

export const hexToHsl = (hexColor: string) => {
	if (hexColor === undefined) {
		return;
	}

	let rgb = getRGBComponents(hexColor);

	if (!rgb) {
		return;
	}

	let { r, g, b } = rgb;

	r /= 255;
	g /= 255;
	b /= 255;

	const cmin = Math.min(r, g, b);
	const cmax = Math.max(r, g, b);
	const delta = cmax - cmin;

	let h = 0;
	let s = 0;
	let l = 0;

	// Calculate hue
	// No difference
	if (delta === 0) {
		h = 0;
	}
	// Red is max
	else if (cmax === r) {
		h = ((g - b) / delta) % 6;
	}
	// Green is max
	else if (cmax === g) {
		h = (b - r) / delta + 2;
	}
	// Blue is max
	else {
		h = (r - g) / delta + 4;
	}

	h = Math.round(h * 60);

	// Make negative hues positive behind 360°
	if (h < 0) {
		h += 360;
	}

	// Calculate lightness
	l = (cmax + cmin) / 2;

	// Calculate saturation
	s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

	// Multiply l and s by 100
	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	return {
		h,
		s,
		l
	};
}

export const hslToHex = (hsl: HslColor) => {
	if (hsl === undefined) {
		return;
	}

	const { h } = hsl;
	let { s, l } = hsl;

	s /= 100;
	l /= 100;

	const c = (1 - Math.abs(2 * l - 1)) * s;
	const x = c * (1 - Math.abs((h / 60) % 2 - 1));
	const m = l - c / 2;
	let r = 0;
	let g = 0;
	let b = 0;

	if (0 <= h && h < 60) {
		r = c;
		g = x;
		b = 0;
	} else if (60 <= h && h < 120) {
		r = x;
		g = c;
		b = 0;
	} else if (120 <= h && h < 180) {
		r = 0;
		g = c;
		b = x;
	} else if (180 <= h && h < 240) {
		r = 0;
		g = x;
		b = c;
	} else if (240 <= h && h < 300) {
		r = x;
		g = 0;
		b = c;
	} else if (300 <= h && h < 360) {
		r = c;
		g = 0;
		b = x;
	}
	// Having obtained RGB, convert channels to hex
	let rString = Math.round((r + m) * 255).toString(16);
	let gString = Math.round((g + m) * 255).toString(16);
	let bString = Math.round((b + m) * 255).toString(16);

	// Prepend 0s, if necessary
	if (rString.length === 1) {
		rString = '0' + r;
	}
	if (gString.length === 1) {
		gString = '0' + g;
	}
	if (bString.length === 1) {
		bString = '0' + b;
	}

	return '#' + rString + gString + bString;
}


// example: converts 'black' -> [r, g, b, a]: number 0-255
function colorValueToRGBA(color: string) {
    // Returns the color as an array of [r, g, b, a] -- all range from 0 - 255
    // color must be a valid canvas fillStyle. This will cover most anything
    // you'd want to use.
    // Examples:
    // colorToRGBA('red')  # [255, 0, 0, 255]
    // colorToRGBA('#f00') # [255, 0, 0, 255]
    var cvs, ctx;
    cvs = document.createElement('canvas');
    cvs.height = 1;
    cvs.width = 1;
	ctx = cvs.getContext('2d') as CanvasRenderingContext2D;
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);
    return ctx.getImageData(0, 0, 1, 1).data;
}

// Turns a number (0-255) into a 2-character hex number (00-ff)
function byteToHex(num: number) {
    return ('0' + num.toString(16)).slice(-2);
}

// converts 'black' => '#000'
export function colorToHex(color: string) {
	if (!color) {
		return;
	}
	if (color.charAt(0) === '#') {
		return color;
	}
    // Convert any CSS color to a hex representation
    // Examples:
    // colorToHex('red')            # '#ff0000'
    // colorToHex('rgb(255, 0, 0)') # '#ff0000'
    const rgba = colorValueToRGBA(color);
    const hex = [0,1,2].map((idx) => byteToHex(rgba[idx])).join('');
    return "#" + hex;
}

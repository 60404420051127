import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistActiveItemsReducer } from 'features/Persist/reducerTypes';
import { TenantIsActiveResponse } from 'services/tenantManagementService';
import { PROJECT_TYPES_SET, PROJECT_TYPES_FETCHING } from './action';

export const projectTypesReducer = (state = new PersistActiveItemsReducer<TenantIsActiveResponse>(), action: PersistItemsActionType<TenantIsActiveResponse> | FetchingActionType): PersistActiveItemsReducer<TenantIsActiveResponse> => {
	switch (action.type) {
		case PROJECT_TYPES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case PROJECT_TYPES_SET:
			const { items } = action as PersistItemsActionType<TenantIsActiveResponse>;

			const itemsMap: { [id: number]: TenantIsActiveResponse } = items.reduce(
                (map: { [id: number]: TenantIsActiveResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			const activeItems = items.filter(x => x.isActive);

			const activeItemsMap: { [id: number]: TenantIsActiveResponse } = items.reduce(
                (map: { [id: number]: TenantIsActiveResponse }, item) => {
					if (item.isActive) {
						map[item.id] = item;
					}
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				activeItems,
				activeItemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { useMemo } from 'react';
import styles from './popup.module.scss';
import { PLACEMENT } from './constants';

export const useArrowDataMemo = function (position: PLACEMENT[], bBoxData: any) {
	return useMemo(
		() => {
			const { bBox } = bBoxData;

			const arrowData: any = {
				className: styles.popup_arrow + ' ' + styles[`popup_arrow_${position[0]}`],
				top: undefined,
				bottom: undefined,
				left: undefined,
				right: undefined
			};

			if (bBox === undefined) {
				return arrowData;
			}

			switch (position[1]) {
				case PLACEMENT.TOP:
				case PLACEMENT.BOTTOM:
					arrowData[position[1]] = `calc(100% - ${bBox.height / 2}px`
					break;
				case PLACEMENT.LEFT:
				case PLACEMENT.RIGHT:
					arrowData[position[1]] = `calc(100% - ${bBox.width / 2}px`
					break;
				case PLACEMENT.CENTER:
					if (position[0] === PLACEMENT.TOP || position[0] === PLACEMENT.BOTTOM) {
						arrowData.left = '50%';
					} else {
						arrowData.top = '50%';
					}
			}

			return arrowData;
		},
		[position, bBoxData]
	)
};

export const useContainerDataMemo = function (position: PLACEMENT[], bBoxData: any) {
	return useMemo(
		() => {
			const { bBox, dx, dy } = bBoxData;

			const containerData: any = {
				className: styles.popup + ' ' + styles[`popup_${position[0]}`],
				top: undefined,
				left: undefined,
				transform: undefined,
				transformOrigin: undefined
			};

			if (bBox === undefined) {
				return containerData;
			}

			switch (position[1]) {
				case PLACEMENT.CENTER:
					if (position[0] === PLACEMENT.TOP || position[0] === PLACEMENT.BOTTOM) {
						containerData.left = '50%';
					} else {
						containerData.top = '50%';
					}
			}

			let translateX: any = 0;
			let translateY: any = 0;
			let originX;
			let originY;

			switch (position[0]) {
				case PLACEMENT.TOP:
					translateY = '-100%';
					originY = 'bottom';
					break;
				case PLACEMENT.BOTTOM:
					translateY = '100%';
					originY = 'top';
					break;
				case PLACEMENT.LEFT:
					translateX = '-100%';
					originX = 'right';
					break;
				case PLACEMENT.RIGHT:
					translateX = '100%';
					originX = 'left';
					break;
			}

			switch (position[1]) {
				case PLACEMENT.TOP:
					translateY = `calc(-100% + ${dy / 2}px)`;
					originY = `calc(100% - ${bBox.height / 2}px)`;
					break;
				case PLACEMENT.BOTTOM:
					translateY = `${-bBox.height + dy / 2}px`
					originY = `${bBox.height / 2}px`;
					break;
				case PLACEMENT.LEFT:
					translateX = `calc(-100% + ${bBox.width - dx / 2}px)`;
					originX = `calc(100% - ${bBox.width / 2}px)`;
					break;
				case PLACEMENT.RIGHT:
					translateX = `${-dx / 2}px`;
					originX = `${bBox.width / 2}px`;
					break;
				case PLACEMENT.CENTER:
					if (position[0] === PLACEMENT.TOP || position[0] === PLACEMENT.BOTTOM) {
						translateX = '-50%';
						originX = 'center';
					} else {
						translateY = '-50%';
						originY = 'center';
					}
			}

			containerData.transform = `translate(${translateX}, ${translateY})`
			containerData.transformOrigin = `${originX} ${originY}`

			return containerData;
		},
		[position, bBoxData]
	)
};

import { PriorityResponse } from "services/tenantManagementService";

export const getPriority = (priorities: PriorityResponse[], impactId: number, urgencyId: number) => {
	for (const priority of priorities) {
		for (const mapping of priority.urgencyImpactMapping) {
			if (mapping.impactId === impactId && mapping.urgencyId === urgencyId) {
				return priority;
			}
		}
	}
}

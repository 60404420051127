import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './popup.module.scss';
import { POSITION } from './constants';
import { useArrowDataMemo, useContainerDataMemo } from './useDataMemo';
import { PLACEMENT } from '.';
import { noop } from 'utils/commonHelper';

// depends on $arrow_size from scss
const minSize = 26;

function getMinBBoxData(element: any) {
	const bBox = element.getBoundingClientRect();
	let dx = 0;
	let dy = 0;

	if (bBox.width < minSize) {
		dx = minSize - bBox.width;
		bBox.x -= dx / 2;
		bBox.width += dx;
	}
	if (bBox.height < minSize) {
		dy = minSize - bBox.height;
		bBox.y -= dy / 2;
		bBox.height += dy;
	}

	return {
		bBox,
		dx,
		dy
	}
}

type Props = {
	position: PLACEMENT[]
	content: any
	stayOpen?: boolean
	initialOpen?: boolean
	children: React.ReactElement
}

export const Popup = ({ position = POSITION.RIGHT_CENTER, content, stayOpen, initialOpen, children }: Props) => {
	const [bBoxData, setBBoxData] = useState({});
	const [isOpened, setIsOpened] = useState(initialOpen);

	const popupContainer = useRef<any>();

	const arrowData = useArrowDataMemo(position, bBoxData);
	const containerData = useContainerDataMemo(position, bBoxData);

	const arrowContent = useMemo(
		() => (
			<div
				className={arrowData.className}
				style={{
					top: arrowData.top && arrowData.top,
					bottom: arrowData.bottom && arrowData.bottom,
					left: arrowData.left && arrowData.left,
					right: arrowData.right && arrowData.right
				}}
			/>
		),
		[arrowData]
	);

	useEffect(
		() => {
			if (popupContainer.current && popupContainer.current.previousSibling) {
				const newBBoxData = getMinBBoxData(popupContainer.current.previousSibling);
				setBBoxData(newBBoxData);
			}
		},
		[]
	);

	const openPopup = useCallback(
		() => setIsOpened(true),
		[]
	);

	const closePopup = useCallback(
		() => setIsOpened(false),
		[]
	);

	return (
		<div className={styles.wrapper}>
			<div
				onMouseOver={openPopup}
				onMouseLeave={stayOpen ? noop : closePopup}
			>
				{children}
			</div>
			<div
				onMouseOver={stayOpen ? noop : openPopup}
				onMouseLeave={stayOpen ? noop : closePopup}
				ref={popupContainer}
				className={containerData.className}
				style={{
					transform: `${containerData.transform} scale(${isOpened ? 1 : 0})`,
					transformOrigin: containerData.transformOrigin,
					top: containerData.top,
					bottom: containerData.bottom,
					left: containerData.left,
					right: containerData.right
				}}
			>
			<div className={styles.popup_inner}>
				{stayOpen &&
					<div onClick={closePopup} className={styles.popup_close}>
						<p style={{ fontSize: '18px' }}>x</p>
					</div>
				}
				{content}
			</div>
			{arrowContent}
			</div>
		</div>
	)
};

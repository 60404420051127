import { AutoComplete, FieldMessage } from "components/Form"
import { SmartFormGroup } from "components/SmartContainer/SmartContainer"
import { IProjectOrCategory, ProjectOrCategoryType, UseProjectsAndCategoriesOptions, useProjectsAndCategories } from "../projectHooks"
import { useCallback, useMemo } from "react"

type Props = UseProjectsAndCategoriesOptions & {
	value?: ProjectOrCategoryType
	onChange?: (projectOrCategory: ProjectOrCategoryType | undefined) => void
	disabled?: boolean
	loading?: boolean
	hasError?: boolean // only for show message if select is required, but no value in select
	isRequired?: boolean
}

export const ProjectOrCategorySelect = ({
	value,
	onChange,
	disabled,
	loading,
	hasError,
	isRequired,
	...options
}: Props) => {
	const { projectsOrCategories, loadingProjectsAndCategories } = useProjectsAndCategories(options)

	const id = useMemo(
		() => {
			if (!value) {
				return;
			}

			const projectOrCategoryOption = projectsOrCategories.find((item) => item.projectOrCategoryId === value.projectOrCategoryId && item.isProjectConnected === value.isProjectConnected)!;

			return projectOrCategoryOption?.id;
		},
		[projectsOrCategories, value]
	);

	const onChangeCallback = useCallback(
		(value: string) => {
			const projectOrCategoryOption = projectsOrCategories.find((item) => item.id === value);

			if (!projectOrCategoryOption) {
				onChange && onChange(undefined);
				return;
			}

			onChange && onChange({
				projectOrCategoryId: projectOrCategoryOption.projectOrCategoryId,
				isProjectConnected: projectOrCategoryOption.isProjectConnected
			})
		},
		[onChange, projectsOrCategories]
	)

	return (
		<SmartFormGroup label='Project or category' isRequired={isRequired}>
			<>
				<AutoComplete
					value={id}
					onChange={onChangeCallback}
					items={projectsOrCategories}
					getItemId={(item: IProjectOrCategory) => item.id}
					getItemText={(item: IProjectOrCategory) => item.label}
					loading={loading || loadingProjectsAndCategories}
					disabled={disabled}
				/>
				{(hasError && isRequired) && <FieldMessage message={'This field is required'} />}
			</>
		</SmartFormGroup>
	)
}

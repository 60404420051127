import store from 'base/reducer/store';
import { TenantIsActiveResponse, ProjectConfigClient, TenantIsActiveDeltaRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getProjectCategoriesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectConfigClient();
	return client.getProjectCategories(tenantId);
}

export const updateProjectCategoriesAction = (projectCategoriesDelta: TenantIsActiveDeltaRequest ) => {
	const tenantId = getTenantId()!;
	const client = new ProjectConfigClient();
	return client.updateProjectCategories(tenantId, projectCategoriesDelta);
}

export const persistProjectCategoriesAction = async () => {
	const state = store.getState();

	if (state.persistedProjectCategory.fetching) {
		return;
	}

	store.dispatch(setProjectCategoryFetching(true));

	const response = await tryCatchJsonByAction(getProjectCategoriesAction);
	if (response.success) {
		store.dispatch(setProjectCategoriesAction(response.items || []));
	} else {
		store.dispatch(setProjectCategoryFetching(false));
	}
}

// redux

export const PROJECT_CATEGORIES_SET = 'PROJECT_CATEGORIES_SET';
export const PROJECT_CATEGORIES_FETCHING = 'PROJECT_CATEGORIES_FETCHING';

export const setProjectCategoryFetching = (fetching: boolean): FetchingActionType => {
	return {
		type: PROJECT_CATEGORIES_FETCHING,
		fetching
	}
}

export const setProjectCategoriesAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: PROJECT_CATEGORIES_SET,
		items
	}
}

import store from 'base/reducer/store';
import { TenantIsActiveResponse, TenantIsActiveDeltaRequest, ProjectConfigClient } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getProjectTypesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectConfigClient();
	return client.getProjectTypes(tenantId);
}

export const updateProjectTypesAction = (projectTypesDelta: TenantIsActiveDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectConfigClient();
	return client.updateProjectTypes(tenantId, projectTypesDelta);
}

export const persistProjectTypesAction = async () => {
	const state = store.getState();

	if (state.persistedProjectType.fetching) {
		return;
	}

	store.dispatch(setProjectTypesFetchingAction(true));

	const response = await tryCatchJsonByAction(getProjectTypesAction);
	if (response.success) {
		store.dispatch(setProjectTypesAction(response.items || []));
	} else {
		store.dispatch(setProjectTypesFetchingAction(false));
	}
}

// redux

export const PROJECT_TYPES_SET = 'PROJECT_TYPES_SET';
export const PROJECT_TYPES_FETCHING = 'PROJECT_TYPES_FETCHING';

export const setProjectTypesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: PROJECT_TYPES_FETCHING,
		fetching
	}
}

export const setProjectTypesAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: PROJECT_TYPES_SET,
		items
	}
}

import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { createLinksCellEditor } from './LinksCell/LinksCellEditor'

let _customTableCellsDefined = false

export const defineCustomTableCells = () => {
	if (!_customTableCellsDefined) {
		defineLinksField()
		_customTableCellsDefined = true
	}
}

const defineLinksField = () => {
	Tabulator.extendModule("edit", "editors", {
		customLinks: (
			cell: any,
			onRendered: Function,
			success: Function,
			cancel: Function,
			editorParams: any
		): Element => {
			return createLinksCellEditor(cell, onRendered, success, cancel, editorParams)
		},
	});
}


import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { BusinessPartnerResponseModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { sortByString } from 'utils/stringUtil';
import { BUSINESS_PARTNERS_SET, BUSINESS_PARTNERS_FETCHING } from './action';

export const businessPartnersReducer = (state = new PersistItemsReducer<BusinessPartnerResponseModel>(), action: PersistItemsActionType<BusinessPartnerResponseModel> | FetchingActionType): PersistItemsReducer<BusinessPartnerResponseModel> => {
	switch (action.type) {
		case BUSINESS_PARTNERS_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case BUSINESS_PARTNERS_SET:
			const { items } = action as PersistItemsActionType<BusinessPartnerResponseModel>;

			const itemsMap: { [id: number]: BusinessPartnerResponseModel } = items.reduce(
                (map: { [id: number]: BusinessPartnerResponseModel }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items: sortByString(items, propertyOf<BusinessPartnerResponseModel>('name')),
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

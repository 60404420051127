import { useMemo, useCallback, useState } from 'react';
import { AutoComplete, Input } from '../';
import { ControlsCommonProps } from '../../fields';
import { ClockIcon } from 'components/icons/icons';
import { convertMeridiemTimeToTicks, availableHours, availableMinutes, availableAmPm, convertTicksToMeridiemTime } from 'utils/dateTimeUtils';
import { MeridiemTimeEnum } from 'utils/MeridiemTimeEnum';
import styles from './timePicker.module.scss';
import { Cover } from 'components/Cover';

export type TimePickerProps = ControlsCommonProps<number | undefined> & {
	placeholder?: string
};

export const TimePicker = ({ value, onChange, disabled, onBlur, placeholder }: TimePickerProps) => {
	const { hours, minutes, displayValue, amOrPm } = useMemo(
		() => {
			if (value === undefined) {
				return {
					hours: 0,
					minutes: 0,
					amOrPm: MeridiemTimeEnum.AM,
					displayValue: '',
				};
			} else {
				const { hours, minutes, amOrPm } = convertTicksToMeridiemTime(value);

				const minutesString = String(minutes).padStart(2, '0');
				const hoursString = String(hours).padStart(2, '0');

				return {
					minutes,
					hours,
					amOrPm,
					displayValue: `${hoursString}:${minutesString} ${amOrPm}`,
				}
			}
		},
		[value]
	)

	const [open, setOpen] = useState(false);

	const openDropdownCallback = useCallback(
		() => disabled ? setOpen(false) : setOpen(true),
		[disabled]
	);

	const closeDropdownCallback = useCallback(
		() => setOpen(false),
		[]
	);

	const handleHoursChanged = useCallback(
		(newHours: number) => {
			const ticks = convertMeridiemTimeToTicks(newHours, minutes, amOrPm);
			onChange && onChange(ticks);
		},
		[onChange, minutes, amOrPm]
	)

	const handleMinutesChanged = useCallback(
		(newMinutes: number) => {
			const ticks = convertMeridiemTimeToTicks(hours, newMinutes, amOrPm);
			onChange && onChange(ticks);
		},
		[onChange, hours, amOrPm]
	)

	const handleAmPmChange = useCallback(
		(newAmPm: MeridiemTimeEnum) => {
			const ticks = convertMeridiemTimeToTicks(hours, minutes, newAmPm);
			onChange && onChange(ticks);
			closeDropdownCallback();
		},
		[onChange, hours, minutes, closeDropdownCallback]
	)

	return (
		<div className={styles.container} onClick={openDropdownCallback}>
			<Input
				value={displayValue}
				disabled={disabled}
				placeholder={placeholder}
				hideMaxLength
			/>
			<ClockIcon
				className={styles.icon}
				width={16}
				height={16}
				fill="currentColor"
			/>
			{open && <Cover onClick={closeDropdownCallback} transparent />}
			<div className={`${styles.dropdown} ${open ? styles.open : ''}`} >
				<div className={styles.time_picker}>
					<AutoComplete
						value={hours}
						items={availableHours}
						getItemId={(item: number) => item}
						getItemText={(item: number) => String(item).padStart(2, '0')}
						onChange={handleHoursChanged}
					/>
					<AutoComplete
						value={minutes}
						items={availableMinutes}
						getItemId={(item: number) => item}
						getItemText={(item: number) => String(item).padStart(2, '0')}
						onChange={handleMinutesChanged}
					/>
					<AutoComplete
						value={amOrPm}
						items={availableAmPm}
						getItemId={(item: MeridiemTimeEnum) => item}
						getItemText={(item: MeridiemTimeEnum) => item}
						onChange={handleAmPmChange}
					/>
				</div>
			</div>
		</div>
	)
}

import { ChangeRequestTypeClient, TenantIsActiveDeltaRequest, TenantIsActiveResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getChangeRequestTypesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestTypeClient();
	return client.get(tenantId);
}

export const updateChangeRequestTypesAction = (model: TenantIsActiveDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ChangeRequestTypeClient();
	return client.update(tenantId, model);
}

export const persistChangeRequestTypesAction = async () => {
	const state = store.getState();

	if (state.persistedChangeRequestType.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getChangeRequestTypesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const SCOPE_CHANGE_REQUEST_TYPE_FETCHING = 'SCOPE_CHANGE_REQUEST_TYPE_FETCHING';
export const SCOPE_CHANGE_REQUEST_TYPE_SET = 'SCOPE_CHANGE_REQUEST_TYPE_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: SCOPE_CHANGE_REQUEST_TYPE_FETCHING,
		fetching
	}
}

const setAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: SCOPE_CHANGE_REQUEST_TYPE_SET,
		items
	}
}

import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistActiveItemsReducer } from 'features/Persist/reducerTypes';
import { UserModel } from 'services/tenantManagementService';
import { propertyOf } from 'utils/propertyOf';
import { sortByString } from 'utils/stringUtil';
import { USERS_FETCHING, USERS_SET } from './action';

export const usersReducer = (state = new PersistActiveItemsReducer<UserModel>(), action: PersistItemsActionType<UserModel> | FetchingActionType): PersistActiveItemsReducer<UserModel> => {
	switch (action.type) {
		case USERS_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case USERS_SET:
			const { items } = action as PersistItemsActionType<UserModel>;

			const itemsMap: { [id: number]: UserModel } = items.reduce(
				(map: { [id: number]: UserModel }, item) => {
					map[item.id] = item;
					return map;
				},
				{}
			);

			const activeItems = items.filter(x => x.isActive);

			const activeItemsMap: { [id: number]: UserModel } = items.reduce(
                (map: { [id: number]: UserModel }, item) => {
					if (item.isActive) {
						map[item.id] = item;
					}
					return map;
				},
                {}
			);

			return {
				items: sortByString(items, propertyOf<UserModel>('username')),
				itemsMap,
				activeItems: sortByString(activeItems, propertyOf<UserModel>('username')),
				activeItemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { TaskStatusResponse } from 'services/tenantManagementService';
import { TASK_STATUS_FETCHING, TASK_STATUS_SET } from './action';

export const taskStatusReducer = (state = new PersistItemsReducer<TaskStatusResponse>(), action: PersistItemsActionType<TaskStatusResponse> | FetchingActionType): PersistItemsReducer<TaskStatusResponse> => {
	switch (action.type) {
		case TASK_STATUS_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TASK_STATUS_SET:
			const { items } = action as PersistItemsActionType<TaskStatusResponse>;

			const itemsMap: { [id: number]: TaskStatusResponse } = items.reduce(
                (map: { [id: number]: TaskStatusResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

export class ItemType {
    id!: number
    name!: string
	semantics!: any
}

export const getItemBySemantic = <P extends ItemType>(semanticEnum: any, items: P[]) => {
	for (const item of items) {
		if (item.semantics === semanticEnum) {
			return item;
		}
	}

	return;
}

export const getNameBySemantic = <P extends ItemType>(semanticEnum: any, items: P[]) => {
	const status = getItemBySemantic(semanticEnum, items);
	return status && status.name;
}

import React from 'react';
import styles from '../colorPicker.module.scss';

const Cover = props => {
	const onClick = e => {
		props.onClick();

		// enable that click event also goes thru cover
		const elements = document.elementsFromPoint(e.pageX, e.pageY);
		// svg elements don't have click method, so we can't just use elements[1].click,
		// but we need to find first parent that has click method
		for (let i = 1; i < elements.length; i++) {
			const element = elements[i];
			if (element.click) {
				element.click();
				break;
			}
		}
	}

	return <div className={styles.color_picker_cover} onClick={onClick} />;
}

export default Cover;

import { ProjectStatusResponse } from 'services/tenantManagementService';
import { PROJECT_STATUSES_FETCHING, PROJECT_STATUSES_SET } from './action';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';

export const projectStatusesReducer = (state = new PersistItemsReducer<ProjectStatusResponse>(), action: PersistItemsActionType<ProjectStatusResponse> | FetchingActionType): PersistItemsReducer<ProjectStatusResponse> => {
	switch (action.type) {
		case PROJECT_STATUSES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case PROJECT_STATUSES_SET:
			const { items } = action as PersistItemsActionType<ProjectStatusResponse>;

			const itemsMap: { [id: number]: ProjectStatusResponse } = items.reduce(
                (map: { [id: number]: ProjectStatusResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

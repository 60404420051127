import React from 'react';
import styles from '../colorPicker.module.scss';
import Preview from './Preview';
import DefaultColors from './DefaultColors';
import Arrow from './Arrow';
import Hue from './Hue';

const ColorPickerPanel = ({ color, defaultColors, onChange, onChangeComplete, placement, showHue }) => {
	const onHueChange = color => onChange(color);
	const onHueChangeComplete = color => onChangeComplete(color);

	let className = styles.color_picker_panel;

	// if (placement === PLACEMENT.RIGHT) {
	// 	className = styles.color_picker_panel_right;
	// }

	return (
		<div className={className}>
			<Arrow color={color} placement={placement} />
			<Preview color={color} />
			<div className={styles.color_picker_default_colors_wrapper}>
				<DefaultColors colors={defaultColors} onChange={onChangeComplete} />
				{showHue &&
					<Hue
						height={18}
						color={color}
						onChange={onHueChange}
						onChangeComplete={onHueChangeComplete}
					/>
				}
			</div>
		</div>
	);
}

ColorPickerPanel.defaultProps = {
	color: '#000'
}

export default ColorPickerPanel;

import { RiskStatusResponse, RiskStatusClient, StatusDeltaRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getRiskStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new RiskStatusClient();
	return client.getAll(tenantId);
}

export const updateRiskStatusesAction = (delta: StatusDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new RiskStatusClient();
	return client.update(tenantId, delta);
}

export const persistRiskStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedRiskStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getRiskStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const RISK_STATUSES_SET = 'RISK_STATUSES_SET';
export const RISK_STATUSES_FETCHING = 'RISK_STATUSES_FETCHING';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: RISK_STATUSES_FETCHING,
		fetching
	}
}

const setAction = (items: RiskStatusResponse[]): PersistItemsActionType<RiskStatusResponse> =>  {
	return {
		type: RISK_STATUSES_SET,
		items
	}
}

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory, useRouteMatch } from 'react-router-dom';
import { ModuleActivityEnum, ProjectResponse, ProjectStatusEnum } from 'services/tenantManagementService';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { RootState } from 'base/reducer/reducer';
import { ColumnContainer } from 'components/Layout';
import { isStatusBySemantic } from 'features/StatusResponse/statusResponse';
import { Subtract } from 'utility-types';
import { ProjectSelect } from '../controls/ProjectSelect';
import { UserRolePermissionEnum } from '../projectHooks';

export type ComponentProps = {
	project: ProjectResponse
	disabledEdit?: boolean
}

export const ProjectParamName = 'projectId';

export type ProjectPickerParams = {
	[ProjectParamName]?: string
}

export function WithProjectPicker<P extends ComponentProps>(
	Component: React.ComponentType<P>,
	moduleEnum: ModuleActivityEnum,
	useMargin?: boolean,
	teamMemberPermission?: string,
	userRolePermission?: UserRolePermissionEnum[]) {

	const HighOrderComponent = (props: Subtract<P, ComponentProps>) => {
		const history = useHistory();
		const routematch = useRouteMatch();
		const { projectId: urlProjectIdString }: ProjectPickerParams = useParams();
		const urlProjectId = urlProjectIdString ? parseInt(urlProjectIdString) : undefined;
		const { persistedProjectStatus, persistedProject, persistedDefaultProject } = useSelector((state: RootState) => state);

		// initial state should be the one from URL, and later on, state from URL should be ignored
		const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(urlProjectId);

		useEffect(
			() => {
				// if there is no initial state from URL, nor selected state, set default project as initial state (if it exists)
				if (!selectedProjectId && persistedDefaultProject.value.projectId) {
					setSelectedProjectId(persistedDefaultProject.value.projectId)
				}
			},
			[selectedProjectId, persistedDefaultProject]
		)

		const url = useMemo(
			() => {
				if (urlProjectId !== selectedProjectId) {
					return routematch.path.split(`/:${ProjectParamName}`)[0] + '/' + selectedProjectId;
				}
			},
			[routematch.path, selectedProjectId, urlProjectId]
		)

		useEffect(
			() => {
				if (url) {
					history.replace(url);
				}
			},
			[history, url]
		)

		const selectedProject = useMemo(
			() => {
				if (selectedProjectId) {
					return persistedProject.itemsMap[selectedProjectId];
				}

				return undefined;
			},
			[selectedProjectId, persistedProject]
		)

		const WrapperComponent = useMemo(
			() => useMargin ? ColumnContainer : React.Fragment,
			[]
		)

		const componentPropsMemo = useMemo(
			() => {
				return {
					...props,
					project: selectedProject,
					disabledEdit: !isStatusBySemantic(ProjectStatusEnum.Released, selectedProject?.statusId, persistedProjectStatus.itemsMap)
				} as P
			},
			[selectedProject, props, persistedProjectStatus.itemsMap]
		)

		return (
			<WrapperComponent>
				<SmartContainer>
					<SmartItem>
						<ProjectSelect
							value={selectedProjectId}
							onChange={setSelectedProjectId}
							moduleEnum={moduleEnum}
							showCompleted
							teamMemberPermission={teamMemberPermission}
							userRolePermission={userRolePermission}
						/>
					</SmartItem>
				</SmartContainer>
				{selectedProject && <Component {...componentPropsMemo} />}
			</WrapperComponent>
		)
	}

	return HighOrderComponent;
}

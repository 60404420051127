import { DeleteIcon } from 'components/icons/icons'
import { useCallback } from 'react'
import styles from './info.module.scss'
import { OverlaySpinner } from 'components/Spinner'

type Props = {
	text?: string // either text or children should be provided
	children?: React.ReactElement
	isDeletable?: boolean
	onDelete?(): void
	disabled?: boolean
	onClick?(): void
	isLoading?: boolean
}

export const Info = ({ text, children, isDeletable, onDelete, disabled, onClick, isLoading }: Props) => {
	const onDeleteCallback = useCallback(
		(e) => {
			e.stopPropagation();
			onDelete && onDelete()
		},
		[onDelete]
	)

	return (
		<div className={`${styles.item} ${disabled ? styles.disabled : ''}`} onClick={onClick}>
			<div>{text || children}</div>
			{(isDeletable && !disabled) &&
				<DeleteIcon
					fill='currentColor'
					className={styles.icon}
					width={10}
					height={10}
					onClick={onDeleteCallback}
				/>
			}
			{isLoading && <OverlaySpinner size={24} withBackground />}
		</div>
	)
}

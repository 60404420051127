import { useCallback, useMemo, useState } from 'react';
import FileSaver from 'file-saver';
import { AttachmentResponse, FileResponse, InsertAttachmentRequest } from 'services/tenantManagementService';
import { Info } from '../Info/Info';

export const useItems = (
	newAttachments: InsertAttachmentRequest[] = [],
	oldAttachments: AttachmentResponse[] = [],
	disabled: boolean = false,
	removeNewAttachment: (attachment: InsertAttachmentRequest) => void,
	removeOldAttachment: ((attachmentId: number) => Promise<void>) | undefined,
	downloadOldAttachment: (attachmentId: number) => Promise<FileResponse>
) => {
	const [deletingId, setDeletingId] = useState<number>();
	const [downloadingId, setDownloadingId] = useState<number>();

	const downloadOldAttachmentCallback = useCallback(
		async (id: number) => {
			setDownloadingId(id);
			const response = await downloadOldAttachment(id);

			if (response.status === 200) {
				FileSaver.saveAs(response.data, response.fileName);
			}
			setDownloadingId(undefined);
		},
		[downloadOldAttachment]
	)

	const removeOldAttachmentCallback = useCallback(
		async (id: number) => {
			setDeletingId(id)
			removeOldAttachment && await removeOldAttachment(id);
			setDeletingId(undefined)
		},
		[removeOldAttachment]
	)

	const oldAttachmentsContent = useMemo(
		() => oldAttachments.map(attachment => (
			<Info
				key={attachment.id}
				text={attachment.name}
				disabled={disabled || !removeOldAttachment}
				onClick={() => {
					downloadOldAttachmentCallback(attachment.id);
				}}
				onDelete={() => removeOldAttachmentCallback(attachment.id)}
				isDeletable={!!removeOldAttachment}
				isLoading={deletingId === attachment.id || downloadingId === attachment.id}
			/>
		)),
		[oldAttachments, downloadOldAttachmentCallback, removeOldAttachment, removeOldAttachmentCallback, deletingId, disabled, downloadingId]
	)

	const newAttachmentsContent = useMemo(
		() => newAttachments?.map((attachment, index) => (
			<Info
				key={index}
				text={attachment.name}
				disabled={disabled}
				isDeletable
				onDelete={() => removeNewAttachment(attachment)}
			/>
		)),
		[newAttachments, disabled, removeNewAttachment]
	)

	return useMemo(
		() => {
			return [
				...oldAttachmentsContent,
				...newAttachmentsContent
			]
		},
		[oldAttachmentsContent, newAttachmentsContent]
	)
}

import { useState, useEffect, useMemo } from 'react';
import { Provider } from 'react-redux';
import store from 'base/reducer/store';
import Spinner, { ClipSpinner } from 'components/Spinner';
import { ThemeProvider } from 'features/ThemeProvider';
import { PageRouter } from 'pages';
import appSettings from 'settings/appSettings';
import { BrowserRouter } from 'react-router-dom';
import ErrorBoundary from 'features/ErrorBoundary';

const AppContainer = () => {
	const [loading, setLoading] = useState(true);

	useEffect(
		() => {
			const fetchData = async ()  => {
				let filename;
				if (process.env.REACT_APP_STAGE) {
					filename = `appSettings_${process.env.REACT_APP_STAGE}.json`
				} else {
					filename = 'appSettings.json';
				}

				const settingsString = await (await fetch(`${process.env.PUBLIC_URL}/appSettings/${filename}`)).text();
				appSettings.SetSettings(JSON.parse(settingsString));

				setLoading(false);
			}
			fetchData();
		},
		[]
	);

	const content = useMemo(
		() => {
			if (loading) {
				return (
					<Spinner text='Loading...'>
						<ClipSpinner size={85} />
					</Spinner>
				)
			} else {
				return (
					<Provider store={store}>
						<ThemeProvider>
							<BrowserRouter>
								<PageRouter />
							</BrowserRouter>
						</ThemeProvider>
					</Provider>
				)
			}
		},
		[loading]
	);

	return (
		<ErrorBoundary location='AppContainer' fallbackProps={{ showButtons: false }}>
			{content}
		</ErrorBoundary>
	)
}

export default AppContainer;

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureContext, Severity, Breadcrumb, EventHint, Event } from '@sentry/types';
import { config } from 'config/config';
import { getSubdomainFromStorage } from 'features/Tenant/tenantUtils';
import { noop } from 'utils/commonHelper';
import { getUserInfo } from 'utils/storageUtils';

const _enabled = process.env.NODE_ENV === 'production';

export const ErrorReportingErrorBoundary = Sentry.ErrorBoundary;

const _beforeSend = (event: Event, hint?: EventHint) => {

	const userInfo = getUserInfo();
	const subdomainFromStorage = getSubdomainFromStorage();

	return {
		...event,
		user: userInfo.id ? { ...event.user, id: userInfo.id.toString(), email: userInfo.email } : event.user,
		tags: subdomainFromStorage ? {...event.tags, subdomain: subdomainFromStorage } : event.tags,
	};
}

const init = () => {
	const SentryConfig = config.Sentry;
	if (SentryConfig) {
		Sentry.init({
			dsn: SentryConfig.dsn,
			integrations: [new Integrations.BrowserTracing()],

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			// We recommend adjusting this value in production
			tracesSampleRate: 1.0,
			beforeSend: _beforeSend,
		});
	}
};

const reportError = (error: any, captureContext?: CaptureContext) => Sentry.captureException(error, captureContext);
const reportMessage = (message: string, captureContext?: CaptureContext | Severity) => Sentry.captureMessage(message, captureContext);
const addBreadcrumb = (breadcrumb: Breadcrumb) => Sentry.addBreadcrumb(breadcrumb);

const errorReportingService = _enabled ? {
	init,
	reportError,
	reportMessage,
	addBreadcrumb,
} : {
	init: noop,
	reportError: noop,
	reportMessage: noop,
	addBreadcrumb: noop,
};

export default errorReportingService;

import { CompanyRequestModel, CompanyResponseModel, TenantClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';

export const getCompanyInfoAction = () => {
	const tenantId = getTenantId()!;
	const tenantClient = new TenantClient();
	return tenantClient.getCompany(tenantId);
}

export const updateCompanyInfoAction = (companyInfo: CompanyRequestModel ) => {
	const tenantId = getTenantId()!;
	const tenantClient = new TenantClient();
	return tenantClient.updateCompany(tenantId, companyInfo);
}


export const persistCompanyInfoAction = async () => {
	const state = store.getState();

	if (state.persistedCompanyInfo.fetching) {
		return;
	}

	store.dispatch(setCompanyInfoFetchingAction(true));

	const response = await tryCatchJsonByAction(getCompanyInfoAction);
	if (response.success) {
		store.dispatch(setCompanyInfoAction(response.value || new CompanyResponseModel()));
	} else {
		store.dispatch(setCompanyInfoFetchingAction(false));
	}
}

// redux

export const COMPANY_INFO_FETCHING = 'COMPANY_INFO_FETCHING';
export const COMPANY_INFO_SET = 'COMPANY_INFO__SET';

const setCompanyInfoFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: COMPANY_INFO_FETCHING,
		fetching
	}
}

const setCompanyInfoAction = (value: CompanyResponseModel): PersistValueActionType<CompanyResponseModel> => {
	return {
		type: COMPANY_INFO_SET,
		value
	}
}

import { ThemeAction, THEME_SET } from './action';
import { getThemeLocalStorage } from './localStorage';

export enum ThemeEnum {
	Light = 'light',
	Dark = 'dark'
}

const initialTheme = getThemeLocalStorage() || ThemeEnum.Dark;

export const themeReducer = (state = initialTheme, action: ThemeAction) => {
	switch (action.type) {
		case THEME_SET:
			return action.theme;
		default:
			return state;
	}

}

import { RoutesActionType, ROUTES_SET } from "./action";
import { combineReducers } from 'redux';
import { IconType } from 'components/icons/icons';

export type Route = {
	id: string
	url: string
	text: string
	Icon?: IconType
	children?: Route[]
}

const initialState: Route[] = [];

const currentRoutesReducer = (state = initialState, action: RoutesActionType) => {
	switch (action.type) {
		case ROUTES_SET:
			return action.routes;
		default:
			return state;
	}
}

export const routesReducer = combineReducers({
	currentRoutes: currentRoutesReducer
})

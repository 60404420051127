import { Subtract } from 'utility-types';
import { TimeSpan, TimeSpanProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const TimeSpanField = (props: Subtract<TimeSpanProps, ControlsCommonProps<number | undefined>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={TimeSpan}
		/>
	)
}

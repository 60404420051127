import { Subtract } from 'utility-types';
import { TextEditor, TextEditorProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const TextEditorField = (props: Subtract<TextEditorProps, ControlsCommonProps<string>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={TextEditor}
		/>
	)
}

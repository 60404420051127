import { ControlsCommonProps } from 'components/Form/fields'
import styles from './switch.module.scss'
import { useCallback } from 'react'

export type SwitchProps = ControlsCommonProps<boolean> & {

}

export const Switch = ({ value, disabled, onChange }: SwitchProps) => {
	const onChangeCallback = useCallback(
		() => {
			if (!disabled) {
				onChange && onChange(!value);
			}
		},
		[value, disabled, onChange]
	)

	const className = `${styles.container} ${disabled ? styles.disabled : ''} ${value ? styles.active : ''}`;


	return (
		<div className={className} onClick={onChangeCallback}>
			<div className={styles.track}></div>
			<div className={styles.thumb}></div>
		</div>
	)
}

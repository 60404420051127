import notifications from 'components/Notification/notification';
import { BaseResponseModel, ApiException } from 'services/tenantManagementService';
import { globalErrorKey } from 'components/Form'
import { clearStorage } from 'utils/storageUtils';

export const defaultErrorResponse = new BaseResponseModel({
	success: false,
	errors: {}
})

const fixErrorResponse = (result: any) => ({ ...result, success: false })

export const catchError = <P extends BaseResponseModel>(error: any) => {
	// server error
	if (error.status === 500) {
		notifications.error('Unknown server error, please contact system administrator');
		return defaultErrorResponse as P;
	}

	// validation error
	if (error.status === 400) {
		notifications.error('There are some validation errors, please fix those and try again.')
		// error.result is BaseResponseModel all the time (if backend is correctly implemented)
		return fixErrorResponse((error as ApiException).result);
	}

	// forbidden, there is no permission for this user or action is not possible (example: cannot delete entity that is used)
	if (error.status === 403) {
		// error.result is BaseResponseModel all the time (if backend is correctly implemented)
		const result = (error as ApiException).result;
		const errors = (result as BaseResponseModel)?.errors;
		const message = errors && errors[globalErrorKey] && errors[globalErrorKey].message;
		notifications.error(message || 'You don\'t have permission to perform this action');
		return fixErrorResponse(result);
	}

	// unauthorized error
	if (error.status === 401) {
		// redirect to login
		clearStorage();
		// when token is invalid, storage is cleared and we reload the page
		// when page is loaded again, PageRouter inside of renderHomePage will save location.pathname to router state and redirect to Login
		window.location.reload();

		notifications.error('You are unauthorized for this action. Please login to application and try this action again. If problem remains, please contact system administrator.');
		return defaultErrorResponse as P;
	}

	if (error instanceof TypeError) {
		// INFO: this is not useful to user, it is more for us to see the message
		notifications.error(error.toString());
		return defaultErrorResponse as P;
	}

	notifications.error('Unknown error, please contact system administrator');
	return defaultErrorResponse as P;
}

export const tryCatchJsonByAction = async <P extends BaseResponseModel>(fetchFunction: (...args: any[]) => Promise<P>): Promise<P> => {
	const fetchPromise = fetchFunction();

	try {
		const response = await fetchPromise;
		return response || defaultErrorResponse as P;
	}
	catch (error: any) {
		return catchError<P>(error);
	}
}

// INFO: form should export this type
// delta errors has array, not string. Check this "any" type
type FormErrorsType = {
	[key: string]: string | any
}

// INFO: this should be called somewhere in tryCatchJson... and all calls to this method should be removed
export const convertResponseErrors = (response: BaseResponseModel): FormErrorsType | undefined => {
	if (response.errors) {
		const errors: any = {};
		for (const key of Object.keys(response.errors)) {
			// INFO: additionalInfo and messageCode should be implemented with localization
			// TODO: delta errors are stored in additionalInfo. Check can we use that property for delta errors
			key.split('.').reduce(
				(accumulator: any, currentSubKey: any, index: number, array: string[]) => {
					let arrayRegex = /^[a-zA-Z]+[[0-9]*]/g;
					let newObj: any;

					// check is property name array "insert[0]"
					if (arrayRegex.test(currentSubKey)) {
						const splited = currentSubKey.split('[');
						const arrayKey = splited[0];
						const arrayIndex = parseInt(splited[1].slice(0, -1));

						//create array first, then create object
						newObj = accumulator[arrayKey] || [];
						accumulator[arrayKey] = newObj;

						newObj = accumulator[arrayKey][arrayIndex] || {};

						// if last, set message
						if (index === array.length - 1) {
							newObj = response.errors![key].message;
						}
						// set accumulator to new object
						accumulator[arrayKey][arrayIndex] = newObj;
					} else { // property name is object
						newObj = accumulator[currentSubKey] || {};
						if (index === array.length - 1) {
							// if last, set message
							newObj = response.errors![key].message;
						}
						// set accumulator to new object
						accumulator[currentSubKey] = newObj;
					}

					return newObj;
				},
				errors
			);
		}
		return errors;
	}
}

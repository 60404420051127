import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { ApprovalTimeTravelDescriptiveRequest, ExportDataModel, GenericFilterModelCollection, InsertTravelRequestRequest, MonthEnum, TravelRequestClient, TravelRequestResponse, UpdateTravelRequestRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getTravelRequestAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.get(tenantId, id);
}

export const getAllMyTravelRequestsGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.getAllMyGeneric(tenantId, years, months, filter);
}

export const getAllMyTravelRequestsGenericTotalsAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.getAllMyGenericTotals(tenantId, years, months, filter);
}

export const getAllMyTravelRequestsAction = () => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.getAllMy(tenantId);
}

export const getAllTravelRequestsGenericAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.getAllGeneric(tenantId, years, months, filter);
}

export const getAllTravelRequestsGenericTotalsAction = (years: number[], months: MonthEnum[], filter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.getAllGenericTotals(tenantId, years, months, filter);
}

export const createTravelRequestAction = (model: InsertTravelRequestRequest) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.create(tenantId, model);
}

export const updateTravelRequestAction = (model: UpdateTravelRequestRequest) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.update(tenantId, model);
}

export const deleteTravelRequestAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.delete(tenantId, id);
}

export const releaseMyTravelRequestAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.releaseMy(tenantId, id);
}

export const approvalTravelRequestAction = (id: number, model: ApprovalTimeTravelDescriptiveRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.approval(tenantId, id, model);
}

export const exportTravelRequestsAction = (years: number[], months: MonthEnum[], body: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new TravelRequestClient();
	return client.exportAll(tenantId, years, months, body);
}

export const persistTravelRequestsAction = async () => {
	const state = store.getState();

	if (state.persistedTravelRequests.fetching) {
		return;
	}

	store.dispatch(setTravelRequestsFetchingAction(true));

	const response = await tryCatchJsonByAction(getAllMyTravelRequestsAction);
	if (response.success) {
		store.dispatch(setTravelRequestsAction(response.items || []));
	} else {
		store.dispatch(setTravelRequestsFetchingAction(false));
	}
}

// redux

export const TRAVEL_REQUESTS_FETCHING = 'TRAVEL_REQUESTS_FETCHING';
export const TRAVEL_REQUESTS_SET = 'TRAVEL_REQUESTS_SET';

const setTravelRequestsFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TRAVEL_REQUESTS_FETCHING,
		fetching
	}
}

const setTravelRequestsAction = (items: TravelRequestResponse[]): PersistItemsActionType<TravelRequestResponse> => {
	return {
		type: TRAVEL_REQUESTS_SET,
		items
	}
}

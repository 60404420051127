import { RiskConfigClient, TenantIsActiveDeltaRequest, TenantIsActiveResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getRiskTypesAction = () => {
	const tenantId = getTenantId()!;
	const client = new RiskConfigClient();
	return client.getRiskTypes(tenantId);
}

export const updateRiskTypesAction = (riskTypesDelta: TenantIsActiveDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new RiskConfigClient();
	return client.updateRiskTypes(tenantId, riskTypesDelta);
}

export const persistRiskTypesAction = async () => {
	const state = store.getState();

	if (state.persistedRiskType.fetching) {
		return;
	}

	store.dispatch(setRiskTypesFetchingAction(true));

	const response = await tryCatchJsonByAction(getRiskTypesAction);
	if (response.success) {
		store.dispatch(setRiskTypesAction(response.items || []));
	} else {
		store.dispatch(setRiskTypesFetchingAction(false));
	}
}

// redux

export const RISK_TYPES_FETCHING = 'RISK_TYPES_FETCHING';
export const RISK_TYPES_SET = 'RISK_TYPES_SET';

const setRiskTypesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: RISK_TYPES_FETCHING,
		fetching
	}
}

const setRiskTypesAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: RISK_TYPES_SET,
		items
	}
}

import { NonProjectCategoryDeltaRequest, NonProjectCategoryResponse, TimeTravelConfigClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import store from 'base/reducer/store';

export const getNonProjectCategoriesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.getNonProjectCategories(tenantId);
}

export const updateNonProjectCategoriesAction = (nonProjectCategoriesDelta: NonProjectCategoryDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.updateNonProjectCategories(tenantId, nonProjectCategoriesDelta);
}

export const persistNonProjectCategoriesAction = async () => {
	const state = store.getState();

	if (state.persistedTimeTravelNonProjectCategories.fetching) {
		return;
	}

	store.dispatch(setNonProjectCategoriesFetchingAction(true));

	const response = await tryCatchJsonByAction(getNonProjectCategoriesAction);
	if (response.success) {
		store.dispatch(setNonProjectCategoriesActionAction(response.items || []));
	} else {
		store.dispatch(setNonProjectCategoriesFetchingAction(false));
	}
}

// redux

export const TIME_TRAVEL_NON_PROJECT_CATEGORIES_FETCHING = 'TIME_TRAVEL_NON_PROJECT_CATEGORIES_FETCHING';
export const TIME_TRAVEL_NON_PROJECT_CATEGORIES_SET = 'TIME_TRAVEL_NON_PROJECT_CATEGORIES_SET';

const setNonProjectCategoriesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TIME_TRAVEL_NON_PROJECT_CATEGORIES_FETCHING,
		fetching
	}
}

const setNonProjectCategoriesActionAction = (items: NonProjectCategoryResponse[]): PersistItemsActionType<NonProjectCategoryResponse> =>  {
	return {
		type: TIME_TRAVEL_NON_PROJECT_CATEGORIES_SET,
		items
	}
}

import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistActiveItemsReducer } from 'features/Persist/reducerTypes';
import { CostExpenseTypeResponse } from 'services/tenantManagementService';
import { TIME_TRAVEL_EXPENSE_TYPES_FETCHING, TIME_TRAVEL_EXPENSE_TYPES_SET } from './action';

export const timeTravelExpenseTypesReducer = (state = new PersistActiveItemsReducer<CostExpenseTypeResponse>(), action: PersistItemsActionType<CostExpenseTypeResponse > | FetchingActionType): PersistActiveItemsReducer<CostExpenseTypeResponse > => {
	switch (action.type) {
		case TIME_TRAVEL_EXPENSE_TYPES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TIME_TRAVEL_EXPENSE_TYPES_SET:
			const { items } = action as PersistItemsActionType<CostExpenseTypeResponse >;

			const itemsMap: { [id: number]: CostExpenseTypeResponse  } = items.reduce(
                (map: { [id: number]: CostExpenseTypeResponse  }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			const activeItems = items.filter(x => x.isActive);

			const activeItemsMap: { [id: number]: CostExpenseTypeResponse } = items.reduce(
                (map: { [id: number]: CostExpenseTypeResponse }, item) => {
					if (item.isActive) {
						map[item.id] = item;
					}
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				activeItems,
				activeItemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

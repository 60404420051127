import { InsertAttachmentRequest } from 'services/tenantManagementService';
import { Subtract } from 'utility-types';
import { FileInput, FileInputProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const FileInputField = (props: Subtract<FileInputProps, ControlsCommonProps<InsertAttachmentRequest[]>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={FileInput}
		/>
	)
}

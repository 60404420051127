import store from 'base/reducer/store';
import { OrganizationalUnitResponse, OrganizationalUnitClient, InsertOrganizationalUnitRequest, UpdateOrganizationalUnitRequest, UpdateOrderRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getAllOrganizationalUnitsAction = (parentId?: number, levelNumber?: number) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitClient();
	return client.getAll(tenantId, parentId, levelNumber);
}

export const getOrganizationalUnitAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitClient();
	return client.get(tenantId, id);
}

export const createOrganizationalUnitAction = (model: InsertOrganizationalUnitRequest) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitClient();
	return client.create(tenantId, model);
}

export const updateOrganizationalUnitsAction = (model: UpdateOrganizationalUnitRequest) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitClient();
	return client.update(tenantId, model);
}

export const deleteOrganizationalUnitAction = (id: number) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitClient();
	return client.delete(tenantId, id);
}

export const reorderOrganizationalUnitsAction = (modelOrders: UpdateOrderRequest[]) => {
	const tenantId = getTenantId()!;
	const client = new OrganizationalUnitClient();
	return client.updateOrders(tenantId, modelOrders);
}

export const persistOrganizationalUnitsAction = async (parentId?: number, levelNumber?: number) => {
	const state = store.getState();

	if (state.persistedOrganizationalUnit.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const bindedAction = getAllOrganizationalUnitsAction.bind(parentId, levelNumber);
	const response = await tryCatchJsonByAction(bindedAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const COMPANY_ORGANIZATIONAL_UNITS_SET = 'COMPANY_ORGANIZATIONAL_UNITS_SET';
export const COMPANY_ORGANIZATIONAL_UNITS_FETCHING = 'COMPANY_ORGANIZATIONAL_UNITS_FETCHING';

export const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: COMPANY_ORGANIZATIONAL_UNITS_FETCHING,
		fetching
	}
}

export const setAction = (items: OrganizationalUnitResponse[]): PersistItemsActionType<OrganizationalUnitResponse> =>  {
	return {
		type: COMPANY_ORGANIZATIONAL_UNITS_SET,
		items
	}
}

import { Subtract } from 'utility-types';
import { InputNumber, InputNumberProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const InputNumberField = (props: Subtract<InputNumberProps, ControlsCommonProps<number | undefined>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={InputNumber}
		/>
	)
}

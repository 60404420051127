import styles from './maxLength.module.scss';

type Props = {
	length: number
	maxLength: number
}

export const MaxLength = ({ length, maxLength }: Props) => {
	return (
		<span className={styles.container}>{length} / {maxLength}</span>
	)
}

import store from 'base/reducer/store';
import { FetchingActionType, FetchingProjectItemsActionType, PersistItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { ProjectConfigDeltaRequest, ProjectIsActiveResponse, ProjectTeamClient } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getProjectTeamsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamClient();
	return client.getAllByProjectId(tenantId, projectId);
}

export const getAllProjectTeamsAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamClient();
	return client.getAll(tenantId);
}

export const updateProjectTeamsAction = (projectId: number, model: ProjectConfigDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamClient();
	return client.update(tenantId, projectId, model);
}

export const persistProjectTeamsAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedProjectTeam.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setProjectTeamsFetchingAction(true, projectId));

	const bindedAction = getProjectTeamsAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success && response.items) {
		store.dispatch(setProjectTeamsAction(projectId, response.items || []));
	} else {
		store.dispatch(setProjectTeamsFetchingAction(false, projectId));
	}
}

export const persistAllProjectTeamsAction = async () => {
	const state = store.getState();

	if (state.persistedProjectTeam.fetching) {
		return;
	}

	store.dispatch(setProjectTeamsFetchingAllAction(true));

	const response = await tryCatchJsonByAction(getAllProjectTeamsAction);

	if (response.success && response.items) {
		store.dispatch(setAllProjectTeamsAction(response.items || []));
	} else {
		store.dispatch(setProjectTeamsFetchingAllAction(false));
	}
}

// persist project teams redux

export const PROJECT_TEAMS_FETCHING_ALL = 'PROJECT_TEAMS_FETCHING_ALL';
export const PROJECT_TEAMS_FETCHING = 'PROJECT_TEAMS_FETCHING';
export const PROJECT_TEAMS_SET = 'PROJECT_TEAMS_SET';
export const PROJECT_TEAMS_ALL_SET = 'PROJECT_TEAMS_ALL_SET';

const setProjectTeamsFetchingAllAction = (fetching: boolean): FetchingActionType => {
	return {
		type: PROJECT_TEAMS_FETCHING_ALL,
		fetching
	}
}

const setProjectTeamsFetchingAction = (fetching: boolean, projectId: number): FetchingProjectItemsActionType => {
	return {
		type: PROJECT_TEAMS_FETCHING,
		fetching,
		projectId
	}
}

const setProjectTeamsAction = (projectId: number, items: ProjectIsActiveResponse[]): PersistProjectItemsActionType<ProjectIsActiveResponse> => {
	return {
		type: PROJECT_TEAMS_SET,
		items,
		projectId
	}
}

const setAllProjectTeamsAction = (items: ProjectIsActiveResponse[]): PersistItemsActionType<ProjectIsActiveResponse> => {
	return {
		type: PROJECT_TEAMS_ALL_SET,
		items,
	}
}

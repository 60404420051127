import { globalErrorKey } from "../../../Form";

export const isUniqueFieldNameValidator = (fieldName: string, label: string, values: Array<any>) => {
	const fieldValues = values.map(value => value[fieldName])
	const duplicates = fieldValues.filter((value, index: number) => fieldValues.indexOf(value) !== index);
	if (duplicates.length > 0) {
		const errors: any[] = [];
		errors[values.length - 1] = { [globalErrorKey]: `${label} must be unique` };
		return errors;
	}
	return '';
}

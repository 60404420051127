import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { OrganizationalUnitResponse } from 'services/tenantManagementService';
import { COMPANY_ORGANIZATIONAL_UNITS_FETCHING, COMPANY_ORGANIZATIONAL_UNITS_SET } from './action';

export const organizationalUnitsReducer = (state = new PersistItemsReducer<OrganizationalUnitResponse>(), action: PersistItemsActionType<OrganizationalUnitResponse> | FetchingActionType): PersistItemsReducer<OrganizationalUnitResponse> => {
	switch (action.type) {
		case COMPANY_ORGANIZATIONAL_UNITS_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case COMPANY_ORGANIZATIONAL_UNITS_SET:
			const { items } = action as PersistItemsActionType<OrganizationalUnitResponse>;

			const itemsMap: { [id: number]: OrganizationalUnitResponse } = items.reduce(
                (map: { [id: number]: OrganizationalUnitResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

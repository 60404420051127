import { FetchingProjectItemsActionType, PersistItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { ProjectIsActiveResponse } from 'services/tenantManagementService';
import { DEPARTMENTS_FETCHING, DEPARTMENTS_SET } from './action';

export const departmentsReducer = (state = new PersistProjectItemsReducer<ProjectIsActiveResponse>(), action: PersistProjectItemsActionType<ProjectIsActiveResponse> | PersistItemsActionType<ProjectIsActiveResponse> | FetchingProjectItemsActionType): PersistProjectItemsReducer<ProjectIsActiveResponse> => {
	switch (action.type) {
		case DEPARTMENTS_FETCHING: {
			// fetching is only for this projectId
			const { fetching, projectId } = action as FetchingProjectItemsActionType;

			// isAnyFetching is checking if fetching for any project is in progress
			const isAnyFetching = fetching || !!Object.values(state.projectMap).find((item) => item?.fetching === true)

			return {
				...state,
				fetching: isAnyFetching,
				projectMap: {
					...state.projectMap,
					[projectId]: {
						items: state.projectMap[projectId]?.items || [],
						fetching
					}
				}
			}
		}
		case DEPARTMENTS_SET:
			const { items, projectId } = action as PersistProjectItemsActionType<ProjectIsActiveResponse>;

			const itemsMap = {
				...state.itemsMap,
				...items.reduce(
					(map: { [id: number]: ProjectIsActiveResponse }, item) => {
						map[item.id] = item;
						return map;
					},
					{}
				)
			}

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					fetching: false,
					items
				}
			}

			const filteredItems = state.items.filter(x => x.projectId !== projectId);
			const newItems = [...items, ...filteredItems];

			return {
				items: newItems,
				itemsMap,
				fetching: false,
				isInitialized: true,
				projectMap
			}
		default:
			return state;
	}
};

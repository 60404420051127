import { GenericFilterOperationEnum } from 'services/tenantManagementService';

export const formatFilterOperation = (filterOperation?: GenericFilterOperationEnum) => {
	switch(filterOperation) {
		case GenericFilterOperationEnum.EQUALS:
			return 'Equal'
		case  GenericFilterOperationEnum.LIKE:
			return 'Contains'
		case GenericFilterOperationEnum.LIKE_STARTSWITH:
			return 'Starts with';
		case GenericFilterOperationEnum.LIKE_ENDSWITH:
			return 'Ends with';
		case GenericFilterOperationEnum.NOT_EQUALS:
			return 'Not Equal';
		case GenericFilterOperationEnum.GREATER_THAN:
			return 'Greater than'
		case GenericFilterOperationEnum.GREATER_EQUAL_THAN:
			return 'Greater or equal than'
		case GenericFilterOperationEnum.LESS_THAN:
			return 'Less than'
		case GenericFilterOperationEnum.LESS_EQUAL_THAN:
			return 'Less or equal than'
		default:
			return 'Equal'
	}
}

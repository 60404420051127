import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { PersistValueReducer } from 'features/Persist/reducerTypes';
import { TenantResponseModel } from 'services/tenantManagementService';
import { TENANT_FETCHING, TENANT_SET } from './action';

export const tenantReducer = (state = new PersistValueReducer<TenantResponseModel>(), action: PersistValueActionType<TenantResponseModel> | FetchingActionType): PersistValueReducer<TenantResponseModel> => {
	switch (action.type) {
		case TENANT_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TENANT_SET:
			const { value } = action as PersistValueActionType<TenantResponseModel>;

			return {
				value,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistActiveItemsReducer } from 'features/Persist/reducerTypes';
import { NonProjectCategoryResponse } from 'services/tenantManagementService';
import { TIME_TRAVEL_NON_PROJECT_CATEGORIES_FETCHING, TIME_TRAVEL_NON_PROJECT_CATEGORIES_SET } from './action';

export const timeTravelNonProjectCategoriesReducer = (state = new PersistActiveItemsReducer<NonProjectCategoryResponse>(), action: PersistItemsActionType<NonProjectCategoryResponse> | FetchingActionType): PersistActiveItemsReducer<NonProjectCategoryResponse> => {
	switch (action.type) {
		case TIME_TRAVEL_NON_PROJECT_CATEGORIES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TIME_TRAVEL_NON_PROJECT_CATEGORIES_SET:
			const { items } = action as PersistItemsActionType<NonProjectCategoryResponse >;

			const itemsMap: { [id: number]: NonProjectCategoryResponse  } = items.reduce(
                (map: { [id: number]: NonProjectCategoryResponse  }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			const activeItems = items.filter(x => x.isActive);

			const activeItemsMap: { [id: number]: NonProjectCategoryResponse } = items.reduce(
                (map: { [id: number]: NonProjectCategoryResponse }, item) => {
					if (item.isActive) {
						map[item.id] = item;
					}
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				activeItems,
				activeItemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistActiveItemsReducer } from 'features/Persist/reducerTypes';
import { TicketCategoryResponse } from 'services/tenantManagementService';
import { TICKET_CATEGORY_SET, TICKET_CATEGORY_FETCHING } from './action';

export const ticketCategoryReducer = (state = new PersistActiveItemsReducer<TicketCategoryResponse>(), action: PersistItemsActionType<TicketCategoryResponse > | FetchingActionType): PersistActiveItemsReducer<TicketCategoryResponse > => {
	switch (action.type) {
		case TICKET_CATEGORY_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TICKET_CATEGORY_SET:
			const { items } = action as PersistItemsActionType<TicketCategoryResponse >;

			const itemsMap: { [id: number]: TicketCategoryResponse  } = items.reduce(
                (map: { [id: number]: TicketCategoryResponse  }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			const activeItems = items.filter(x => x.isActive);

			const activeItemsMap: { [id: number]: TicketCategoryResponse } = items.reduce(
                (map: { [id: number]: TicketCategoryResponse }, item) => {
					if (item.isActive) {
						map[item.id] = item;
					}
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				activeItems,
				activeItemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

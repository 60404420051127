import { FinanceConfigClient, TenantIsActiveDeltaRequest, TenantIsActiveResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

export const getCategoriesAction = () => {
	const tenantId = getTenantId()!;
	const client = new FinanceConfigClient();
	return client.getFinanceCategories(tenantId);
}

export const updateCategoriesAction = (model: TenantIsActiveDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new FinanceConfigClient();
	return client.updateFinanceCategories(tenantId, model);
}

export const persistFinanceCategoriesAction = async () => {
	const state = store.getState();

	if (state.persistedFinanceCategory.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getCategoriesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const FINANCE_CATEGORY_FETCHING = 'FINANCE_CATEGORY_FETCHING';
export const FINANCE_CATEGORY_SET = 'FINANCE_CATEGORY_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: FINANCE_CATEGORY_FETCHING,
		fetching
	}
}

const setAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: FINANCE_CATEGORY_SET,
		items
	}
}

import { useCallback } from 'react'
import styles from './multiSelect.module.scss'
import { Checkbox } from '../Checkbox/Checkbox'

type Props = {
	item: any
	getItemText(item: any): string | undefined
	onClick(item: any): void
	isSelected?: boolean
	isKeyboardItem?: boolean
}

export const Option = ({ item, getItemText, onClick, isSelected, isKeyboardItem }: Props) => {
	const onClickCallback = useCallback(
		() => {
			onClick(item);
		},
		[item, onClick]
	)

	const className = `${styles.option} ${isKeyboardItem ? styles.focus : ''}`

	return (
		<div className={className} onMouseDown={onClickCallback}>
			<Checkbox
				value={isSelected}
			/>
			<span className={styles.option_text}>{getItemText(item)}</span>
		</div>
	)
}

import { isLinkValidator } from 'components/Form/validators';
import { getCssVariableValue } from 'utils/cssVariablesUtils';

export const oneLinkHeight = 32;
export const linksCellElementsIdPrefix = 'links_cell';
export const linksCellFormatterElementsIdPrefix = `${linksCellElementsIdPrefix}_formatter`;
export const linksCellEditorElementsIdPrefix = `${linksCellElementsIdPrefix}_editor`;

export const getLinks = value => (value || '').split(';').filter(x => !!x);

export const setCellHeight = (cell, numberOfRows) => {
	const cellRow = cell.getRow();
	const otherLinksCellsRowCount = cellRow.getCells().reduce((acc, x) => {
		if (x === cell) {
			return acc;
		}

		const element = x.getElement();
		if (!element) {
			return acc;
		}

		const elementChildren = [...element.children];
		const linksElement = elementChildren.find(y => (y.id || '').startsWith(linksCellElementsIdPrefix));
		if (!linksElement) {
			return acc;
		}

		const isEditor = linksElement.id.startsWith(linksCellEditorElementsIdPrefix);

		const value = x.getValue();
		const links = getLinks(value);

		acc.push(isEditor ? links.length + 1 : Math.max(links.length, 1));

		return acc;
	}, []);

	const maxNumberOfRows = Math.max(numberOfRows, ...otherLinksCellsRowCount);

	const cellRowSelf = cellRow._getSelf();
	const table = cell.getTable();
	cellRowSelf.setHeight(maxNumberOfRows * oneLinkHeight);
	table.rowManager.adjustTableSize();
}

export const linkFormatterUrlParam = (link) => {
	if (link) {
		return link.startsWith('www') ? `http://${link}` : link;
	}

	return '#';
}

export const linksCellEditorValidator = (inputs) => {
	let valid = true;

	inputs.forEach(input => {
		if (input) {
			const error = isLinkValidator(input.value);
			valid = valid && !error;
			if (error) {
				input.style.border = '1px solid #d00';
				input.style.color = '#d00';
				input.title = error;
			}
			else {
				input.style.borderColor = '1px solid #000';
				input.style.color = getCssVariableValue('--field-color');
				input.title = '';
			}
		}
	});

	return valid;
}

export const linksCellValidator = (cell, value) => {
	const validatorErrors = (value || '').split(';').filter(x => !!x).map(isLinkValidator).filter(x => !!x);
	const valid = !value || validatorErrors.length === 0;
	const element = cell.getElement();
	if (element) {
		element.title = valid ? value : (validatorErrors.length > 0 ? validatorErrors[0] : '');
	}
	return valid;
}

import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { SimpleStatusRequestUpdateSimpleStatusRequestItems, TicketStatusClient, TicketStatusResponse } from 'services/tenantManagementService';

export const getTicketStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketStatusClient();
	return client.getAll(tenantId);
}

export const updateTicketStatusesAction = (ticketStatusesDelta: SimpleStatusRequestUpdateSimpleStatusRequestItems) => {
	const tenantId = getTenantId()!;
	const client = new TicketStatusClient();
	return client.update(tenantId, ticketStatusesDelta);
}

export const persistTicketStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedTicketStatus.fetching) {
		return;
	}

	store.dispatch(setTicketStatusesFetchingAction(true));

	const response = await tryCatchJsonByAction(getTicketStatusesAction);
	if (response.success) {
		store.dispatch(setTicketStatusesAction(response.items || []));
	} else {
		store.dispatch(setTicketStatusesFetchingAction(false));
	}
}

// redux

export const TICKET_STATUS_FETCHING = 'TICKET_STATUS_FETCHING';
export const TICKET_STATUS_SET = 'TICKET_STATUS_SET';

const setTicketStatusesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_STATUS_FETCHING,
		fetching
	}
}

const setTicketStatusesAction = (items: TicketStatusResponse[]): PersistItemsActionType<TicketStatusResponse> =>  {
	return {
		type: TICKET_STATUS_SET,
		items
	}
}

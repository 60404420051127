import Button from 'components/Button';
import { AutoCompleteField, Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterModel } from '../../Filter';
import { BaseColumnModel } from 'components/Table';
import { emptyArray } from 'utils/commonHelper';
import { propertyOf } from 'utils/propertyOf';

type Props = {
	column: BaseColumnModel
	filters: FilterModel[]
	onSave: (filters: FilterModel[]) => void
	onCancel: () => void
}

type StateType = {
	selectedValue?: string | number
}

export const OptionFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<StateType>({})

	useEffect(
		() => {
			const currentFilter = findFilter(filters, column.dbFilterFieldPath);
			if (!currentFilter) {
				setValues({});
			} else if (currentFilter?.property === column.dbFilterFieldPath) {
				setValues({ selectedValue: currentFilter.value })
			}
		},
		[filters, column.dbFilterFieldPath]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.dbFilterFieldPath);
			let newFilter = new FilterModel({
					property: column.dbFilterFieldPath,
					operation: GenericFilterOperationEnum.EQUALS,
					value: values.selectedValue
				})
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}
			if (newFilter) {
				newFilters.push(newFilter);
			}
			onSave && await onSave(newFilters);
		},
		[column.dbFilterFieldPath, filters, values, onSave]
	)

	const clearCallback = useCallback(
		() => {
			setValues({}) ;
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='Apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<AutoCompleteField
							id={propertyOf<StateType>('selectedValue')}
							label='Select option'
							items={column.options || emptyArray}
							getItemId={column.getItemId!}
							getItemText={column.getItemText!}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='Clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}

import Button from 'components/Button';
import { Form } from 'components/Form';
import { SmartContainer, SmartItem } from 'components/SmartContainer/SmartContainer';
import { useCallback, useEffect, useState } from 'react';
import { GenericFilterOperationEnum } from 'services/tenantManagementService';
import { findFilter } from '../../filtersHelper';
import { FilterModel } from '../../Filter';
import { BaseColumnModel } from 'components/Table';
import { SemaphoreField } from 'components/Form';

type Props = {
	column: BaseColumnModel
	filters: FilterModel[]
	onSave: (filters: FilterModel[]) => void
	onCancel: () => void
}

type State = {
	selectedValue: any
}

export const SemaphoreFilter = ({ column, filters, onSave, onCancel }: Props) => {
	const [values, setValues] = useState<State>({ selectedValue: '' })

	useEffect(
		() => {
			const currentFilter = findFilter(filters, column.dbFilterFieldPath);

			if (currentFilter) {
				setValues({ selectedValue: currentFilter.value});
			} else {
				setValues({ selectedValue: ''});
			}
		},
		[filters, column.dbFilterFieldPath]
	)

	const submitCallback = useCallback(
		async () => {
			let newFilters = [...filters];

			const currentFilter = findFilter(newFilters, column.dbFilterFieldPath);
			if (currentFilter) {
				const index = newFilters.indexOf(currentFilter);
				newFilters.splice(index, 1);
			}

			if (values.selectedValue) {
				newFilters.push(
					new FilterModel({
						property: column.dbFilterFieldPath,
						operation: GenericFilterOperationEnum.EQUALS,
						value: values.selectedValue
					})
				)
			}

			onSave && await onSave(newFilters);
		},
		[column.dbFilterFieldPath, filters, values, onSave]
	)

	const clearCallback = useCallback(
		() => {
			setValues({ selectedValue: '' }) ;
		},
		[]
	)

	return (
		<Form
			values={values}
			onChange={setValues}
			onSubmit={submitCallback}
			onCancel={onCancel}
			submitButtonText='Apply'
			disableUnsavedChangesGuard
			render={() => (
				<SmartContainer>
					<SmartItem>
						<SemaphoreField
							id='selectedValue'
							label='Select option'
							colors={column.options}
						/>
					</SmartItem>
				</SmartContainer>
			)}
			renderAdditionalButtons={() => (
				<Button
					text='Clear'
					color='destructive'
					onClick={clearCallback}
				/>
			)}
		/>
	)
}

import { useCallback } from 'react';
import { ControlsCommonProps } from '../../fields';
import { AttachmentResponse, FileResponse, InsertAttachmentRequest } from 'services/tenantManagementService';
import { useItems } from './useItems';
import { RowContainer } from 'components/Layout';
import { AddAttachmentButton } from './AddAttachmentButton';

export type AttachmentProps = ControlsCommonProps<InsertAttachmentRequest[]> & {
	multiple?: boolean
	accept?: string

	oldAttachments?: AttachmentResponse[]
	removeOldAttachment?(attachmentId: number): Promise<void>
	downloadOldAttachment(attachmentId: number): Promise<FileResponse>
}

export const Attachment = ({
	value, // those are new attachments
	onChange,
	disabled,
	onBlur,
	multiple,
	accept,

	oldAttachments, // those are already on backend
	removeOldAttachment,
	downloadOldAttachment
}: AttachmentProps) => {
	const removeNewAttachmentCallback = useCallback(
		(item: InsertAttachmentRequest) => {
			if (!value) {
				return;
			}

			const attachments = value.filter((attachment) => attachment !== item);
			onChange && onChange(attachments);
		},
		[value, onChange]
	)

	const itemsContent = useItems(value, oldAttachments, disabled, removeNewAttachmentCallback, removeOldAttachment, downloadOldAttachment);

	return (
		<RowContainer>
			{!disabled &&
				<AddAttachmentButton
					value={value}
					onChange={onChange}
					disabled={disabled}
					onBlur={onBlur}
					multiple={multiple}
					accept={accept}
				/>
			}
			{itemsContent}
		</RowContainer>
	)
}

import { useCallback, useMemo, useState } from "react"
import styles from './mapPicker.module.scss'
import { Shorten } from "components/Shorten";
import { MapItem } from "./helpers";
import { ArrowDownIcon, ArrowRightIcon, FileIcon, FolderIcon } from "components/icons/icons";

type Props = {
	item: MapItem
	onClick?(item: MapItem): void
	selected: number[]
	expandedByDefault?: boolean
}

export const Item = ({ item, onClick, selected, expandedByDefault }: Props) => {
	const hasChildrens = item.childrens.length > 0;
	const [expanded, setExpanded] = useState(!!expandedByDefault);

	const toggleExpandCallback = useCallback(
		() => {
			setExpanded((state) => !state);
		},
		[]
	)

	const onClickCallback = useCallback(
		() => {
			onClick && onClick(item);
		},
		[item, onClick]
	)

	const childrensContent = useMemo(
		() => {
			if (!hasChildrens || !expanded) {
				return <></>
			}

			return item.childrens.map((item) => (
				<Item
					key={item.id}
					item={item}
					onClick={onClick}
					selected={selected}
				/>
			));
		},
		[selected, item.childrens, hasChildrens, expanded, onClick]
	)

	const expanderContent = useMemo(
		() => {
			if (!hasChildrens) {
				return <div className={styles.expander} />
			}

			const props = {
				width: 8,
				height: 8,
				fill: 'currentColor'
			}

			let expander;
			if (expanded) {
				expander = <ArrowDownIcon {...props} />
			} else {
				expander = <ArrowRightIcon {...props} />
			}

			return (
				<div className={styles.expander} onClick={toggleExpandCallback}>
					{expander}
				</div>
			)
		},
		[expanded, hasChildrens, toggleExpandCallback]
	)

	const isSelected = selected.includes(item.id);

	const icon = useMemo(
		() => {
			const props = {
				width: 16,
				height: 16,
				fill: 'currentColor'
			}

			return hasChildrens ? <FolderIcon {...props} /> : <FileIcon {...props} />;
		},
		[hasChildrens]
	)

	const itemContent = useMemo(
		() => (
			<div className={`${styles.item_content} ${isSelected ? styles.selected : 'clickable'}`} onClick={onClickCallback}>
				{icon}
				<div style={{ width: '8px' }}/>
				<Shorten title={item.name}>{item.name}</Shorten>
			</div>
		),
		[isSelected, onClickCallback, item.name, icon]
	)

	return (
		<>
			<div className={styles.item}>
				{expanderContent}
				<div style={{ width: '2px' }}/>
				{itemContent}
			</div>
			{/* childrens */}
			<div className={styles.nested}>
				{childrensContent}
			</div>
		</>
	)
}

import { FilterModel } from './Filter';

// find first base filter by property
export const findFilter = (filters?: FilterModel[], property?: string) => {
	return filters?.find(filter => filter.property === property ||
		filter.orFilters?.find(orFilter => orFilter.property === property)
	)
}

export const findAllFilters = (filters: FilterModel[], property?: string) => {
	return filters.filter(filter => filter.property === property ||
		filter.orFilters?.find(orFilter => orFilter.property === property)
	)
}

export enum AndOrEnum {
	And = 'And',
	Or = 'Or'
}

import store from 'base/reducer/store';
import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { TimeTravelConfigClient, TimeTravelConfigResponse, UpdateTimeTravelConfigRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getExpenseGeneralConfigAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.getTravelConfig(tenantId);
}

export const updateExpenseGeneralConfigAction = (updateRequest: UpdateTimeTravelConfigRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.updateTravelConfig(tenantId, updateRequest);
}

export const persistExpenseGeneralConfigurationAction = async () => {
	const state = store.getState();

	if (state.persistedExpenseGeneralConfiguration.fetching) {
		return;
	}

	store.dispatch(setExpenseGeneralConfigurationFetchingAction(true));

	const response = await tryCatchJsonByAction(getExpenseGeneralConfigAction);
	if (response.success) {
		store.dispatch(setExpenseGeneralConfigurationAction(response.value || new TimeTravelConfigResponse()));
	} else {
		store.dispatch(setExpenseGeneralConfigurationFetchingAction(false));
	}
}

// redux

export const EXPENSE_GENERAL_CONFIGURATION_FETCHING = 'EXPENSE_GENERAL_CONFIGURATION_FETCHING';
export const EXPENSE_GENERAL_CONFIGURATION_SET = 'EXPENSE_GENERAL_CONFIGURATION_SET';

const setExpenseGeneralConfigurationFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: EXPENSE_GENERAL_CONFIGURATION_FETCHING,
		fetching
	}
}

const setExpenseGeneralConfigurationAction = (value: TimeTravelConfigResponse): PersistValueActionType<TimeTravelConfigResponse> => {
	return {
		type: EXPENSE_GENERAL_CONFIGURATION_SET,
		value
	}
}

import { RowContainer } from 'components/Layout';
import { useMemo } from 'react';
import { formatDate } from 'utils/dateTimeUtils';
import styles from './filterItem.module.scss';
import { formatFilterOperation } from './filterItemHelper';
import { getFormatedId } from 'utils/commonHelper';
import { FilterModel } from '../Filter';
import { BaseColumnModel, FieldTypeEnum } from '../../BaseColumnModel';
import { MapItem, convertToAbsoluteName, getSelectedItem } from 'components/Form/controls/MapPicker/MapPicker/helpers';

type Props = {
	filter: FilterModel
	columns: BaseColumnModel[]
}

const FilterItem = ({ filter, columns }: Props) => {
	const filterItemMemo = useMemo(
		() => {
			const column = columns.find((column) => column.dbFilterFieldPath === filter.property);
			if (!column) {
				return {};
			}

			let value = '';

			switch (column.fieldType) {
				case FieldTypeEnum.Option:
				case FieldTypeEnum.Options:
				case FieldTypeEnum.Enums: {
					const options = column.options || [];
                    for (const option of options) {
                        const optionId = column.getItemId && column.getItemId(option);
                        if (optionId === filter.value) {
                            value = column.getItemText ? column.getItemText(option) : filter.value;
                            break;
                        }
					}
					break;
				}
				case FieldTypeEnum.MapOption: {
					const options = column.options as MapItem[] || [];
					const selectedOption = getSelectedItem(filter.value, options);
					if (selectedOption) {
						value = convertToAbsoluteName(selectedOption, options);
					}
					break;
				}
				case FieldTypeEnum.Date:
					value = formatDate(filter.value);
					break;
				case FieldTypeEnum.FormattedReference:
					value = column.entityPrefix ? getFormatedId(column.entityPrefix, filter.value) : filter.value;
					break;
				case FieldTypeEnum.Boolean:
					value = String(filter.value)
					break;
				case FieldTypeEnum.Number:
				case FieldTypeEnum.Currency:
				case FieldTypeEnum.String:
				default:
					value = filter.value;
			}

			return {
				title: column.title,
				value
			}
		},
		[columns, filter]
	)

	return (
		<span className={styles.item}>
			<RowContainer margin='xsmall' wrap='nowrap'>
				<span>{filterItemMemo.title}</span>
				<span className={styles.operation}>{formatFilterOperation(filter.operation)}</span>
				<span>{filterItemMemo.value}</span>
				{/* da li dodati delete, tipa na hover da se pojavi neki 'X' */}
				{/* <span className={styles.delete_button} onClick={deleteCallback}>X</span> */}
			</RowContainer>
		</span>
	)

}

export default FilterItem;

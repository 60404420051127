import { useCallback, useMemo } from 'react';
import styles from './customItems.module.scss';
import { ItemWrapper } from '../wrappers/ItemWrapper';
import { CustomItem } from './CustomItem';
import { ItemsControlCommonProps } from '../wrappers/useItemsField';
import Button from 'components/Button/Button';

export type CustomItemsProps = ItemsControlCommonProps & {
	renderItem(item: any): any
	uniqueValueKey?: string
}

export const CustomItems = (props: CustomItemsProps) => {
	const {
		value = [], onChange,
		errors = [], registerValidators, validateField, onDelete,
		renderItem,
		disabled,
		uniqueValueKey
	} = props;

    const changeCallback = useCallback(
        (index: number, id: string, newValue: any) => {
			onChange && onChange(index, id, newValue);
		},
        [onChange]
	)

	const itemsContent = useMemo(
		() => {
			return value.map((itemValues, index) => {
				return (
					<div key={uniqueValueKey ? itemValues[uniqueValueKey] : index} className={styles.item}>
						<ItemWrapper
							index={index}
							values={itemValues}
							errors={errors[index]}
							onFieldChange={changeCallback}
							registerValidators={registerValidators}
							validateField={validateField}
							disabled={disabled}
						>
							<CustomItem
								values={itemValues}
								renderItem={renderItem}
								errors={errors}
							/>
						</ItemWrapper>
						<div className={styles.delete}>
							<Button
								text='x'
								color='destructive'
								onClick={onDelete?.bind(null, index)}
								disabled={disabled}
							/>
						</div>
					</div>
				)
			})
		},
		[value, renderItem, errors, disabled, uniqueValueKey, changeCallback, registerValidators, validateField, onDelete]
	)

	return (
		<div className={styles.container}>
			{itemsContent}
		</div>
	)
}

import ClipSpinner from "./ClipSpinner/ClipSpinner"
import Spinner from "./Spinner"
import styles from './overlaySpinner.module.scss'

type Props = {
	size?: number
	color?: string
	useBrandColor?: boolean
	withBackground?: boolean
}

export const OverlaySpinner = ({ size = 20, color = '#000', useBrandColor, withBackground }: Props) => {
	return (
		<div className={`${styles.container} ${withBackground ? styles.background : ''}`}>
			<Spinner>
				<ClipSpinner size={size} color={useBrandColor ? undefined : color} />
			</Spinner>
		</div>
	)
}

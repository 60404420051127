import { TimeTravelStatusResponse } from 'services/tenantManagementService';
import { TIME_AND_TRAVEL_STATUSES_FETCHING, TIME_AND_TRAVEL_STATUSES_SET } from './action';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';

export const timeAndTravelStatusReducer = (state = new PersistItemsReducer<TimeTravelStatusResponse>(), action: PersistItemsActionType<TimeTravelStatusResponse> | FetchingActionType): PersistItemsReducer<TimeTravelStatusResponse> => {
	switch (action.type) {
		case TIME_AND_TRAVEL_STATUSES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TIME_AND_TRAVEL_STATUSES_SET:
			const { items } = action as PersistItemsActionType<TimeTravelStatusResponse>;

			const itemsMap: { [id: number]: TimeTravelStatusResponse } = items.reduce(
                (map: { [id: number]: TimeTravelStatusResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

export interface AppSettingsData {
	apiUrl: string
	alertsApiUrl: string
	siteDomain: string
	myTestDomain: string
	persistInterval: number
}

export class AppSettings {
	private _apiUrl: string = '';
	private _alertsApiUrl: string = '';
	private _siteDomain: string = '';
	private _myTestDomain: string = '';
	private _persistInterval: number = 300000;

    public SetSettings(values: AppSettingsData) {
		 this._apiUrl = values.apiUrl;
		 this._alertsApiUrl = values.alertsApiUrl;
		 this._siteDomain = values.siteDomain;
		 this._myTestDomain = values.myTestDomain;
		 this._persistInterval = values.persistInterval;
	}

	public getApiUrl(): string {
		return this._apiUrl;
	}

	public getAlertsApiUrl(): string {
		return this._alertsApiUrl;
	}

	public getSiteDomain(): string {
		return this._siteDomain;
	}

	public getMyTestDomain(): string {
		return this._myTestDomain;
	}

	public getPersistInterval(): number {
		return this._persistInterval;
	}
}

export default new AppSettings();

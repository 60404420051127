import { useCallback, useEffect, useRef, useMemo } from 'react';
import styles from './fileInput.module.scss';
import { ControlsCommonProps } from '../../fields';
import { InsertAttachmentRequest } from 'services/tenantManagementService';
import { RowContainer } from 'components/Layout';
import { EditIcon, DeleteIcon } from 'components/icons/icons';

export type FileInputProps = ControlsCommonProps<InsertAttachmentRequest[]> & {
	placeholder?: string
	focus?: boolean
	multiple?: boolean
	accept?: string
	display?: any
}

export const FileInput = ({
	value,
	onChange,
	disabled,
	onBlur,
	placeholder,
	multiple,
	accept,
	display
}: FileInputProps) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(
		() => {
			if (value === undefined && inputRef.current) {
				inputRef.current.value = ''
			}
		},
		[value]
	)

	const onChangeCallback = useCallback(
		(e) => {
			let files = e.target.files;
			let filesLength = files.length;

			if (filesLength === 0) {
				onChange && onChange([]);
			}

			const allFiles: InsertAttachmentRequest[] = [];
			for (let i = 0; i < files.length; i++) {
				let file = files[i];

				// Make new FileReader
				let reader = new FileReader();
				// Convert the file to base64 text
				reader.readAsDataURL(file);
				// on reader load something...
				reader.onload = () => {
					// Make a fileInfo Object
					let fileInfo: InsertAttachmentRequest = new InsertAttachmentRequest({
						name: file.name,
						base64EncodedFile: reader.result as string,
					});

					// Push it to the state
					allFiles.push(fileInfo);

					// If all files have been proceed
					if (allFiles.length === filesLength) {
						// Apply Callback function
						onChange && onChange(allFiles);
					}
				}
			}
		},
		[onChange]
	)

	const removeCallback = useCallback(
		(e) => {
			if (disabled) {
				return;
			}
			e.stopPropagation();
			onChange && onChange([]);
		},
		[disabled, onChange]
	)

	const displayContent = useMemo(
		() => {
			if (display) {
				return display;
			}

			if (!value || value.length === 0) {
				return;
			}

			if (value.length === 1) {
				return <>{value[0].name}</>
			} else {
				return <>{value.length} files</>
			}
		},
		[display, value]
	)

	return (
		<div
			className={styles.input}
			onClick={() => { inputRef.current && inputRef.current.click() }}
		>
			<RowContainer justifyContent='space-between'>
				<>{displayContent}</>
				<>
					<RowContainer>
						<EditIcon
							width={16}
							height={16}
							fill='currentColor'
						/>
						<DeleteIcon
							width={16}
							height={16}
							fill='currentColor'
							onClick={removeCallback}
						/>
					</RowContainer>
					<input
						hidden
						ref={inputRef}
						className={styles.input}
						type='file'
						onChange={onChangeCallback}
						onBlur={onBlur}
						placeholder={placeholder}
						disabled={disabled}
						accept={accept}
						multiple={multiple}
					/>
				</>
			</RowContainer>
		</div>
	)
}

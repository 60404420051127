import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';
import { ChangeRequestStatusResponse } from 'services/tenantManagementService';
import { CHANGE_REQUEST_STATUS_SET, CHANGE_REQUEST_STATUS_FETCHING } from './action';

export const changeRequestStatusReducer = (state = new PersistItemsReducer<ChangeRequestStatusResponse>(), action: PersistItemsActionType<ChangeRequestStatusResponse> | FetchingActionType): PersistItemsReducer<ChangeRequestStatusResponse> => {
	switch (action.type) {
		case CHANGE_REQUEST_STATUS_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case CHANGE_REQUEST_STATUS_SET:
			const { items } = action as PersistItemsActionType<ChangeRequestStatusResponse>;

			const itemsMap: { [id: number]: ChangeRequestStatusResponse } = items.reduce(
                (map: { [id: number]: ChangeRequestStatusResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

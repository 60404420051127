import { useMemo, useCallback } from 'react';
import { Checkbox } from '..';
import { ControlsCommonProps } from '../../fields';
import styles from './multiCheckbox.module.scss';

export type MultiCheckboxItemType = {
	id: string | number
	text: string
	disabled?: boolean
}

export type MultiCheckboxProps = ControlsCommonProps<Array<string | number>> & {
	items: MultiCheckboxItemType[]
	column?: boolean
}

export const MultiCheckbox = ({ value, onChange, disabled, items = [], column }: MultiCheckboxProps) => {
	const onChangeCallback = useCallback(
		(id: string | number, val?: boolean) => {
			const newValue = value ? [...value] : [];
			if (val) {
				newValue.push(id)
			} else if (newValue) {
				const index = newValue.indexOf(id);
				newValue.splice(index, 1);
			}
			onChange && onChange(newValue);
		},
		[onChange, value]
	)

	const itemsContent = useMemo(
		() => {
			return items.map((item) => {
				return (
					<div
						className={column ? styles.multi_checkbox_item_column : styles.multi_checkbox_item}
						key={item.id.toString()}
					>
						<Checkbox
							{...item}
							disabled={disabled}
							value={!!value && value.includes(item.id)}
							labelBefore={item.text}
							onChange={val => onChangeCallback(item.id, val)}
						/>
					</div>
				)
			});
		},
		[value, items, disabled, onChangeCallback, column]
	)

	return (
		<div className={column ? styles.container_column : styles.container} >
			{itemsContent}
	  </div>
	)
}

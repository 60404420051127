import React from 'react';
import styles from '../colorPicker.module.scss';

const Arrow = ({ color, placement }) => {
	let className = styles.color_picker_arrow;

	// if (placement === PLACEMENT.RIGHT) {
	// 	className = styles.color_picker_arrow_right;
	// }

	return (
		<div
			className={className}
			style={{
				borderColor: 'transparent transparent ' + color
			}}
		/>
	)
}

export default Arrow;

import { TicketConfigClient, TicketCategoryResponse, UpdateTicketCategoryRequest, InsertTicketCategoryRequest, UpdateOrderRequest } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

export const getTicketCategoryAction = (id: number) => {
	const tenantId = getTenantId()!;
	const ticketConfigClient = new TicketConfigClient();
	return ticketConfigClient.getCategory(tenantId, id);
}

export const getAllTicketCategoriesAction = (parentId: number | undefined, level: number | undefined) => {
	const tenantId = getTenantId()!;
	const ticketConfigClient = new TicketConfigClient();
	return ticketConfigClient.getAllCategories(tenantId, parentId, level);
}

export const createTicketCategoryAction = (model: InsertTicketCategoryRequest) => {
	const tenantId = getTenantId()!;
	const ticketConfigClient = new TicketConfigClient();
	return ticketConfigClient.createCategory(tenantId, model);
}

export const updateTicketCategoryAction = (model: UpdateTicketCategoryRequest) => {
	const tenantId = getTenantId()!;
	const ticketConfigClient = new TicketConfigClient();
	return ticketConfigClient.updateCategory(tenantId, model);
}

export const deleteTicketCategoryAction = (id: number) => {
	const tenantId = getTenantId()!;
	const ticketConfigClient = new TicketConfigClient();
	return ticketConfigClient.deleteCategory(tenantId, id);
}

export const reorderTicketCategoryAction = (model: UpdateOrderRequest[]) => {
	const tenantId = getTenantId()!;
	const ticketConfigClient = new TicketConfigClient();
	return ticketConfigClient.updateTicketCategoryOrders(tenantId, model);
}

export const persistTicketCategoryAction = async () => {
	const state = store.getState();

	if (state.persistedTicketCategory.fetching) {
		return;
	}

	store.dispatch(setTicketCategoryFetchingAction(true));

	const response = await tryCatchJsonByAction(getAllTicketCategoriesAction);
	if (response.success) {
		store.dispatch(setTicketCategoryAction(response.items || []));
	} else {
		store.dispatch(setTicketCategoryFetchingAction(false));
	}
}

// redux

export const TICKET_CATEGORY_FETCHING = 'TICKET_CATEGORY_FETCHING';
export const TICKET_CATEGORY_SET = 'TICKET_CATEGORY_SET';

const setTicketCategoryFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_CATEGORY_FETCHING,
		fetching
	}
}

const setTicketCategoryAction = (items: TicketCategoryResponse[]): PersistItemsActionType<TicketCategoryResponse> =>  {
	return {
		type: TICKET_CATEGORY_SET,
		items
	}
}

import { Subtract } from 'utility-types';
import { SimpleColorPicker, SimpleColorPickerProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const ColorField = (props: Subtract<SimpleColorPickerProps, ControlsCommonProps<string>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={SimpleColorPicker}
		/>
	)
}

import React, { Suspense, useCallback } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ONBOARDING_ROUTE } from 'base/constants/routes';
import { getToken } from 'features/Token/token';
import { getIsTenantInitialized, getSubdomainFromStorage } from 'features/Tenant/tenantUtils';
import { getSubdomainFromUrl } from 'features/Tenant/tenantUtils';

export type RedirectToLoginState = {
	from?: string
}

const HomePage = React.lazy(() => import(/* webpackChunkName: "home" */ './Home/HomePage'))
const OnboardingPage = React.lazy(() => import(/* webpackChunkName: "onboarding" */ './Onboarding/OnboardingPage'))

const PageRouter = () => {
	const location = useLocation();

	const isLoggedIn = useCallback(
		() => !!getToken(),
		[]
	)

	const isRegistrationFinished = useCallback(
		() => !!getIsTenantInitialized(),
		[]
	)

	const shouldRedirectToOnboardingPage = useCallback(
		() => !isLoggedIn() || !isRegistrationFinished(),
		[isLoggedIn, isRegistrationFinished]
	)

	// Use Case 1. for example, URL is opened from email and user is logged in.
	// we need to check if it is the same tenant, checking current URL subdomain versus subdomain from login.
	const isAppropriateTenant = useCallback(
		() => {
			const subdomainFromStorage = getSubdomainFromStorage();
			const subdomainFromUrl = getSubdomainFromUrl();

			return subdomainFromStorage === subdomainFromUrl;
		},
		[]
	)

	const renderHomePage = useCallback(
		() => {
			const shouldRedirect = shouldRedirectToOnboardingPage();
			const isWrongTenant = !isAppropriateTenant();

			if (shouldRedirect || isWrongTenant) {
				return (
					<Redirect
						to={{
							pathname: ONBOARDING_ROUTE,
							// Use Case 2. for example, URL is opened from email and user is NOT logged in
							// we need to save original 'from' pathname and after login we redirect to that 'from'
							state: { from: location.pathname } as RedirectToLoginState
						}}
					/>
				)
			} else {
				return <HomePage />
			}
		},
		[shouldRedirectToOnboardingPage, location.pathname, isAppropriateTenant]
	)

	return (
		<Suspense fallback={false}>
			<Switch>
				<Route path={ONBOARDING_ROUTE} component={OnboardingPage} />
				{process.env.REACT_APP_TYPE === 'registration' && <Route component={OnboardingPage} />}
				{process.env.REACT_APP_TYPE !== 'registration' && <Route render={renderHomePage} />}
			</Switch>
		</Suspense>
	);
};

export default PageRouter;

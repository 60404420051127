import { Subtract } from 'utility-types';
import { MultiSelect, MultiSelectProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const MultiSelectField = (props: Subtract<MultiSelectProps, ControlsCommonProps<Array<string | number>>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={MultiSelect}
		/>
	)
}

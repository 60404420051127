import { ControlsCommonProps } from "components/Form/fields"
import { useCallback, useMemo } from "react"
import { MapItem } from "./MapPicker/helpers"
import { MapPicker } from "./MapPicker/MapPicker"

export type MapMultiPickerProps = ControlsCommonProps<number[] | undefined> & {
	items: MapItem[]
	description?: string
	loading?: boolean
}

export const MapMultiPicker = (props: MapMultiPickerProps) => {
	const {	value, onChange, ...rest } = props;

	const initialSelected = useMemo(
		() => value ? [...value] : [],
		[value]
	)

	const changeCallback = useCallback(
		(selected: number[]) => {
			onChange && onChange(selected);
		},
		[onChange]
	)

	return (
		<MapPicker
			{...rest}
			initialSelected={initialSelected}
			onChange={changeCallback}
		/>
	)
}

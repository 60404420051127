import { useCallback } from 'react';
import styles from './cover.module.scss';

type Props = {
	onClick(): void
	transparent?: boolean
}

export const Cover = ({ onClick, transparent }: Props) => {
	const onClickCallback = useCallback(
		(e) => {
			e.stopPropagation();
			onClick();

			// // enable that click event also goes thru cover
			// const elements = document.elementsFromPoint(e.pageX, e.pageY);
			// // svg elements don't have click method, so we can't just use elements[1].click,
			// // but we need to find first parent that has click method
			// for (let i = 1; i < elements.length; i++) {
			// 	const element = elements[i];
			// 	if ((element as any).click) {
			// 		(element as any).click();
			// 		break;
			// 	}
			// }
		},
		[onClick]
	)

	return (
		<div
			className={`${styles.container} ${transparent ? styles.transparent : ''}`}
			onClick={onClickCallback}
		/>
	)
}

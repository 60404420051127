import { Subtract } from 'utility-types';
import { Radio, RadioProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const RadioField = (props: Subtract<RadioProps, ControlsCommonProps<string | number | boolean>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={Radio}
		/>
	)
}

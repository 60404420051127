import { SimpleStatusRequestUpdateSimpleStatusRequestItems, TimeTravelStatusClient, TimeTravelStatusResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

// api

export const getTimeAndTravelStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelStatusClient();
	return client.getAll(tenantId);
}

export const updateTimeAndTravelStatusesAction = (model: SimpleStatusRequestUpdateSimpleStatusRequestItems ) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelStatusClient();
	return client.update(tenantId, model);
}

export const persistTimeAndTravelStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedTimeAndTravelStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getTimeAndTravelStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const TIME_AND_TRAVEL_STATUSES_SET = 'TIME_AND_TRAVEL_STATUSES_SET';
export const TIME_AND_TRAVEL_STATUSES_FETCHING = 'TIME_AND_TRAVEL_STATUSES_FETCHING';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TIME_AND_TRAVEL_STATUSES_FETCHING,
		fetching
	}
}

const setAction = (items: TimeTravelStatusResponse[]): PersistItemsActionType<TimeTravelStatusResponse> =>  {
	return {
		type: TIME_AND_TRAVEL_STATUSES_SET,
		items
	}
}

import { globalErrorKey } from "components/Form/Form";
import { FieldMessage } from "components/Form/addons";
import { RowContainer } from "components/Layout";

type Props = {
	values: any
	renderItem(item: any): any
	errors: any
}

export const CustomItem = ({ values, renderItem, errors }: Props) => {
	const content = renderItem(values);

	return (
		<>
			{content}
			{errors && errors[globalErrorKey] &&
				<RowContainer justifyContent='flex-end'>
					<FieldMessage message={errors[globalErrorKey]} />
				</RowContainer>
			}
		</>
	)
}

import { TravelRequestResponse } from 'services/tenantManagementService';
import { TRAVEL_REQUESTS_FETCHING, TRAVEL_REQUESTS_SET } from './action';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';

export const travelRequestsReducer = (state = new PersistItemsReducer<TravelRequestResponse>(), action: PersistItemsActionType<TravelRequestResponse> | FetchingActionType): PersistItemsReducer<TravelRequestResponse> => {
	switch (action.type) {
		case TRAVEL_REQUESTS_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TRAVEL_REQUESTS_SET:
			const { items } = action as PersistItemsActionType<TravelRequestResponse>;

			const itemsMap: { [id: number]: TravelRequestResponse } = items.reduce(
                (map: { [id: number]: TravelRequestResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { Subtract } from 'utility-types';
import { Field, ControlsCommonProps, FieldProps } from './Field';
import { Attachment, AttachmentProps } from '../controls';
import { InsertAttachmentRequest } from 'services/tenantManagementService';

export const AttachmentField = (props: Subtract<AttachmentProps, ControlsCommonProps<InsertAttachmentRequest[]>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={Attachment}
		/>
	)
}

import { FetchingProjectValueActionType, PersistProjectValueActionType } from 'features/Persist/actionTypes';
import { PersistProjectValueReducer } from 'features/Persist/reducerTypes';
import { ScheduleConfigurationResponse } from 'services/tenantManagementService';
import { SCHEDULE_CONFIGURATION_FETCHING, SCHEDULE_CONFIGURATION_SET } from './action';

export const scheduleConfigurationReducer = (state = new PersistProjectValueReducer<ScheduleConfigurationResponse>(), action: PersistProjectValueActionType<ScheduleConfigurationResponse> | FetchingProjectValueActionType): PersistProjectValueReducer<ScheduleConfigurationResponse> => {
	switch (action.type) {
		case SCHEDULE_CONFIGURATION_FETCHING: {
			// fetching is only for this projectId
			const { fetching, projectId } = action as FetchingProjectValueActionType;

			// isAnyFetching is checking if fetching for any project is in progress
			const isAnyFetching = fetching || !!Object.values(state.projectMap).find((item) => item?.fetching === true)

			return {
				...state,
				fetching: isAnyFetching,
				projectMap: {
					...state.projectMap,
					[projectId]: {
						value: state.projectMap[projectId]?.value || new ScheduleConfigurationResponse(),
						fetching
					}
				}
			}
		}
		case SCHEDULE_CONFIGURATION_SET: {
			const { value, projectId } = action as PersistProjectValueActionType<ScheduleConfigurationResponse>;

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					fetching: false,
					value
				}
			}

			return {
				value,
				fetching: false,
				isInitialized: true,
				projectMap
			}
		}
		default:
			return state;
	}
};

import { GenericFilterOperationEnum, GenericSortModel } from 'services/tenantManagementService';
import { FilterModel } from '../BaseTable/Filter/Filter';
import { BaseColumnModel, FieldTypeEnum } from '../BaseTable/BaseColumnModel';
import { defaultPaginationSize } from '../BaseTable'
import { noop } from 'utils/commonHelper';
import { formatServerDate } from 'utils/dateTimeUtils'
import { sortByString } from 'utils/stringUtil';

// filter methods

type PrimitiveType = number | string | boolean

const arePrimitiveTypesEqual = (first: PrimitiveType, second: PrimitiveType) => {
	return first === second;
}

const arePrimitiveTypesNotEqual = (first: PrimitiveType, second: PrimitiveType) => {
	return first !== second;
}

const like = (value: string, filterValue: string) => {
	if (value === filterValue) {
		return true;
	}
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return value.toLowerCase().includes(filterValue.toLowerCase());
}

const likeStartsWith = (value: string, filterValue: string) => {
	if (value === filterValue) {
		return true;
	}
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return value.toLowerCase().startsWith(filterValue.toLowerCase());
}

const likeEndsWith = (value: string, filterValue: string) => {
	if (value === filterValue) {
		return true;
	}
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return value.toLowerCase().endsWith(filterValue.toLowerCase());
}

const greaterEqual = (value: number, filterValue: number) => {
	return value >= filterValue;
}

const greater = (value: number, filterValue: number) => {
	return value > filterValue;
}

const lessEqual = (value: number, filterValue: number) => {
	return value <= filterValue;
}

const less = (value: number, filterValue: number) => {
	return value < filterValue;
}

const areDatesEqual = (first: Date, second: Date) => {
	if (first === second) {
		return true;
	}
	if (first === undefined || second === undefined) {
		return false;
	}

	const firstFormatted = formatServerDate(first)
	const secondFormatted = formatServerDate(second)

	return firstFormatted === secondFormatted;
}

const areDatesNotEqual = (first: Date, second: Date) => {
	return !areDatesEqual(first, second);
}

const dateGreaterEqual = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	const fullYear = value.getFullYear();
	const month = value.getMonth();
	const dayOfTheMonth = value.getDate();

	const filterFullYear = filterValue.getFullYear();
	const filterMonth = filterValue.getMonth();
	const filterDayOfTheMonth = filterValue.getDate();

	return (
		fullYear > filterFullYear ||
		(fullYear === filterFullYear && month > filterMonth) ||
		(fullYear === filterFullYear && month === filterMonth && dayOfTheMonth >= filterDayOfTheMonth)
	)
}

const dateGreater = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	const fullYear = value.getFullYear();
	const month = value.getMonth();
	const dayOfTheMonth = value.getDate();

	const filterFullYear = filterValue.getFullYear();
	const filterMonth = filterValue.getMonth();
	const filterDayOfTheMonth = filterValue.getDate();

	return (
		fullYear > filterFullYear ||
		(fullYear === filterFullYear && month > filterMonth) ||
		(fullYear === filterFullYear && month === filterMonth && dayOfTheMonth > filterDayOfTheMonth)
	)
}

const dateLessEqual = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return !dateGreater(value, filterValue);
}

const dateLess = (value: Date, filterValue: Date) => {
	if (value === undefined || filterValue === undefined) {
		return false;
	}

	return !dateGreaterEqual(value, filterValue);
}

const doesRowSatisfy = (rowData: any, filteredColumn: BaseColumnModel, filter: FilterModel) => {
	let compareFunction: (value: any, filterValue: any) => boolean = noop as any;

	switch (filteredColumn.fieldType) {
		case FieldTypeEnum.Option:
		case FieldTypeEnum.Options:
		case FieldTypeEnum.MapOption:
		case FieldTypeEnum.Boolean:
		case FieldTypeEnum.Enums:
			compareFunction = arePrimitiveTypesEqual;
			break;
		case FieldTypeEnum.FormattedReference:
			switch (filter.operation) {
				case GenericFilterOperationEnum.EQUALS:
					compareFunction = arePrimitiveTypesEqual;
					break;
				case GenericFilterOperationEnum.NOT_EQUALS:
					compareFunction = arePrimitiveTypesNotEqual;
					break;
			}
			break;
		case FieldTypeEnum.String:
			switch (filter.operation) {
				case GenericFilterOperationEnum.EQUALS:
					compareFunction = arePrimitiveTypesEqual;
					break;
				case GenericFilterOperationEnum.NOT_EQUALS:
					compareFunction = arePrimitiveTypesNotEqual;
					break;
				case GenericFilterOperationEnum.LIKE:
					compareFunction = like;
					break;
				case GenericFilterOperationEnum.LIKE_STARTSWITH:
					compareFunction = likeStartsWith;
					break;
				case GenericFilterOperationEnum.LIKE_ENDSWITH:
					compareFunction = likeEndsWith;
					break;
			}
			break;
		case FieldTypeEnum.Number:
		case FieldTypeEnum.Currency:
			switch (filter.operation) {
				case GenericFilterOperationEnum.EQUALS:
					compareFunction = arePrimitiveTypesEqual;
					break;
				case GenericFilterOperationEnum.NOT_EQUALS:
					compareFunction = arePrimitiveTypesNotEqual;
					break;
				case GenericFilterOperationEnum.GREATER_EQUAL_THAN:
					compareFunction = greaterEqual;
					break;
				case GenericFilterOperationEnum.GREATER_THAN:
					compareFunction = greater;
					break;
				case GenericFilterOperationEnum.LESS_EQUAL_THAN:
					compareFunction = lessEqual;
					break;
				case GenericFilterOperationEnum.LESS_THAN:
					compareFunction = less;
					break;
			}
			break;
		case FieldTypeEnum.Date:
			switch (filter.operation) {
				case GenericFilterOperationEnum.EQUALS:
					compareFunction = areDatesEqual;
					break;
				case GenericFilterOperationEnum.NOT_EQUALS:
					compareFunction = areDatesNotEqual;
					break;
				case GenericFilterOperationEnum.GREATER_EQUAL_THAN:
					compareFunction = dateGreaterEqual;
					break;
				case GenericFilterOperationEnum.GREATER_THAN:
					compareFunction = dateGreater;
					break;
				case GenericFilterOperationEnum.LESS_EQUAL_THAN:
					compareFunction = dateLessEqual;
					break;
				case GenericFilterOperationEnum.LESS_THAN:
					compareFunction = dateLess;
					break;
			}
			break;
		}

	if (rowData._children) {
		return treeCompareFunction(rowData, filteredColumn, filter, compareFunction);
	}

	return compareFunction(rowData[filteredColumn.field!], filter.value)
}

export const filterData = (data: any[], filters: FilterModel[], columns: BaseColumnModel[]) => {
	if (filters.length === 0 || data.length === 0) {
		return data;
	}

	let filteredData = [...data];

	for (const filter of filters) {
		// property
		if (filter.property) {
			const filteredColumn = columns.find((column) => filter.property === column.dbFilterFieldPath);
			if (!filteredColumn) {
				continue;
			}

			const afterFilterData: any[] = [];
			for (const rowData of filteredData) {
				const rowDataCopy = structuredClone(rowData)
				if (doesRowSatisfy(rowDataCopy, filteredColumn, filter)) {
					afterFilterData.push(rowDataCopy);
				}
			}

			filteredData = afterFilterData;
		}
		// or filters
		else if (filter.orFilters) {
			const afterFilterData: any[] = [];

			for (const rowData of filteredData) {
				for (const orFilter of filter.orFilters) {
					const filteredColumn = columns.find((column) => orFilter.property === column.dbFilterFieldPath);
					if (!filteredColumn) {
						continue;
					}
					const rowDataCopy = structuredClone(rowData)
					if (doesRowSatisfy(rowDataCopy, filteredColumn, orFilter)) {
						afterFilterData.push(rowDataCopy);
						break;
					}
				}

				filteredData = afterFilterData;
			}
		}
	}

	return filteredData;
}

// sort methods

const sortByNumber = (data: any[], property: string) => {
	const sortedData = [...data];

	sortedData.sort((first, second) => {
		const firstValue = first[property] || 0;
		const secondValue = second[property] || 0;
		return firstValue - secondValue;
	})

	return sortedData;
}

const sortByDate = (data: any[], property: string) => {
	const sortedData = [...data];

	sortedData.sort((first, second) => {
		const firstTime = first[property] ? first[property].getTime() : 0;
		const secondTime = second[property] ? second[property].getTime() : 0;
		return firstTime - secondTime;
	})

	return sortedData;
}

export const sortData = (data: any[], sort: GenericSortModel, columns: BaseColumnModel[]) => {
	if (data.length === 0 || sort.property === undefined) {
		return data;
	}

	const sortColumn = columns.find((column) => sort.property === column.dbFilterFieldPath);

	if (sortColumn === undefined) {
		return data;
	}

	let sortedData: any[] = [];

	switch (sortColumn.fieldType) {
		case FieldTypeEnum.Option:
		case FieldTypeEnum.Options:
		case FieldTypeEnum.Boolean:
		case FieldTypeEnum.Number:
		case FieldTypeEnum.Currency:
		case FieldTypeEnum.FormattedReference:
			sortedData = sortByNumber(data, sortColumn.field);
			break;
		case FieldTypeEnum.Date:
			sortedData = sortByDate(data, sortColumn.field);
			break;
		case FieldTypeEnum.String:
		case FieldTypeEnum.Enums:
		default:
			sortedData = sortByString(data, sortColumn.field);
			break;
	}

	return sort.isAsc ? sortedData : sortedData.reverse();
}

// pagination methods

export const paginateData = (data: any[], offset: number = 0, limit: number = defaultPaginationSize) => {
	return data.slice(offset, offset + limit);
}

// tree local filtering

const treeCompareFunction = (rowData: any, filteredColumn: BaseColumnModel, filter: FilterModel, compareFunction: (value: any, filterValue: any) => boolean) => {
	let isSatisfied = false;
	const parentSatisfied = compareFunction(rowData[filteredColumn.field!], filter.value);
	if (parentSatisfied) {
		isSatisfied = true;
	}

	const childIndexForDelete: any[] = [];
	for (let i = 0; i < rowData?._children?.length; i++) {
		const child = rowData._children[i];
		const childSatisfied = treeCompareFunction(child, filteredColumn, filter, compareFunction);
		if (childSatisfied) {
			isSatisfied = true;
		} else {
			childIndexForDelete.push(i)
		}
	}
	rowData._children = rowData?._children?.filter((x, index) => !childIndexForDelete.includes(index));
	return isSatisfied;
}

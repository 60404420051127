import appSettings from 'settings/appSettings';
import { getToken } from 'features/Token/token';

export abstract class BaseClient {
    protected async transformOptions(originalOptions: RequestInit): Promise<RequestInit> {
		const token =  getToken();

        return Promise.resolve({
			...originalOptions,
			headers: {
				...originalOptions.headers,
				"Authorization": `Bearer ${token}`
			},
        });
	}

	protected getBaseUrl(defaultUrl: string, baseUrl?: string): string {
		return appSettings.getApiUrl()
    }
}

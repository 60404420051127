import { RowContainer } from 'components/Layout';
import FilterItem from './FilterItem';
import { useGetOrFiltersCallback } from './useGetOrFiltersCallback';
import styles from './filterItem.module.scss'
import { useMemo } from 'react';
import { BaseColumnModel } from '../../BaseColumnModel';
import { FilterModel } from '../Filter';

type Props = {
	filters: FilterModel[]
	columns: BaseColumnModel[]
}

export const FilterItems = ({ filters, columns }: Props) => {
	const getOrFiltersCallback = useGetOrFiltersCallback(columns);

	const itemsContent = useMemo(
		() => {
			const content: React.ReactNode[] = [];
			for (let i = 0; i < filters.length; i++) {
				const filter = filters[i];

				if (filter.property && columns.find(column => filter.property === column.dbFilterFieldPath)) {
					content.push(<FilterItem key={i} filter={filter} columns={columns} />);
					if (i < filters.length - 1) {
						content.push(<span className={styles.conjunction}>AND</span>);
					}
				}

				if (filter.orFilters && columns.find(column => filter.orFilters?.find(orFilter => orFilter.property === column.dbFilterFieldPath))) {
					content.push(getOrFiltersCallback(filter.orFilters))
					if (i < filters.length - 1) {
						content.push(<span className={styles.conjunction}>AND</span>);
					}
				}
			}

			return content;
		},
		[columns, filters, getOrFiltersCallback]
	)

	return (
		<RowContainer alignItems='center' wrap='nowrap'>
			{itemsContent}
		</RowContainer>
	)
}

import { TicketConfigClient, UrgencyResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';


export const getTicketUrgenciesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.getUrgencies(tenantId);
}

export const persistTicketUrgencyAction = async () => {
	const state = store.getState();

	if (state.persistedTicketUrgency.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getTicketUrgenciesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const TICKET_URGENCY_FETCHING = 'TICKET_URGENCY_FETCHING';
export const TICKET_URGENCY_SET = 'TICKET_URGENCY_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_URGENCY_FETCHING,
		fetching
	}
}

const setAction = (items: UrgencyResponse[]): PersistItemsActionType<UrgencyResponse> =>  {
	return {
		type: TICKET_URGENCY_SET,
		items
	}
}

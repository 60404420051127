import { AutoComplete } from "components/Form"
import { ProjectResponse } from "services/tenantManagementService"
import { EntityPrefixEnum, getFormatedId } from "utils/commonHelper"
import { SmartFormGroup } from "components/SmartContainer/SmartContainer"
import { UseProjectsAndCategoriesOptions, useProjects } from "../projectHooks"

type Props = UseProjectsAndCategoriesOptions & {
	value: string | number | undefined
	onChange?: (projectId: number) => void
	disabled?: boolean
}

export const ProjectSelect = ({
	value,
	onChange,
	disabled,
	...options
}: Props) => {
	const { projects, loadingProjects } = useProjects(options)

	return (
		<SmartFormGroup label='Project'>
			<AutoComplete
				value={value}
				onChange={onChange}
				items={projects}
				getItemId={(item: ProjectResponse) => item.id}
				getItemText={(item: ProjectResponse) => `${getFormatedId(EntityPrefixEnum.PROJECT, item.id)} - ${item.name}`}
				loading={loadingProjects}
				disabled={disabled}
			/>
		</SmartFormGroup>
	)
}

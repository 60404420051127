import React, { memo, useMemo } from 'react';
import { HeaderType } from './Table';
import styles from './table.module.scss';
import Button from 'components/Button/Button';
import commonStyles from 'assets/styles/common.module.scss'
import { globalErrorKey } from 'components/Form/Form';
import { RowContainer } from 'components/Layout';
import { FieldMessage } from 'components/Form/addons';

type Props = {
	headers: HeaderType[]
	fields: React.ReactElement[]
	errors: any
	DraggableHandle?: JSX.Element
	isAnyRowDeletable: boolean
	rowIsDeletable: boolean
	index: number
	onDelete: (index: number) => void
}

export const TableRow = ({ headers, fields, errors, DraggableHandle, isAnyRowDeletable, rowIsDeletable, index, onDelete }: Props) => {
	const content = useMemo(
		() => {
			return headers.map((header, index) => (
				<div key={index} className={styles.cell} style={{ flex: header.size }}>
					{fields[index]}
				</div>
			))
		},
		[fields, headers]
	)

	return (
		<>
			<div style={{ display: 'flex' }}>
				{DraggableHandle}
				{content}
				{isAnyRowDeletable &&
					<div className={`${styles.cell} ${rowIsDeletable ? '' : commonStyles.hidden_no_height}`}>
						<Button
							text='x'
							color='destructive'
							onClick={onDelete?.bind(null, index)}
						/>
					</div>
				}
			</div>
			{errors && errors[globalErrorKey] &&
				<RowContainer justifyContent='flex-end'>
					<FieldMessage message={errors[globalErrorKey]} />
				</RowContainer>
			}
		</>
	)
}

export default memo(TableRow)

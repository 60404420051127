import store from 'base/reducer/store';
import { ProjectTeamMemberClient, InsertProjectTeamMemberRequest, UpdateProjectTeamMemberRequest, ProjectTeamMemberResponse, TokenTypeEnum, GenericFilterModelCollection, ExportDataModel } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingProjectItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';

// async api calls

export const getAllTeamMembersAction = (projectId: number, roleType?: TokenTypeEnum, isActive?:boolean, offset?: number, limit?: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.getAll(tenantId, projectId, roleType, isActive, offset, limit);
}

export const getAllTeamMembersByProjectGenericFilteringAction = (projectId: number, model: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.getAllByProjectGenericFiltering(tenantId, projectId, model);
}

export const getTeamMemberAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();

	return client.get(tenantId, projectId, id);
}

export const createTeamMemberAction = (projectId: number, model: InsertProjectTeamMemberRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.insert(tenantId, projectId, model);
}

export const updateTeamMemberAction = (projectId: number, model: UpdateProjectTeamMemberRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();
	return client.update(tenantId, projectId, model)
}

export const deleteTeamMemberAction = (projectId: number, id: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();

	return client.delete(tenantId, projectId, id);
}

export const exportProjectTeamMembersByProjectAction = (projectId: number, filter: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new ProjectTeamMemberClient();

	return client.export(tenantId, projectId, filter);
}

export const persistTeamMembersAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedTeamMember.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setTeamMembersFetchingAction(true, projectId));

	const bindedAction = getAllTeamMembersAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success && response.items) {
		store.dispatch(setTeamMembersAction(projectId, response.items || []));
	} else {
		store.dispatch(setTeamMembersFetchingAction(false, projectId));
	}
}

// persist team members redux

export const TEAM_MEMBERS_FETCHING = 'TEAM_MEMBERS_FETCHING';
export const TEAM_MEMBERS_SET = 'TEAM_MEMBERS_SET';

const setTeamMembersFetchingAction = (fetching: boolean, projectId: number): FetchingProjectItemsActionType => {
	return {
		type: TEAM_MEMBERS_FETCHING,
		fetching,
		projectId
	}
}

const setTeamMembersAction = (projectId: number, items: ProjectTeamMemberResponse[]): PersistProjectItemsActionType<ProjectTeamMemberResponse> => {
	return {
		type: TEAM_MEMBERS_SET,
		items,
		projectId
	}
}

import { BaseColumnModel } from "../BaseTable";

// for tabulator custom calc function see: https://tabulator.info/docs/5.5/column-calcs#func-custom
// local table binds arguments because tabulator is not aware of our pagination

export const sumCalc = (filteredData: any[], column: BaseColumnModel/*, paginatedData: any[], calcParams: any*/) => {
	let calculated = 0;

	for (let rowData of filteredData || []) {
		const value = rowData[column.field];
		calculated += value || 0;
	}

	return calculated;
}

import { ActionType } from 'features/Persist/actionTypes';
import { ThemeEnum } from './reducer'

export type ThemeAction = ActionType & {
	theme: ThemeEnum
}

export const THEME_SET = 'THEME_SET';

export const setThemeAction = (theme: ThemeEnum) => {
	return {
		type: THEME_SET,
		theme
	}
}

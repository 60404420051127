import React, { useCallback, memo, useMemo } from 'react';
import { ModuleActivityEnum } from 'services/tenantManagementService';
import ProjectActiveModuleCheckbox from './ProjectActiveModuleCheckbox';
import { ProjectActiveModulesCommonProps } from './ProjectActiveModulesField';
import { isFieldDisabled } from './projectActiveModulesHelper';

// INFO: remove implemented modules when all modules are finished
const implementedModules = [
	ModuleActivityEnum.Project,
	ModuleActivityEnum.Ticket,
	ModuleActivityEnum.Scope,
	ModuleActivityEnum.Testing,
	ModuleActivityEnum.Training,
	ModuleActivityEnum.Time,
	ModuleActivityEnum.Risk,
	ModuleActivityEnum.Finance,
	ModuleActivityEnum.Communication,
	ModuleActivityEnum.Schedule
]

export function ProjectActiveModules(props: ProjectActiveModulesCommonProps) {
	const {
		value = [], onChange,
		errors = [], registerValidators, validateField,
		disabled
	} = props;

    const changeCallback = useCallback(
        (index: number, id: string, newValue: any) => {
			onChange && onChange(index, id, newValue);
		},
        [onChange]
	)

	const rowsContent = useMemo(
		() => {
			return value.map((rowValues, index) => {
				if (!implementedModules.includes(rowValues.id)) {
					return <React.Fragment key={index} />
				}
				return (
					<ProjectActiveModuleCheckbox
						key={index}
						index={index}
						values={rowValues}
						errors={errors[index]}
						onFieldChange={changeCallback}
						registerValidators={registerValidators}
						validateField={validateField}
						disabled={disabled || isFieldDisabled(rowValues.id, value)}
					/>
				)
			})
		},
		[value, errors, disabled, changeCallback, registerValidators, validateField]
	)

	return (
		<>
			<h5>Active in following modules</h5>
			<div>{rowsContent}</div>
		</>
	);
};

export default memo(ProjectActiveModules);

import { Subtract } from 'utility-types';
import { Field, ControlsCommonProps, FieldProps } from './Field';
import { MultiCheckbox, MultiCheckboxProps } from '../controls';

export const MultiCheckboxField = (props: Subtract<MultiCheckboxProps, ControlsCommonProps<Array<string | number>>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={MultiCheckbox}
		/>
	)
}

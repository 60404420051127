import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { PersistValueReducer } from 'features/Persist/reducerTypes';
import { TimeTravelConfigResponse } from 'services/tenantManagementService';
import { EXPENSE_GENERAL_CONFIGURATION_FETCHING, EXPENSE_GENERAL_CONFIGURATION_SET } from './action';

export const expenseGeneralConfigurationReducer = (state = new PersistValueReducer<TimeTravelConfigResponse>(), action: PersistValueActionType<TimeTravelConfigResponse> | FetchingActionType): PersistValueReducer<TimeTravelConfigResponse> => {
	switch (action.type) {
		case EXPENSE_GENERAL_CONFIGURATION_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case EXPENSE_GENERAL_CONFIGURATION_SET:
			const { value } = action as PersistValueActionType<TimeTravelConfigResponse>;

			return {
				value,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

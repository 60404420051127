import { RowComponent } from 'tabulator-tables';

export const handleExpandRows = (rows: RowComponent[], currentRowLevel: number, expandRowLevel: number) => {
	for (let row of rows) {
		if (!row.isTreeExpanded() && expandRowLevel > currentRowLevel) {
			row.treeExpand();
		} else if (row.isTreeExpanded() && expandRowLevel <= currentRowLevel) {
			row.treeCollapse();
		}

		const children = row.getTreeChildren();
		if (children) {
			handleExpandRows(children, currentRowLevel + 1, expandRowLevel);
		}
	}
}
import store from 'base/reducer/store';
import { GenericFilterModelCollection, BusinessPartnerClient, BusinessPartnerInsertRequestModel, BusinessPartnerResponseModel, BusinessPartnerUpdateRequestModel, BusinessPartnerResponseModelSimpleResponseModel, ExportDataModel } from 'services/tenantManagementService'
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

export const getBusinessPartnersAction = () => {
	const tenantId = getTenantId()!;
	const client = new BusinessPartnerClient();
	return client.getAll(tenantId);
}

export const getBusinessPartnersGenericAction = (genericFilter: GenericFilterModelCollection) => {
	const tenantId = getTenantId()!;
	const client = new BusinessPartnerClient();
	return client.getAllGenericFilter(tenantId, genericFilter);
}

export const getBusinessPartnerAction = (id: number) => {
	const state = store.getState();

	if (state.persistedBusinessPartner.isInitialized && state.persistedBusinessPartner.itemsMap[id]) {
		const response = new BusinessPartnerResponseModelSimpleResponseModel({
			success: true,
			value: state.persistedBusinessPartner.itemsMap[id]
		});
		return Promise.resolve(response);
	}

	const tenantId = getTenantId()!;
	const client = new BusinessPartnerClient();
	return client.get(id, tenantId);
}

export const createBusinessPartnerAction = (partner: BusinessPartnerInsertRequestModel ) => {
	const tenantId = getTenantId()!;
	const client = new BusinessPartnerClient();
	return client.insert(tenantId, partner);
}

export const updateBusinessPartnerAction = (partner: BusinessPartnerUpdateRequestModel ) => {
	const tenantId = getTenantId()!;
	const client = new BusinessPartnerClient();
	return client.update(tenantId, partner);
}

// export const deleteBusinessPartnerAction = (id: number ) => {
// 	const tenantId = getTenantId();
// 	const client = new BusinessPartnerClient();
// 	return client.delete(tenantId, id);
// }

export const exportAction = (exportDataModel: ExportDataModel) => {
	const tenantId = getTenantId()!;
	const client = new BusinessPartnerClient();
	return client.export(tenantId, exportDataModel);
}

export const persistBusinessPartnersAction = async () => {
	const state = store.getState();

	if (state.persistedBusinessPartner.fetching) {
		return;
	}

	store.dispatch(setBusinessPartnersFetchingAction(true));

	const response = await tryCatchJsonByAction(getBusinessPartnersAction);
	if (response.success) {
		store.dispatch(setBusinessPartnersAction(response.items || []));
	} else {
		store.dispatch(setBusinessPartnersFetchingAction(false));
	}
}

// redux

export const BUSINESS_PARTNERS_FETCHING = 'BUSINESS_PARTNERS_FETCHING';
export const BUSINESS_PARTNERS_SET = 'BUSINESS_PARTNERS_SET';

const setBusinessPartnersFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: BUSINESS_PARTNERS_FETCHING,
		fetching
	}
}

const setBusinessPartnersAction = (items: BusinessPartnerResponseModel[]): PersistItemsActionType<BusinessPartnerResponseModel> =>  {
	return {
		type: BUSINESS_PARTNERS_SET,
		items
	}
}

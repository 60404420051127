import { RowContainer } from 'components/Layout';
import { useCallback, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import Column, { ColumnKeyValuePairType, ColumnType } from './Column';
import { ElementKeyValuePairType } from './Element';

export type MultiListType = {
	elements: ElementKeyValuePairType;
	columns: ColumnKeyValuePairType;
	columnOrder: Array<string>;
};

type Props = {
	onDragEnd: (multiList: MultiListType) => void;
	initialMultiList: MultiListType;
};

const MultiList = ({ onDragEnd, initialMultiList }: Props) => {
	const [currentData, setCurrentData] = useState<MultiListType>(initialMultiList);

	const handleDragEnd = useCallback(
		(result: DropResult) => {
			const { destination, source, draggableId } = result;

			if (!destination) {
				return;
			}

			if (
				destination.droppableId === source.droppableId &&
				destination.index === source.index
			) {
				return;
			}

			const startColumn = currentData.columns[source.droppableId];
			const finishColumn = currentData.columns[destination.droppableId];
			if (startColumn === finishColumn) {
				const newElementsIds = [...startColumn.elementIds];
				newElementsIds.splice(source.index, 1);
				newElementsIds.splice(destination.index, 0, draggableId);

				const newColumn = new ColumnType(startColumn);
				newColumn.elementIds = newElementsIds;

				const newCurrentData = {
					...currentData,
					columns: { ...currentData.columns, [newColumn.id]: newColumn },
				};

				setCurrentData(newCurrentData);
				onDragEnd(newCurrentData);
				return;
			}

			const startElementIds = [...startColumn.elementIds];
			startElementIds.splice(source.index, 1);

			const newStartColumn = new ColumnType(startColumn);
			newStartColumn.elementIds = [...startElementIds];

			const finishElementIds = [...finishColumn.elementIds];
			finishElementIds.splice(destination.index, 0, draggableId);

			const newFinishColumn = new ColumnType(finishColumn);
			newFinishColumn.elementIds = [...finishElementIds];

			const newCurrentData = {
				...currentData,
				columns: {
					...currentData.columns,
					[newStartColumn.id]: newStartColumn,
					[newFinishColumn.id]: newFinishColumn,
				},
			};

			setCurrentData(newCurrentData);
			onDragEnd(newCurrentData);
		},
		[currentData, onDragEnd]
	);

	return (
		<DragDropContext onDragEnd={handleDragEnd}>
			<RowContainer flex={1} margin='large'>
				{currentData.columnOrder.map((columnId) => {
					const column = currentData.columns[columnId];
					const elements = column.elementIds.map(
						(elementId) => currentData.elements[elementId]
					);
					return <Column key={column.id} column={column} elements={elements} />;
				})}
			</RowContainer>
		</DragDropContext>
	);
};

export default MultiList;

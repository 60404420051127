import { ActionType } from 'features/Persist/actionTypes';
import { Route } from './reducer';

export type RoutesActionType = ActionType & {
	routes: Route[];
};

export const ROUTES_SET = 'ROUTES_SET';

export const setCurrentRoutesAction = (routes: Route[]) => {
	return {
		type: ROUTES_SET,
		routes
	}
}

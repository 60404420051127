import React, { memo } from 'react';
import styles from '../colorPicker.module.scss';
import { PropTypes } from 'prop-types';
import DefaultColor from './DefaultColor';

const DefaultColors = ({ colors, onChange }) => {
	return (
		<div className={styles.color_picker_default_colors_inner}>
			{colors.map(
				(color, index) => <DefaultColor key={index} color={color} onClick={onChange} />
			)}
		</div>
	)
}

DefaultColors.propTypes = {
	colors: PropTypes.array,
	onChange: PropTypes.func
}

DefaultColors.defaultProps = {
	colors: ['#000000', '#fbb900', '#7bdcb4', '#00d184', '#8dd2fc', '#ea144c', '#f78da8', '#9a28ee', '#0993e3', '#aab7c2']
}

export default memo(DefaultColors);

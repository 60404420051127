import { SimpleStatusRequestUpdateSimpleStatusRequestItems, UserStatusClient, UserStatusResponse  } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

export const getUserStatusesAction = () => {
	const tenantId = getTenantId()!;
	const statusClient = new UserStatusClient();
	return statusClient.getAll(tenantId);
}

export const updateUserStatusesAction = (userStatusesDelta: SimpleStatusRequestUpdateSimpleStatusRequestItems ) => {
	const tenantId = getTenantId()!;
	const statusClient = new UserStatusClient();
	return statusClient.update(tenantId, userStatusesDelta);
}

export const persistUserStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedUserStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getUserStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const USER_STATUS_FETCHING = 'USER_STATUS_FETCHING';
export const USER_STATUS_SET = 'USER_STATUS_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: USER_STATUS_FETCHING,
		fetching
	}
}

const setAction = (items: UserStatusResponse[]): PersistItemsActionType<UserStatusResponse> =>  {
	return {
		type: USER_STATUS_SET,
		items
	}
}

import store from 'base/reducer/store';
import { FetchingActionType, FetchingProjectItemsActionType, PersistItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { ProjectConfigDeltaRequest, ProjectRoleClient, ProjectIsActiveResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getProjectRolesAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRoleClient();
	return client.getAllByProjectId(tenantId, projectId);
}

export const getAllProjectRolesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectRoleClient();
	return client.getAll(tenantId);
}

export const updateProjectRolesAction = (projectId: number, model: ProjectConfigDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ProjectRoleClient();
	return client.update(tenantId, projectId, model);
}

export const persistProjectRolesAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedProjectRole.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setProjectRolesFetchingAction(true, projectId));

	const bindedAction = getProjectRolesAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success && response.items) {
		store.dispatch(setProjectRolesAction(projectId, response.items || []));
	} else {
		store.dispatch(setProjectRolesFetchingAction(false, projectId));
	}
}

export const persistAllProjectRolesAction = async () => {
	const state = store.getState();

	if (state.persistedProjectRole.fetching) {
		return;
	}

	store.dispatch(setProjectRolesFetchingAllAction(true));

	const response = await tryCatchJsonByAction(getAllProjectRolesAction);

	if (response.success && response.items) {
		store.dispatch(setAllProjectRolesAction(response.items || []));
	} else {
		store.dispatch(setProjectRolesFetchingAllAction(false));
	}
}

// persist project roles redux

export const PROJECT_ROLES_FETCHING_ALL = 'PROJECT_ROLES_FETCHING_ALL';
export const PROJECT_ROLES_FETCHING = 'PROJECT_ROLES_FETCHING';
export const PROJECT_ROLES_SET = 'PROJECT_ROLES_SET';
export const PROJECT_ROLES_ALL_SET = 'PROJECT_ROLES_ALL_SET';

const setProjectRolesFetchingAllAction = (fetching: boolean): FetchingActionType => {
	return {
		type: PROJECT_ROLES_FETCHING_ALL,
		fetching
	}
}

const setProjectRolesFetchingAction = (fetching: boolean, projectId: number): FetchingProjectItemsActionType => {
	return {
		type: PROJECT_ROLES_FETCHING,
		fetching,
		projectId
	}
}

const setProjectRolesAction = (projectId: number, items: ProjectIsActiveResponse[]): PersistProjectItemsActionType<ProjectIsActiveResponse> => {
	return {
		type: PROJECT_ROLES_SET,
		items,
		projectId
	}
}

const setAllProjectRolesAction = (items: ProjectIsActiveResponse[]): PersistItemsActionType<ProjectIsActiveResponse> => {
	return {
		type: PROJECT_ROLES_ALL_SET,
		items,
	}
}

import { TrainingStatusResponse } from 'services/tenantManagementService';
import { TRAINING_STATUSES_FETCHING, TRAINING_STATUSES_SET } from './action';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { PersistItemsReducer } from 'features/Persist/reducerTypes';

export const trainingStatusReducer = (state = new PersistItemsReducer<TrainingStatusResponse>(), action: PersistItemsActionType<TrainingStatusResponse> | FetchingActionType): PersistItemsReducer<TrainingStatusResponse> => {
	switch (action.type) {
		case TRAINING_STATUSES_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case TRAINING_STATUSES_SET:
			const { items } = action as PersistItemsActionType<TrainingStatusResponse>;

			const itemsMap: { [id: number]: TrainingStatusResponse } = items.reduce(
                (map: { [id: number]: TrainingStatusResponse }, item) => {
					map[item.id] = item;
					return map;
				},
                {}
			);

			return {
				items,
				itemsMap,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

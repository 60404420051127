import { CostExpenseTypeDeltaRequest, CostExpenseTypeResponse, TimeTravelConfigClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import store from 'base/reducer/store';

export const getExpenseTypesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.getExpenseTypes(tenantId);
}

export const updateExpenseTypesAction = (expenseTypesDelta: CostExpenseTypeDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.updateExpenseTypes(tenantId, expenseTypesDelta);
}

export const persistExpenseTypesAction = async () => {
	const state = store.getState();

	if (state.persistedTimeTravelExpenseTypes.fetching) {
		return;
	}

	store.dispatch(setExpenseTypesFetchingAction(true));

	const response = await tryCatchJsonByAction(getExpenseTypesAction);
	if (response.success) {
		store.dispatch(setExpenseTypesActionAction(response.items || []));
	} else {
		store.dispatch(setExpenseTypesFetchingAction(false));
	}
}

// redux

export const TIME_TRAVEL_EXPENSE_TYPES_FETCHING = 'TIME_TRAVEL_EXPENSE_TYPES_FETCHING';
export const TIME_TRAVEL_EXPENSE_TYPES_SET = 'TIME_TRAVEL_EXPENSE_TYPES_SET';

const setExpenseTypesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TIME_TRAVEL_EXPENSE_TYPES_FETCHING,
		fetching
	}
}

const setExpenseTypesActionAction = (items: CostExpenseTypeResponse[]): PersistItemsActionType<CostExpenseTypeResponse> =>  {
	return {
		type: TIME_TRAVEL_EXPENSE_TYPES_SET,
		items
	}
}

import { CostExpenseTypeDeltaRequest, CostExpenseTypeResponse, TimeTravelConfigClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import store from 'base/reducer/store';

export const getTravelCostTypesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.getTravelCostTypes(tenantId);
}

export const updateTravelCostTypesAction = (travelCostTypesDelta: CostExpenseTypeDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.updateTravelCostTypes(tenantId, travelCostTypesDelta);
}

export const persistTravelCostTypesAction = async () => {
	const state = store.getState();

	if (state.persistedTravelCostTypes.fetching) {
		return;
	}

	store.dispatch(setTravelCostTypesFetchingAction(true));

	const response = await tryCatchJsonByAction(getTravelCostTypesAction);
	if (response.success) {
		store.dispatch(setTravelCostTypesActionAction(response.items || []));
	} else {
		store.dispatch(setTravelCostTypesFetchingAction(false));
	}
}

// redux

export const TRAVEL_COST_TYPES_FETCHING = 'TRAVEL_COST_TYPES_FETCHING';
export const TRAVEL_COST_TYPES_SET = 'TRAVEL_COST_TYPES_SET';

const setTravelCostTypesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TRAVEL_COST_TYPES_FETCHING,
		fetching
	}
}

const setTravelCostTypesActionAction = (items: CostExpenseTypeResponse[]): PersistItemsActionType<CostExpenseTypeResponse> =>  {
	return {
		type: TRAVEL_COST_TYPES_SET,
		items
	}
}

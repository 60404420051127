import { CountryClient, IdNameResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getCountriesAction = () => {
	const client = new CountryClient();
	return client.getCountries();
}

export const persistCountriesAction = async () => {
	const state = store.getState();

	if (state.persistedCountry.fetching) {
		return;
	}

	store.dispatch(setCountriesFetchingAction(true));

	const response = await tryCatchJsonByAction(getCountriesAction);
	if (response.success) {
		store.dispatch(setCountriesAction(response.items || []));
	} else {
		store.dispatch(setCountriesFetchingAction(false));
	}
}

// redux

export const COUNTRIES_FETCHING = 'COUNTRIES_FETCHING';
export const COUNTRIES_SET = 'COUNTRIES_SET';

const setCountriesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: COUNTRIES_FETCHING,
		fetching
	}
}

const setCountriesAction = (items: IdNameResponse[]): PersistItemsActionType<IdNameResponse> =>  {
	return {
		type: COUNTRIES_SET,
		items
	}
}

import { useCallback, useEffect, useRef } from 'react';
import { MaxLength } from '../../addons';
import styles from './input.module.scss';
import { ControlsCommonProps } from '../../fields';
import { InfoPopup, POSITION } from 'components/Popup';

export type InputProps = ControlsCommonProps<string> & {
	type?: string
	placeholder?: string
	maxLength?: number
	hideMaxLength?: boolean
	focus?: boolean
	onFocus?(): void
	selectAllTextOnFocus?: boolean
	explanation?: string
	onKeyDown?(eventKey: string): void
}

export const Input = ({
	value,
	onChange,
	disabled,
	onBlur,
	type = 'text',
	explanation,
	placeholder,
	maxLength = 40,
	hideMaxLength,
	focus,
	onFocus,
	selectAllTextOnFocus,
	onKeyDown
}: InputProps) => {

	const inputRef = useRef<HTMLInputElement>(null);

	const onChangeCallback = useCallback(
        (e) => onChange && onChange(e.target.value),
        [onChange]
	)

	const onFocusCallback = useCallback(
		() => {
			if (selectAllTextOnFocus) {
				inputRef.current?.select();
			}
			onFocus && onFocus();
		},
		[selectAllTextOnFocus, onFocus]
	)

	// hack: ako je setovan fokus, fokusiraj element
	useEffect(
		() => {
			focus && setTimeout(() => inputRef.current?.focus(), 1)
		},
		[focus]
	)

	const onKeyDownCallback = useCallback(
		(e) => onKeyDown && onKeyDown(e.key),
		[onKeyDown]
	)

	return (
		<div className={styles.wrapper}>
			<div className={styles.container}>
				<input
					className={styles.input}
					ref={inputRef}
					type={type}
					value={value || ''}
					onChange={onChangeCallback}
					onFocus={onFocusCallback}
					onBlur={onBlur}
					onKeyDown={onKeyDownCallback}
					placeholder={placeholder}
					autoFocus={focus}
					disabled={disabled}
					maxLength={hideMaxLength ? undefined : maxLength}
				/>
				{/* max length */}
				{!hideMaxLength && maxLength &&
					<div className={styles.max_length}>
						<MaxLength
							length={value ? value.length : 0}
							maxLength={maxLength}
						/>
					</div>
				}
			</div>
			{/* explanation */}
			{explanation &&
				<div className={styles.explanation}>
					<InfoPopup
						message={explanation}
						position={POSITION.TOP_CENTER}
					/>
				</div>
			}
		</div>
	)
}

import { ProjectStatusClient, ProjectStatusResponse, SimpleStatusRequestUpdateSimpleStatusRequestItems } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getProjectStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ProjectStatusClient();
	return client.getAll(tenantId);
}

export const updateProjectStatusesAction = (projectStatusesDelta: SimpleStatusRequestUpdateSimpleStatusRequestItems) => {
	const tenantId = getTenantId()!;
	const client = new ProjectStatusClient();
	return client.update(tenantId, projectStatusesDelta);
}

export const persistProjectStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedProjectStatus.fetching) {
		return;
	}

	store.dispatch(setProjectStatusesFetchingAction(true));

	const response = await tryCatchJsonByAction(getProjectStatusesAction);
	if (response.success) {
		store.dispatch(setProjectStatusesAction(response.items || []));
	} else {
		store.dispatch(setProjectStatusesFetchingAction(false));
	}
}

// redux

export const PROJECT_STATUSES_SET = 'PROJECT_STATUSES_SET';
export const PROJECT_STATUSES_FETCHING = 'PROJECT_STATUSES_FETCHING';

const setProjectStatusesFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: PROJECT_STATUSES_FETCHING,
		fetching
	}
}

const setProjectStatusesAction = (items: ProjectStatusResponse[]): PersistItemsActionType<ProjectStatusResponse> =>  {
	return {
		type: PROJECT_STATUSES_SET,
		items
	}
}

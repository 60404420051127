import React from 'react';
import styles from '../colorPicker.module.scss';

const Preview = ({ color }) => {
	return (
		<div
			className={styles.color_picker_preview}
			style={{
				backgroundColor: color
			}}
		/>
	)
}

export default Preview;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './colorPickerPanel.module.scss';
import { hexToHsl, hslToHex } from 'utils/colorHelper';

export default class Hue extends Component {

	constructor(props) {
		super(props);

		this.container = React.createRef();

		this.onMouseUp = this.onMouseUp.bind(this);
		this.onMouseDown = this.onMouseDown.bind(this);
		this.onTouchStart = this.onTouchStart.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onChangeComplete = this.onChangeComplete.bind(this);
	}

	calculateChange(e, container) {
		const containerWidth = container.clientWidth;
		const x = e.pageX || (e.touches[0] && e.touches[0].pageX) || (e.changedTouches[0] && e.changedTouches[0].pageX);
		const left = x - (container.getBoundingClientRect().left + window.pageXOffset);

		let h;
		if (left <= 0) {
			h = 0
		} else if (left >= containerWidth) {
			h = 359
		} else {
			const percent = (left * 100) / containerWidth
			h = ((360 * percent) / 100)
		}

		return {
			h,
			s: 100,
			l: 50,
		}
	}

	onChange(e) {
		const hsl = this.calculateChange(e, this.container.current);
		const hex = hslToHex(hsl);
		this.props.onChange(hex);
	}

	onChangeComplete(e) {
		const hsl = this.calculateChange(e, this.container.current);
		const hex = hslToHex(hsl);
		this.props.onChangeComplete(hex);
	}

	onMouseDown() {
		window.addEventListener('mousemove', this.onChange);
		window.addEventListener('mouseup', this.onMouseUp);
	}

	onMouseUp(e) {
		this.unbindEventListeners();
		this.onChangeComplete(e);
	}

	onTouchStart(e) {
		e.preventDefault();
		this.onChange(e);
	}

	unbindEventListeners() {
		window.removeEventListener('mousemove', this.onChange);
		window.removeEventListener('mouseup', this.onMouseUp);
	}

	render() {
		const hslColor = hexToHsl(this.props.color);
		const left = hslColor === undefined ? 0 : hslColor.h * 100 / 360 + '%';

		return (
			<div className={styles.color_picker_hue}
				style={{
					width: this.props.width,
					height: this.props.height + 'px'
				}}
			>
				<div
					className={styles.color_picker_hue_track}
					ref={this.container}
					onMouseDown={this.onMouseDown}
					onTouchStart={this.onTouchStart}
					onTouchMove={this.onChange}
					onTouchEnd={this.onChangeComplete}
				>
					<div className={styles.color_picker_hue_thumb}
						style={{
							left,
							width: this.props.height + 2 + 'px',
							height: this.props.height + 2 + 'px',
							transform: 'translate(-' + this.props.height / 2 + 'px, -1px)'
						}}
					></div>
				</div>
			</div>
		);
	}

}

Hue.propTypes = {
	width: PropTypes.string,
	height: PropTypes.number,
	color: PropTypes.string,
	onChange: PropTypes.func,
	onChangeComplete: PropTypes.func
}

Hue.defaultProps = {
	width: '100%',
	height: 22
}

import { TicketConfigClient, ImpactResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getTicketImpactsAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.getImpacts(tenantId);
}

export const persistTicketImpactsAction = async () => {
	const state = store.getState();

	if (state.persistedTicketImpact.fetching) {
		return;
	}

	store.dispatch(setTicketImpactsFetchingAction(true));

	const response = await tryCatchJsonByAction(getTicketImpactsAction);
	if (response.success) {
		store.dispatch(setTicketImpactsAction(response.items || []));
	} else {
		store.dispatch(setTicketImpactsFetchingAction(false));
	}
}

// redux

export const TICKET_IMPACT_FETCHING = 'TICKET_IMPACT_FETCHING';
export const TICKET_IMPACT_SET = 'TICKET_IMPACT_SET';

const setTicketImpactsFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_IMPACT_FETCHING,
		fetching
	}
}

const setTicketImpactsAction = (items: ImpactResponse[]): PersistItemsActionType<ImpactResponse> =>  {
	return {
		type: TICKET_IMPACT_SET,
		items
	}
}

import { useCallback } from 'react'
import styles from './autoComplete.module.scss'

type Props = {
	item: any
	getItemText(item: any): string | undefined
	onClick(item: any): void
	isSelected?: boolean
}

export const Option = ({ item, getItemText, onClick, isSelected }: Props) => {
	const onClickCallback = useCallback(
		() => {
			onClick(item);
		},
		[item, onClick]
	)

	const className = `${styles.option} ${isSelected ? styles.selected : ''}`

	return (
		<div className={className} onMouseDown={onClickCallback}>
			{getItemText(item)}
		</div>
	)
}

import { useCallback, useMemo } from "react"
import { SmartFormGroup } from "components/SmartContainer/SmartContainer"
import { MultiSelect } from 'components/Form';
import { IProjectOrCategory, UseProjectsAndCategoriesOptions, useProjectsAndCategories } from "../projectHooks"
import { ProjectOrCategoryType } from "../projectHooks"

type Props = UseProjectsAndCategoriesOptions & {
	value?: ProjectOrCategoryType[]
	onChange?: (projectOrCategory: ProjectOrCategoryType[] | undefined) => void
	disabled?: boolean
}

export const ProjectOrCategoryMultiSelect = ({
	value,
	onChange,
	disabled,
	...options
}: Props) => {
	const { projectsOrCategories, loadingProjectsAndCategories } = useProjectsAndCategories(options)

	const ids = useMemo(
		() => {
			if (!value) {
				return;
			}

			let selectedIds: string[] = [];

			for (const { projectOrCategoryId, isProjectConnected } of value) {
				const projectOrCategory = projectsOrCategories.find((item) => item.projectOrCategoryId === projectOrCategoryId && item.isProjectConnected === isProjectConnected);

				if (projectOrCategory) {
					selectedIds.push(projectOrCategory.id);
				}
			}

			return selectedIds;
		},
		[projectsOrCategories, value]
	);

	const onChangeCallback = useCallback(
		(value: string[]) => {
			const projectOrCategory = projectsOrCategories.filter((item) => value.includes(item.id));

			if (!projectOrCategory) {
				onChange && onChange(undefined);
				return;
			}

			onChange && onChange(projectOrCategory);
		},
		[onChange, projectsOrCategories]
	)

	return (
		<SmartFormGroup label='Project or category'>
			<MultiSelect
				value={ids}
				onChange={onChangeCallback}
				items={projectsOrCategories}
				getItemId={(item: IProjectOrCategory) => item.id}
				getItemText={(item: IProjectOrCategory) => item.label}
				loading={loadingProjectsAndCategories}
				disabled={disabled}
			/>
		</SmartFormGroup>
	)
}

import { useCallback } from 'react';
import { BaseColumnModel } from '../../BaseColumnModel';
import FilterItem from './FilterItem';
import styles from './filterItem.module.scss'
import { FilterModel } from '../Filter';

export const useGetOrFiltersCallback = (columns: BaseColumnModel[]) => {
	return useCallback(
		(filters: FilterModel[]) => {
			const content: React.ReactNode[] = [];
			for (let i = 0; i < filters.length; i++) {
				const filter = filters[i];

				content.push(<FilterItem key={i} filter={filter} columns={columns} />);
				if (i < filters.length - 1) {
					content.push(<span className={styles.conjunction}>OR</span>);
				}
			}

			content.unshift(<span>(</span>);
			content.push(<span>)</span>);
			return content;
		},
		[columns]
	)
}

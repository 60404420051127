import ConfigureView from './ConfigureView';
import { configureViewReducer, ConfigureViewType as ReducerType } from './reducer';
import { setConfigureViewAction, setConfigureViewTableAction, ConfigureViewActionType as ActionType, getConfigureViewAction } from './action';

export {
	configureViewReducer,
	setConfigureViewAction,
	setConfigureViewTableAction,
	getConfigureViewAction
};

export type ConfigureViewActionType = ActionType;
export type ConfigureViewType = ReducerType

export default ConfigureView;

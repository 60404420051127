import { useEffect, useRef } from 'react';
import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { BaseColumnModel } from '../BaseTable';
import { LocalTable } from '../Local/LocalTable';
import { handleExpandRows } from './helpers';

export type NestedTableProps = {
	columns: BaseColumnModel[]
	data: any[]
	level: number | undefined
	isLoading?: boolean
	showBranchIcon?: boolean
	dataTreeChildField?: 'children' | '_children'
	limit?: number
	hasPagination?: boolean
	options?: any
	compact?: boolean
	withoutPaddingBottom?: boolean
	childIndent?: number
}

export const NestedTable = ({ columns, data, level, isLoading, showBranchIcon = false, dataTreeChildField = '_children', limit, hasPagination, options, compact, withoutPaddingBottom, childIndent = 12 }: NestedTableProps) => {
	const tabulatorRef = useRef<Tabulator>(null);

	useEffect(
		() => {
			const rows = tabulatorRef.current?.getRows();
			if (rows && level !== undefined) {
				handleExpandRows(rows, 0, level);
			}
		},
		[level]
	)

	return (
		<LocalTable
			ref={tabulatorRef}
			data={data}
			columns={columns}
			isLoading={!!isLoading}
			limit={limit}
			hasPagination={hasPagination}
			compact={compact}
			withoutPaddingBottom={withoutPaddingBottom}
			options={{
				dataTree: true,
				dataTreeExpandElement: "<i class='table_icons_plus'></i>",
				dataTreeCollapseElement: "<i class='table_icons_minus'></i>",
				dataTreeBranchElement: showBranchIcon,
				dataTreeChildField: dataTreeChildField,
				dataTreeChildIndent: childIndent,
				...options
			}}
		/>
	)
}

import { getTenantId } from "features/Tenant/tenantUtils";
import { UserTableViewConfigClient, InsertUpdateUserTableViewConfigRequest } from "services/tenantManagementService";

export const getUserTableViewConfigAction = (viewType: string) => {
	const tenantId = getTenantId()!;
	const client = new UserTableViewConfigClient();
	return client.get(tenantId, viewType)
}

export const updateUserTableViewConfigAction = (viewType: string, content: any) => {
	const data = new InsertUpdateUserTableViewConfigRequest();
	data.viewType = viewType;
	data.content = content;

	const tenantId = getTenantId()!;
	const client = new UserTableViewConfigClient();
	return client.create(tenantId, data)
}

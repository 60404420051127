import { TenantIsActiveDeltaRequest  , TenantIsActiveResponse, TicketConfigClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getTicketEnvironmentsAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.getTicketEnvironments(tenantId);
}

export const updateTicketEnvironmentsAction = (ticketEnvironmentsDelta: TenantIsActiveDeltaRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.updateTicketEnvironments(tenantId, ticketEnvironmentsDelta);
}

export const persistTicketEnvironmentsAction = async () => {
	const state = store.getState();

	if (state.persistedTicketEnvironment.fetching) {
		return;
	}

	store.dispatch(setTicketEnvironmentsFetchingAction(true));

	const response = await tryCatchJsonByAction(getTicketEnvironmentsAction);
	if (response.success) {
		store.dispatch(setTicketEnvironmentsAction(response.items || []));
	} else {
		store.dispatch(setTicketEnvironmentsFetchingAction(false));
	}
}

// redux

export const TICKET_ENVIRONMENTS_FETCHING = 'TICKET_ENVIRONMENTS_FETCHING';
export const TICKET_ENVIRONMENTS_SET = 'TICKET_ENVIRONMENTS_SET';

const setTicketEnvironmentsFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_ENVIRONMENTS_FETCHING,
		fetching
	}
}

const setTicketEnvironmentsAction = (items: TenantIsActiveResponse[]): PersistItemsActionType<TenantIsActiveResponse> =>  {
	return {
		type: TICKET_ENVIRONMENTS_SET,
		items
	}
}

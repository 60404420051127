import React, { useCallback, useMemo, useState } from 'react';
import { ExportDataModel, GenericFilterModelCollection, ProjectResponse } from 'services/tenantManagementService';
import { getProjectsGenericAction, exportAction } from '../action';
import { projectsChangedTopic } from './projectsChanged';
import { useTableColumnsMemo } from './tableColumns';
import { ColumnContainer } from 'components/Layout';
import { BaseColumnModel, RemoteTable } from 'components/Table';
import { setConfigureViewTableAction } from 'features/ConfigureView';

type Props = {
	configureViewKey?: string
	onSelectedProjectChange?: (data: ProjectResponse[]) => void
	renderTableButtons(
		tableColumns: BaseColumnModel[],
		filtersModel: GenericFilterModelCollection,
		memoExportFunction : (exportDataModel: ExportDataModel) => any
	): React.ReactElement
	defaultProjectId?: number
}

export const ProjectsTable = ({ configureViewKey, onSelectedProjectChange, renderTableButtons, defaultProjectId }: Props) => {
	const [filtersModel, setFiltersModel] = useState(new GenericFilterModelCollection());

	const tableColumns = useTableColumnsMemo(configureViewKey, defaultProjectId);

	const memoFetchFunction = useMemo(
		() => getProjectsGenericAction,
		[]
	)

	const memoExportFunction = exportAction;

	const tableButtonsContent = useMemo(
		() => renderTableButtons(tableColumns, filtersModel, memoExportFunction),
		[renderTableButtons, tableColumns, filtersModel, memoExportFunction]
	)

	const reorderColumnsCallback = useCallback(
		(newColumns: string[]) => {
			if (configureViewKey) {
				return setConfigureViewTableAction(configureViewKey, newColumns)
			}
		},
		[configureViewKey]
	)

	return (
		<ColumnContainer margin='medium'>
			{tableButtonsContent}
			<RemoteTable
				columns={tableColumns}
				filtersModel={filtersModel}
				filtersModelChanged={setFiltersModel}
				subscriptionTopic={projectsChangedTopic}
				fetchFunction={memoFetchFunction}
				rowSelectionChanged={onSelectedProjectChange}
				reorderColumns={reorderColumnsCallback}
			/>
		</ColumnContainer>
	)
}

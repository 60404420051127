import React from 'react';
import styles from '../colorPicker.module.scss';

const DefaultColor = ({ color, onClick }) => {
	const onClickCallback = () => onClick(color);

	return (
		<div
			onClick={onClickCallback}
			className={styles.color_picker_default_colors_color}
			style={{
				backgroundColor: color
			}}
		/>
	)
}

export default DefaultColor;

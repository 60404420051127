import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { SimpleStatusRequestUpdateSimpleStatusRequestItems, ReportStatusResponse, ReportStatusClient } from 'services/tenantManagementService';

export const getReportStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new ReportStatusClient();
	return client.getAll(tenantId);
}

export const updateReportStatusesAction = (delta: SimpleStatusRequestUpdateSimpleStatusRequestItems) => {
	const tenantId = getTenantId()!;
	const client = new ReportStatusClient();
	return client.update(tenantId, delta);
}

export const persistReportStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedReportStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getReportStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const REPORT_STATUS_FETCHING = 'REPORT_STATUS_FETCHING';
export const REPORT_STATUS_SET = 'REPORT_STATUS_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: REPORT_STATUS_FETCHING,
		fetching
	}
}

const setAction = (items: ReportStatusResponse[]): PersistItemsActionType<ReportStatusResponse> =>  {
	return {
		type: REPORT_STATUS_SET,
		items
	}
}

import { useCallback, useMemo, useState } from 'react'
import styles from './headerCell.module.scss'
import { FilterIcon } from 'components/icons/icons'

const sortElementId = 'header_cell_sort'

type Props = {
	fieldId: string
	text: string
	hasFilter: boolean
	isFilterActive: boolean
	onFilterClick(fieldId: string): void
	hasSort: boolean
	isAsc: boolean
	isDesc: boolean
	onSortClick(fieldId: string): void
}

export const HeaderCell = ({ fieldId, text, hasFilter, isFilterActive, onFilterClick, hasSort, isAsc, isDesc, onSortClick }: Props) => {
	const [hover, setHover] = useState(false);

	const arrowClassName = useMemo(
		() => {
			if (isAsc) {
				return styles.asc_arrow;
			}
			if (isDesc) {
				return styles.desc_arrow;
			}
			return styles.arrow;
		},
		[isAsc, isDesc]
	)

	const mouseOverCallback = useCallback(
		(e: any) => {
			if (e.target.id !== sortElementId && hasFilter) {
				setHover(true)
			}
		},
		[hasFilter]
	)

	const mouseOutCallback = useCallback(
		() => setHover(false),
		[]
	)

	const onSortClickCallback = useCallback(
		(e) => {
			e.stopPropagation();
			onSortClick(fieldId);
		},
		[fieldId, onSortClick]
	)

	const onFilterClickCallback = useCallback(
		() => {
			hasFilter && onFilterClick(fieldId);
		},
		[fieldId, hasFilter, onFilterClick]
	)

	return (
		<div
			className={`${styles.container} ${hover ? styles.hover : ''}`}
			onMouseOver={mouseOverCallback}
			onMouseOut={mouseOutCallback}
			onClick={onFilterClickCallback}
		>
			{isFilterActive &&
				<div className={styles.filter_wrapper}>
					<FilterIcon className={styles.filter} width={16} height={16} stroke='currentColor' />
				</div>
			}
			<span className={styles.text} title={text}>{text}</span>
			{hasSort &&
				<div id={sortElementId} className={styles.sort} onClick={onSortClickCallback}>
					<div className={arrowClassName}></div>
				</div>
			}
		</div>
	)
}

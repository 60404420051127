import { memo } from 'react';
import { BooleanFilter } from './BooleanFilter/BooleanFilter';
import { DateFilter } from './DateFilter/DateFilter';
import { NumberFilter } from './NumberFilter/NumberFilter';
import { OptionsFilter } from './OptionsFilter/OptionsFilter';
import { StringFilter } from './StringFilter/StringFilter';
import { FilterModel } from '../Filter';
import { BaseColumnModel, FieldTypeEnum } from '../../BaseColumnModel';
import { SemaphoreFilter } from './SemaphoreFilter/SemaphoreFilter';
import { MapFilter } from './MapFilter/MapFilter';
import { OptionFilter } from './OptionFilter/OptionFilter';

type Props = {
	type?: FieldTypeEnum
	column: BaseColumnModel
	filters: FilterModel[]
	onSave: (filters: FilterModel[]) => void
	onCancel: () => void
}

const FilterElement = ({type, ...rest}: Props) => {
	switch (type) {
		case FieldTypeEnum.FormattedReference:
			return <StringFilter {...rest} formattedReference />
		case FieldTypeEnum.Option:
			return <OptionFilter {...rest} />
		case FieldTypeEnum.Options:
			return <OptionsFilter {...rest} />
		case FieldTypeEnum.Enums:
			return <OptionsFilter {...rest} />
		case FieldTypeEnum.Semaphore:
			return <SemaphoreFilter {...rest} />
		case FieldTypeEnum.MapOption:
			return <MapFilter {...rest} />
		case FieldTypeEnum.Boolean:
			return <BooleanFilter {...rest} />
		case FieldTypeEnum.Date:
			return <DateFilter {...rest} />
		case FieldTypeEnum.Number:
		case FieldTypeEnum.Currency:
			return <NumberFilter {...rest} />
		case FieldTypeEnum.String:
		default:
			return <StringFilter {...rest} />
	}
}

export default memo(FilterElement)

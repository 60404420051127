import { GenericFilterOperationEnum } from "services/tenantManagementService";
import { AndOrEnum } from "../../filtersHelper";

export class NumberFilterModel {
	filterType1?: GenericFilterOperationEnum
	filterValue1?: Date;
	filterType2?: GenericFilterOperationEnum;
	filterValue2?: Date;
	additionalFilter: AndOrEnum = AndOrEnum.And;

	constructor(filterModel?: NumberFilterModel) {
		if (filterModel) {
			this.filterType1 = filterModel.filterType1;
			this.filterValue1 = filterModel.filterValue1;
			this.filterType2 = filterModel.filterType2;
			this.filterValue2 = filterModel.filterValue2;
			this.additionalFilter = filterModel.additionalFilter;

		}
	}
}

import { ArrowDownIcon } from 'components/icons/icons';
import { useCallback, useMemo, useState } from 'react';
import { ControlsCommonProps } from '../../fields';
import styles from './multiSelect.module.scss';
import Spinner, { ClipSpinner } from 'components/Spinner';
import { Option } from './Option'
import { Input } from "../Input/Input"
import { Cover } from 'components/Cover';
import { Link } from 'react-router-dom';

const selectAllKey = 'select_all'

const multiselectId = 'multiselectId'

// Multiselect (like Textarea) accepts Enter
// so this method is used in Form to prevent auto save of the form via Enter key
export const isMultiSelectHit = (target: HTMLElement) => {
	return !!target.closest(`#${multiselectId}`);
}

export type MultiSelectProps = ControlsCommonProps<Array<string | number>> & {
	items?: any[]
	getItemId(item: any): string | number
	getItemText(item: any): string
	loading?: boolean
	sort?: boolean
	hasSelectAll?: boolean
	numOfItemNames?: number
	isLink?: boolean
	getItemLinkRoute?(item: any): string
};

export const MultiSelect = (props: MultiSelectProps) => {
	const {
		value, onChange, onBlur, disabled,
		items = [], getItemId, getItemText, getItemLinkRoute,
		loading, sort, isLink, hasSelectAll = true, numOfItemNames = 1
	} = props;

	const [expanded, setExpanded] = useState(false);
	const [searchValue, setSearchValue] = useState<string>();
	const [keyboardItem, setKeyboardItem] = useState<any | undefined>();

	const filteredItemsMemo = useMemo(
		() => {
			// filter by searchValue
			let newFilteredItems = items.filter((item) => {
				const text = getItemText(item);
				return text?.toLowerCase().includes(searchValue?.toLowerCase() || '');
			})
			// sort by alphabet
			if (sort) {
				newFilteredItems = newFilteredItems.sort((a, b) => {
					const textA = getItemText(a)?.toUpperCase() || '';
					const textB = getItemText(b)?.toUpperCase() || ''
					if (textA < textB){
						return -1;
					}
					if (textA > textB){
						return 1;
					}
					return 0;
				})
			}
			return newFilteredItems;
		},
		[items, searchValue, getItemText, sort]
	)

	const onChangeCallback = useCallback(
		(item: any | undefined) => {
			let id = getItemId(item)
			const values  = value || []
			let newValues;

			if (values.includes(id)) {
				newValues = values.filter(x => x !== id);
			} else {
				newValues = [...values, id]
			}

			onChange && onChange(newValues);
		},
		[getItemId, onChange, value]
	)

	const isAllSelectedMemo = useMemo(
		() => filteredItemsMemo.every(x => value?.includes(getItemId(x))),
		[filteredItemsMemo, value, getItemId]
	)

	const selectAllCallback = useCallback(
		() => {
			const values = value || [];
			const filteredItemIds = filteredItemsMemo.map(x => getItemId(x));

			// remove filtered items
			let newValues = values.filter(x => !filteredItemIds.includes(x));

			// if filtered items are NOT already included, add all of them
			// otherwise, they are already removed
			if (!isAllSelectedMemo) {
				newValues = [...newValues, ...filteredItemIds]
			}
			onChange && onChange(newValues);
		},
		[getItemId, onChange, value, isAllSelectedMemo, filteredItemsMemo]
	)

	const onBlurCallback = useCallback(
		() => {
			onBlur && onBlur();
			setSearchValue(undefined);
			setExpanded(false);
			setKeyboardItem(undefined);
		},
		[onBlur]
	)

	const expandCallback = useCallback(
		() => !disabled && !loading && setExpanded(true),
		[disabled, loading]
	)

	const onKeyDownCallback = useCallback(
		(eventKey: string) => {
			if (!expanded) {
				return;
			}

			switch (eventKey) {
				case 'Enter':
					if (keyboardItem) {
						onChangeCallback(keyboardItem);
					}
					break;
				case 'ArrowUp':
					setKeyboardItem((state) => {
						const index = filteredItemsMemo.indexOf(state);

						if (index === -1) {
							return filteredItemsMemo.at(-1);
						} else {
							return filteredItemsMemo.at(index - 1);
						}
					})
					break;
				case 'ArrowDown':
					setKeyboardItem((state) => {
						const index = filteredItemsMemo.indexOf(state);

						if (index === filteredItemsMemo.length - 1) {
							return filteredItemsMemo.at(0);
						} else {
							return filteredItemsMemo.at(index + 1);
						}
					})
					break;
			}
		},
		[filteredItemsMemo, keyboardItem, onChangeCallback, expanded]
	)

	const optionsContent = useMemo(
		() => {
			return filteredItemsMemo.map((item) => {
				const id = getItemId(item);

				return (
					<Option
						key={getItemId(item)}
						item={item}
						getItemText={getItemText}
						onClick={onChangeCallback}
						isSelected={value?.includes(id)}
						isKeyboardItem={keyboardItem === item}
					/>
				)
			})
		},
		[filteredItemsMemo, getItemId, getItemText, onChangeCallback, value, keyboardItem]
	)

	const selectedContent = useMemo(
		() => {
			if (!value || value.length === 0) {
				return '';
			}

			if (value.length === items.length) {
				return 'All items are selected.'
			}

			if (value.length > numOfItemNames) {
				return `Selected ${value.length} items.`
			}

			if (items.length === 0) {
				return '';
			}

			const selectedItems = value.map(x => items.find(item => getItemId(item) === x))

			return selectedItems.map((x, i) => (
				<span>
					{i > 0 && ", "}
					<div className={styles.inline_block} key={getItemId(x)}>
						{isLink && getItemLinkRoute ? (
							<Link className={styles.link} to={getItemLinkRoute(x)} target='_blank'>
								{getItemText(x)}
							</Link>
						) : getItemText(x)}
					</div>
				</span>
			))
		},
		[isLink, value, items, numOfItemNames, getItemText, getItemId, getItemLinkRoute]
	)


	return (
		<div id={multiselectId} className={styles.container}>
			{expanded && <Cover onClick={onBlurCallback} transparent />}
			<div className={styles.select_container} onClick={expandCallback}>
				<div className={`${styles.select} ${expanded ? styles.focus : ''} ${disabled ? styles.disabled : ''}`}>
					{selectedContent}
				</div>
				{/* arrow */}
				<div className={styles.arrow}>
					<ArrowDownIcon width={8} height={8} fill='currentColor' />
				</div>
				{expanded && (
					<div className={`${styles.dropdown} ${styles.open}`}>
						<Input
							value={searchValue}
							onChange={setSearchValue}
							onKeyDown={onKeyDownCallback}
							placeholder='Search'
							disabled={disabled || loading}
							hideMaxLength
							focus
						/>
						{hasSelectAll && filteredItemsMemo.length > 0 && (
							<Option
								key={selectAllKey}
								item={null}
								getItemText={() => searchValue ? 'Select All (Filtered)' : 'Select All'}
								onClick={selectAllCallback}
								isSelected={isAllSelectedMemo}
							/>
						)}
						{optionsContent}
						{optionsContent.length === 0 && <div className={styles.no_options}>No options</div>}
					</div>
				)}

				{/* loading */}
				{loading &&
					<div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
						<Spinner>
							<ClipSpinner size={20} />
						</Spinner>
					</div>
				}
			</div>
		</div>
	)
}

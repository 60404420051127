import { InvoiceStatusClient, SimpleStatusRequestUpdateSimpleStatusRequestItems, InvoiceStatusResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils'
import store from 'base/reducer/store';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getInvoiceStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new InvoiceStatusClient();
	return client.getAll(tenantId);
}

export const updateInvoiceStatusesAction = (model: SimpleStatusRequestUpdateSimpleStatusRequestItems) => {
	const tenantId = getTenantId()!;
	const client = new InvoiceStatusClient();
	return client.update(tenantId, model);
}

export const persistInvoiceStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedInvoiceStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getInvoiceStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const INVOICE_STATUSES_SET = 'INVOICE_STATUSES_SET';
export const INVOICE_STATUSES_FETCHING = 'INVOICE_STATUSES_FETCHING';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: INVOICE_STATUSES_FETCHING,
		fetching
	}
}

const setAction = (items: InvoiceStatusResponse[]): PersistItemsActionType<InvoiceStatusResponse> =>  {
	return {
		type: INVOICE_STATUSES_SET,
		items
	}
}

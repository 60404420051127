import { ThemeEnum } from "./reducer";

const THEME = 'theme';

export const getThemeLocalStorage = (): ThemeEnum | undefined => {
	const value = localStorage.getItem(THEME);

	if (value) {
		return value as ThemeEnum;
	}

	return undefined;
}

export const setThemeLocalStorage = (theme: ThemeEnum) => {
	localStorage.setItem(THEME, theme);
}

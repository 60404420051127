import store from 'base/reducer/store';
import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { TimesheetsConfigResponse, TimeTravelConfigClient, UpdateTimesheetsConfigRequest } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getTimesheetsGeneralConfigAction = () => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.getTimesheetsConfig(tenantId);
}

export const updateTimesheetsGeneralConfigAction = (updateRequest: UpdateTimesheetsConfigRequest ) => {
	const tenantId = getTenantId()!;
	const client = new TimeTravelConfigClient();
	return client.updateTimesheetsConfig(tenantId, updateRequest);
}


export const persistTimesheetseneralConfigurationAction = async () => {
	const state = store.getState();

	if (state.persistedTimesheetsGeneralConfiguration.fetching) {
		return;
	}

	store.dispatch(setTimesheetsGeneralConfigurationFetchingAction(true));

	const response = await tryCatchJsonByAction(getTimesheetsGeneralConfigAction);
	if (response.success) {
		store.dispatch(setTimesheetsGeneralConfigurationAction(response.value || new TimesheetsConfigResponse()));
	} else {
		store.dispatch(setTimesheetsGeneralConfigurationFetchingAction(false));
	}
}

// redux

export const TIMESHEETS_GENERAL_CONFIGURATION_FETCHING = 'TIMESHEETS_GENERAL_CONFIGURATION_FETCHING';
export const TIMESHEETS_GENERAL_CONFIGURATION_SET = 'TIMESHEETS_GENERAL_CONFIGURATION_SET';

const setTimesheetsGeneralConfigurationFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TIMESHEETS_GENERAL_CONFIGURATION_FETCHING,
		fetching
	}
}

const setTimesheetsGeneralConfigurationAction = (value: TimesheetsConfigResponse): PersistValueActionType<TimesheetsConfigResponse> => {
	return {
		type: TIMESHEETS_GENERAL_CONFIGURATION_SET,
		value
	}
}

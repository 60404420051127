import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { PersistValueReducer } from 'features/Persist/reducerTypes';
import { CompanyResponseModel } from 'services/tenantManagementService';
import { COMPANY_INFO_FETCHING, COMPANY_INFO_SET } from './action';

export const companyInfoReducer = (state = new PersistValueReducer<CompanyResponseModel>(), action: PersistValueActionType<CompanyResponseModel> | FetchingActionType): PersistValueReducer<CompanyResponseModel> => {
	switch (action.type) {
		case COMPANY_INFO_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case COMPANY_INFO_SET:
			const { value } = action as PersistValueActionType<CompanyResponseModel>;

			return {
				value,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

import { is, color } from "@amcharts/amcharts4/core";

export const successColor = '#0f996d';
export const errorColor = '#f44336';
export const warningColor = '#ff9800'
// sve u svemu treba napraviti vise paleta
// posto perPriorityDataMemo recimo ima smisla da bude crvena ka narandzastoj ka zutoj

export const colorsPallete = [
	"#3d8eef",
	successColor,
	"#bdcf32",
	"#ede15b", // yellow
	warningColor, // "#edbf33",
	"#b33dc6",
	"#ef9b20",
	"#f46a9b",
	errorColor
]

export const colorsPalleteFire = [
	errorColor,
	'#ff7c1f', // '#ff5a00',
	'#ffab1a', // '#ff9a00',
	'#ffce00', // '#ffce00',
	'#ffe808'
]

const amchartCustomTheme = function (object) {
	if (is(object, "ColorSet")) {
        object.list = [
			color("#3d8eef"),
			color("#0f996d"),
			color("#bdcf32"),
			color("#ede15b"),
			color("#ff9800"), // #edbf33
			color("#b33dc6"),
			color("#ef9b20"),
			color("#f46a9b"),
			color("#f44336") // #ea5545
        ];
        object.reuse = true;
        object.stepOptions = {
            lightness: 0.1,
            hue: 0
        };
        object.passOptions = {};
    }
}

export default amchartCustomTheme;

import { TokenTypeEnum } from "services/tenantManagementService";
import { getIsUserProjectManager, getUserInfo } from "./storageUtils";

export const isUserPmorSubstitutePmOrSiteAdmin = (role?: TokenTypeEnum): boolean => {
	const userInfo = getUserInfo();

	return role === TokenTypeEnum.ProjectManager
		|| role === TokenTypeEnum.ProjectManagerSubstitute
		|| role === TokenTypeEnum.SiteAdmin
		|| userInfo.roleId === TokenTypeEnum.SiteAdmin;
}

export const isUserPmorSubstitutePm = (role?: TokenTypeEnum): boolean => {
	return role === TokenTypeEnum.ProjectManager
		|| role === TokenTypeEnum.ProjectManagerSubstitute;
}

export const isUserPMOrSiteAdmin = (): boolean => {
	const userInfo = getUserInfo();

	return userInfo.roleId === TokenTypeEnum.SiteAdmin || getIsUserProjectManager();
}

export const isUserSiteAdmin = (): boolean => {
	const userInfo = getUserInfo();

	return userInfo.roleId === TokenTypeEnum.SiteAdmin;
}

import { useCallback, useEffect, useMemo, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ControlsCommonProps } from "components/Form/fields";
import './assets/icomoon/style.css';
import styles from './textEditor.module.scss'

export const textEditorId = 'textEditorId';

// TextEditor (like Textarea) accepts Enter as a character,
// so this method is used in Form to prevent auto save of the form via Enter key
export const isTextEditorHit = (target: HTMLElement) => {
	return !!target.closest(`#${textEditorId}`);
}

const initialEditorState = (value: string | undefined) => {
	if (value) {
		const contentBlock = htmlToDraft(value);

		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			const editorState = EditorState.createWithContent(contentState);
			return editorState;
		}
	}
	return undefined;
}

export type TextEditorProps = ControlsCommonProps<string>

export const TextEditor = ({ value, onChange, onBlur, disabled }: TextEditorProps) => {
	const [editorState, setEditorState] = useState(() => initialEditorState(value));

	const onEditorStateChange = useCallback(
		(newEditorState) => {
			const html = draftToHtml(convertToRaw(newEditorState.getCurrentContent()));
			onChange && onChange(html)
			setEditorState(newEditorState)
		},
		[onChange]
	);

	useEffect(
		() => {
			if (value === undefined) {
				setEditorState(EditorState.createEmpty());
			}
		},
		[value]
	)

	const customToolbar = useMemo(
		() => {
			return {
				options: ['inline', 'textAlign', 'list', 'emoji', 'link'],
				inline: {
					options: ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript'],
					// className: styles.inline,
					bold: { icon: '', className: 'text_editor_bold' },
					italic: { icon: '', className: 'text_editor_italic' },
					underline: { icon: '', className: 'text_editor_underline' },
					strikethrough: { icon: '', className: 'text_editor_strikethrought' },
					superscript: { icon: '', className: 'text_editor_superscript' },
					subscript: { icon: '', className: 'text_editor_subscript' }
				},
				textAlign: {
					// className: styles.textAlign,
					left: { icon: '', className: 'text_editor_alignLeft'},
					center: { icon: '', className: 'text_editor_alignMiddle' },
					right: { icon: '', className: 'text_editor_alignRight' },
					justify: { icon: '', className: 'text_editor_justify' }
				},
				list: {
					// className: styles.list,
					unordered: { icon: '', className: 'text_editor_unordered' },
					ordered: { icon: '', className: 'text_editor_ordered' },
					indent: { icon: '', className: 'text_editor_indent' },
					outdent: { icon: '', className: 'text_editor_outdent' }
				},
				emoji: {
					icon: '',
					className: 'text_editor_emoji',
				},
				// disabled images in text editor
				// images are saved as base64 as content and loading is too slow
				// TODO: consider to save image to storage and create link
				// image: {
				// 	icon: '',
				// 	className: 'text_editor_image',
				// 	uploadCallback: imageUploadCallback,
				// 	previewImage: true,
				// },
				link: {
					options: ['link'],
					link: { icon: '', className: 'text_editor_link' },
					defaultTargetOption: '_blank',
				}
			}
		},
		[]
	)

	const classNameWrapper = `${styles.wrapper_editor} ${disabled ? styles.disabled : ''}`;
	const classNameEditor = `${styles.editor} ${disabled ? styles.disabled : ''}`;;

	return (
		<div id={textEditorId}>
			<Editor
				editorState={editorState}
				onEditorStateChange={onEditorStateChange}
				wrapperClassName={classNameWrapper}
				toolbarClassName={styles.toolbar}
				editorClassName={classNameEditor}
				onBlur={onBlur}
				disabled={disabled}
				readOnly={disabled}
				toolbarHidden={disabled}
				toolbar={customToolbar}
				stripPastedStyles
			/>
		</div>
	)
}

import { createRoot } from 'react-dom/client';
import { findFilter } from "../Filter/filtersHelper";
import { BaseColumnModel, FieldTypeEnum } from '../BaseColumnModel';
import { GenericSortModel } from 'services/tenantManagementService';
import { FilterModel } from "../Filter/Filter";
import { HeaderCell } from './HeaderCell';
import { CellComponent } from 'tabulator-tables';

export type TitleFormatterType = {
	column: BaseColumnModel
	onFilterClick: (field: string) => void
	onSortClick: (field: string) => void
	sort: GenericSortModel
	filters: FilterModel[]
}

// formatterParams is type any for TS not to complain
export const customTitleFormatter = (cell: CellComponent, formatterParams: any) => {
	const { sort, column, filters, onFilterClick, onSortClick } = formatterParams as TitleFormatterType;

	var container = document.createElement("div");
	// we need to add text and space for sort icon (paddingRight) here, so Tabulator can calculate widths
	container.innerHTML = cell.getValue();
	container.style.position = 'relative';
	container.style.paddingRight = '12px';

	const root = createRoot(container);
	root.render(
		<HeaderCell
			fieldId={column.field}
			text={cell.getValue()}
			hasFilter={!column.disableFilter && column.fieldType !== FieldTypeEnum.None}
			isFilterActive={findFilter(filters, column.dbFilterFieldPath) !== undefined}
			onFilterClick={onFilterClick}
			hasSort={!column.disableSort && column.fieldType !== FieldTypeEnum.None}
			isAsc={sort.property === column.dbFilterFieldPath && sort.isAsc}
			isDesc={sort.property === column.dbFilterFieldPath && !sort.isAsc}
			onSortClick={onSortClick}
		/>
	)

	return container;
}

import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import { PersistValueReducer } from 'features/Persist/reducerTypes';
import { DefaultProjectResponse } from 'services/tenantManagementService';
import { DEFAULT_PROJECT_FETCHING, DEFAULT_PROJECT_SET } from './action';

export const defaultProjectReducer = (state = new PersistValueReducer<DefaultProjectResponse>(), action: PersistValueActionType<DefaultProjectResponse> | FetchingActionType): PersistValueReducer<DefaultProjectResponse> => {
	switch (action.type) {
		case DEFAULT_PROJECT_FETCHING:
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		case DEFAULT_PROJECT_SET:
			const { value } = action as PersistValueActionType<DefaultProjectResponse>;

			return {
				value,
				fetching: false,
				isInitialized: true
			}
		default:
			return state;
	}
};

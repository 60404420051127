import { FetchingActionType, FetchingProjectItemsActionType, PersistItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';
import { PersistProjectItemsReducer } from 'features/Persist/reducerTypes';
import { ProjectIsActiveResponse } from 'services/tenantManagementService';
import { PROJECT_ROLES_ALL_SET, PROJECT_ROLES_FETCHING, PROJECT_ROLES_FETCHING_ALL, PROJECT_ROLES_SET } from './action';


export const projectRolesReducer = (state = new PersistProjectItemsReducer<ProjectIsActiveResponse>(), action: PersistProjectItemsActionType<ProjectIsActiveResponse> | PersistItemsActionType<ProjectIsActiveResponse> | FetchingProjectItemsActionType): PersistProjectItemsReducer<ProjectIsActiveResponse> => {
	switch (action.type) {
		case PROJECT_ROLES_FETCHING_ALL: {
			const { fetching } = action as FetchingActionType;

			return {
				...state,
				fetching
			}
		}
		case PROJECT_ROLES_FETCHING: {
			// fetching is only for this projectId
			const { fetching, projectId } = action as FetchingProjectItemsActionType;

			// isAnyFetching is checking if fetching for any project is in progress
			const isAnyFetching = fetching || !!Object.values(state.projectMap).find((item) => item?.fetching === true)

			return {
				...state,
				fetching: isAnyFetching,
				projectMap: {
					...state.projectMap,
					[projectId]: {
						items: state.projectMap[projectId]?.items || [],
						fetching
					}
				}
			}
		}
		case PROJECT_ROLES_SET:
			const { items, projectId } = action as PersistProjectItemsActionType<ProjectIsActiveResponse>;

			const itemsMap = {
				...state.itemsMap,
				...items.reduce(
					(map: { [id: number]: ProjectIsActiveResponse }, item) => {
						map[item.id] = item;
						return map;
					},
					{}
				)
			}

			const projectMap = {
				...state.projectMap,
				[projectId]: {
					fetching: false,
					items
				}
			}

			const filteredItems = state.items.filter(x => x.projectId !== projectId);
			const newItems = [...items, ...filteredItems];

			return {
				items: newItems,
				itemsMap,
				fetching: false,
				isInitialized: true,
				projectMap
			}
		case PROJECT_ROLES_ALL_SET:
			const itemAction = action as PersistItemsActionType<ProjectIsActiveResponse>;
			const allItems = itemAction.items;

			const allItemsMap = allItems.reduce(
				(map: { [id: number]: ProjectIsActiveResponse }, item) => {
					map[item.id] = item;
					return map;
				},
				{}
			);

			const allProjectMap = allItems.reduce(
				(map: typeof state.projectMap, item) => {
					if (!map[item.projectId]) {
						map[item.projectId] = {
							fetching: false,
							items: []
						};
					}
					map[item.projectId]!.items.push(item);
					return map;
				},
				{}
			);

			return {
				items: allItems,
				itemsMap: allItemsMap,
				fetching: false,
				isInitialized: true,
				projectMap: allProjectMap
			}
		default:
			return state;
	}
};

import { Subtract } from "utility-types";

export class MapItem {
	id!: number
	name!: string
	parentId?: number
	childrens: MapItem[] = []

	constructor(model: MapItem) {
		if (model) {
			this.id = model.id;
			this.name = model.name;
			this.parentId = model.parentId;
			this.childrens = model.childrens || [];
		}
	}
}

export const convertToMapItems = <P extends Subtract<MapItem, { childrens? }>>(originalItems: P[], childrens: MapItem[], parentId: number | undefined): MapItem[] => {
	for (const item of originalItems) {
		if (item.parentId === parentId) {
			childrens.push(new MapItem({
				...item,
				childrens: []
			}));
		}
	}

	for (const item of childrens) {
		item.childrens = convertToMapItems(originalItems, item.childrens, item.id);
	}

	return childrens;
}

export const getSelectedItem = (id: number | undefined, childrens: MapItem[]): MapItem | undefined => {
	if (!id) {
		return;
	}

	for (const item of childrens) {
		if (item.id === id) {
			return item;
		}

		const selected = getSelectedItem(id, item.childrens);
		if (selected) {
			return selected;
		}
	}

	return;
}

export const convertToAbsoluteName = (mapItem: MapItem, items: MapItem[]) => {
	for (const item of items) {
		if (item.id === mapItem.id) {
			return item.name;
		}

		const absoluteName = convertToAbsoluteName(mapItem, item.childrens);
		if (absoluteName) {
			return `${item.name} - ${absoluteName}`
		}
	}

	return;
}

const getChildren = (organizationalUnit: MapItem, organizationalUnitsMap: MapItem[]) => {
	if (!organizationalUnit) {
		return [];
	}

	let result: number[] = [];

	for (let i = 0; i < organizationalUnit.childrens.length; i++) {
		const child = organizationalUnit.childrens[i];
		result.push(child.id);

		result = [...result, ...getChildren(child, organizationalUnitsMap)];
	}

	return result;
}

export const getAllOrganizationalUnitIds = (organizationalUnitsMap: MapItem[], organizationalUnitIds: number[]) => {
	let result: number[] = [];

	for (let i = 0; i < organizationalUnitIds.length; i++) {
		const organizationalUnit = getSelectedItem(organizationalUnitIds[i], organizationalUnitsMap);
		if (!organizationalUnit) {
			continue;
		}
		//push selected
		result.push(organizationalUnit.id);

		result = [...result, ...getChildren(organizationalUnit, organizationalUnitsMap)];
	}

	return [...Array.from(new Set(result))];
}

import { TicketAssignedGroupDeltaRequest, TicketAssignedGroupsResponse, TicketConfigClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import store from 'base/reducer/store';

export const getTicketAssignedGroupsAction = () => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.getAssignedGroups(tenantId);
}

export const updateTicketAssignedGroupsAction = (ticketAssignedGroupsDelta: TicketAssignedGroupDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TicketConfigClient();
	return client.updateAssignedGroups(tenantId, ticketAssignedGroupsDelta);
}

export const persistTicketAssignedGroupsAction = async () => {
	const state = store.getState();

	if (state.persistedTicketAssignedGroup.fetching) {
		return;
	}

	store.dispatch(setTicketAssignedGroupsFetchingAction(true));

	const response = await tryCatchJsonByAction(getTicketAssignedGroupsAction);
	if (response.success) {
		store.dispatch(setTicketAssignedGroupsAction(response.items || []));
	} else {
		store.dispatch(setTicketAssignedGroupsFetchingAction(false));
	}
}

// redux

export const TICKET_ASSIGNED_GROUP_FETCHING = 'TICKET_ASSIGNED_GROUP_FETCHING';
export const TICKET_ASSIGNED_GROUP_SET = 'TICKET_ASSIGNED_GROUP_SET';

const setTicketAssignedGroupsFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TICKET_ASSIGNED_GROUP_FETCHING,
		fetching
	}
}

const setTicketAssignedGroupsAction = (items: TicketAssignedGroupsResponse[]): PersistItemsActionType<TicketAssignedGroupsResponse> =>  {
	return {
		type: TICKET_ASSIGNED_GROUP_SET,
		items
	}
}

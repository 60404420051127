import Button from 'components/Button';
import { FilterItems } from './FilterItems';
import { Shorten } from 'components/Shorten/Shorten';
import styles from './activeFilter.module.scss'
import { FilterModel } from '../Filter';
import { BaseColumnModel } from '../../BaseColumnModel';

type Props = {
	filters: FilterModel[]
	columns: BaseColumnModel[]
	onClearAll: () => void
}

export const ActiveFilter = ({filters, columns, onClearAll}: Props) => {
	return (
		<div className={styles.container}>
			<h3 className={styles.header}>Filter:</h3>
			<div className={styles.items}>
				<Shorten>
					<FilterItems
						filters={filters}
						columns={columns}
					/>
				</Shorten>
			</div>
			<div className={styles.button}>
				<Button
					text='Clear Filter'
					color='neutral'
					onClick={onClearAll}
				/>
			</div>
		</div>
	)
}

export enum PLACEMENT {
	TOP = 'top',
	BOTTOM = 'bottom',
	LEFT = 'left',
	RIGHT = 'right',
	CENTER = 'center'
};

export const POSITION = {
	TOP_LEFT: [PLACEMENT.TOP, PLACEMENT.LEFT],
	TOP_CENTER: [PLACEMENT.TOP, PLACEMENT.CENTER],
	TOP_RIGHT: [PLACEMENT.TOP, PLACEMENT.RIGHT],
	BOTTOM_LEFT: [PLACEMENT.BOTTOM, PLACEMENT.LEFT],
	BOTTOM_CENTER: [PLACEMENT.BOTTOM, PLACEMENT.CENTER],
	BOTTOM_RIGHT: [PLACEMENT.BOTTOM, PLACEMENT.RIGHT],
	LEFT_TOP: [PLACEMENT.LEFT, PLACEMENT.TOP],
	LEFT_CENTER: [PLACEMENT.LEFT, PLACEMENT.CENTER],
	LEFT_BOTTOM: [PLACEMENT.LEFT, PLACEMENT.BOTTOM],
	RIGHT_TOP: [PLACEMENT.RIGHT, PLACEMENT.TOP],
	RIGHT_CENTER: [PLACEMENT.RIGHT, PLACEMENT.CENTER],
	RIGHT_BOTTOM: [PLACEMENT.RIGHT, PLACEMENT.BOTTOM],
};

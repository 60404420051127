import { Subtract } from 'utility-types';
import { AutoComplete, AutoCompleteProps } from '../controls';
import { Field, FieldProps, ControlsCommonProps } from './Field';

export const AutoCompleteField = (props: Subtract<AutoCompleteProps, ControlsCommonProps<string | number | undefined>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={AutoComplete}
		/>
	)
}

import { DefaultProjectClient, DefaultProjectResponse, SetDefaultProjectRequest } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { getUserInfo } from 'utils/storageUtils';
import { FetchingActionType, PersistValueActionType } from 'features/Persist/actionTypes';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

// api

export const getDefaultProjectAction = () => {
	const tenantId = getTenantId()!;
	const userInfo = getUserInfo();
	const client = new DefaultProjectClient();
	return client.get(tenantId, userInfo.id);
}

export const setDefaultProjectAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const userInfo = getUserInfo();
	const model = new SetDefaultProjectRequest({
		userId: userInfo.id,
		projectId
	})

	const client = new DefaultProjectClient();
	return client.set(tenantId, model);
}

export const persistDefaultProjectAction = async () => {
	const state = store.getState();

	if (state.persistedDefaultProject.fetching) {
		return;
	}

	store.dispatch(setDefaultProjectFetchingAction(true));

	const response = await tryCatchJsonByAction(getDefaultProjectAction);
	if (response.success) {
		store.dispatch(setDefaultProjectReduxAction(response.value || new DefaultProjectResponse()));
	} else {
		store.dispatch(setDefaultProjectFetchingAction(false));
	}
}

// redux

export const DEFAULT_PROJECT_FETCHING = 'DEFAULT_PROJECT_FETCHING';
export const DEFAULT_PROJECT_SET = 'DEFAULT_PROJECT_SET';

const setDefaultProjectFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: DEFAULT_PROJECT_FETCHING,
		fetching
	}
}

export const setDefaultProjectReduxAction = (value: DefaultProjectResponse): PersistValueActionType<DefaultProjectResponse> => {
	return {
		type: DEFAULT_PROJECT_SET,
		value
	}
}

import { useMemo, useCallback } from 'react';
import { ErrorReportingErrorBoundary } from 'services/errorReportingService';
import ErrorBoundaryFallback from './ErrorBoundaryFallback';

type ErrorBoundaryProps = {
	children: any;
	fallback?: any;
	location?: string;
	fallbackProps?: any
}

const ErrorBoundary = ({ children, fallback = ErrorBoundaryFallback, location, fallbackProps }: ErrorBoundaryProps) => {

	const fallbackCallback = useCallback(
		(props: any) => {
			const FC = fallback;
			return (
				<FC {...fallbackProps} {...props}/>
			)
		},
		[fallback, fallbackProps]
	)

	const beforeCaptureMemo = useMemo(
		() => {
			if (!location) {
				return;
			}

			return (scope: any) => {
				scope.setTag('location', location);
			}
		},
		[location]
	)

	return (
		<ErrorReportingErrorBoundary
			fallback={fallbackCallback}
			beforeCapture={beforeCaptureMemo}
		>
			{children}
		</ErrorReportingErrorBoundary>
	)
}

export default ErrorBoundary;

import { useCallback } from 'react';
import styles from './radio.module.scss';

export type RadioItemType = {
	id: string | number | boolean
	text: string
	disabled?: boolean
}

type Props = RadioItemType & {
    isChecked?: boolean
    onClick?(id: string | number | boolean): void
	column?: boolean
}

export const RadioItem = ({ id, text, isChecked, onClick, disabled, column }: Props) => {
    const handleOptionChange = useCallback(
        () => {
			!disabled && !isChecked && onClick && onClick(id);
        },
        [id, isChecked, onClick, disabled]
	)

	const className = `${styles.item} ${disabled ? styles.disabled : ''} ${isChecked ? styles.checked : ''}`;

	return (
		<div className={className} onClick={handleOptionChange}>
			<span className={styles.circle} />
			<span className={styles.text}>{text}</span>
	  	</div>
	)
}

import moment from 'moment';
import { formatTimeWithoutAmOrPm, parseTimeStringToTicks } from 'utils/dateTimeUtils';

export const integerNumberEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
    const editor = document.createElement("input");

    editor.setAttribute("type", "number");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = cell.getValue();

    onRendered(function(){
        editor.focus();
    });

    function successFunc(){
		const parsedValue = parseInt(editor.value);
        success(parsedValue);
    }

    editor.addEventListener("change", successFunc);
    editor.addEventListener("blur", successFunc);

    //return the editor element
    return editor;
};

export const floatNumberEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
    const editor = document.createElement("input");

    editor.setAttribute("type", "number");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = cell.getValue();

    onRendered(function(){
        editor.focus();
		editor.style.height = "100%";
    });

    function successFunc(){
		const parsedValue = parseFloat(editor.value);
		if (parsedValue === 0) {
			success(0);
			return;
		}

		if (!parsedValue) {
			success(undefined);
			return;
		}

        success(parsedValue);
    }

    editor.addEventListener("change", successFunc);
    editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    //return the editor element
    return editor;
};

export const dateEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
    //cell - the cell component for the editable cell
    //onRendered - function to call when the editor has been rendered
    //success - function to call to pass the successfuly updated value to Tabulator
    //cancel - function to call to abort the edit and return to a normal cell
    //editorParams - params object passed into the editorParams column definition property

    //create and style editor
    const editor = document.createElement("input");

    editor.setAttribute("type", "date");

    //create and style input
    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    //Set value of editor to the current value of the cell
    editor.value = moment(cell.getValue(), "MM/DD/YYYY").format("YYYY-MM-DD")

    //set focus on the select box when the editor is selected (timeout allows for editor to be added to DOM)
    onRendered(function(){
        editor.focus();
		editor.style.height = "100%";
    });

    //when the value has been set, trigger the cell to update
    function successFunc(){
		if (!editor.value) {
			success(undefined);
			return;
		}
		const newDate = new Date(`${editor.value}T00:00:00`);
        success(newDate);
    }

    editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    //return the editor element
    return editor;
};

export const timeEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
    const editor = document.createElement("input");

    editor.setAttribute("type", "time");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = formatTimeWithoutAmOrPm(cell.getValue());

    onRendered(function(){
        editor.focus();
		editor.style.height = "100%";
    });

    function successFunc(){
		const parsedValue = parseTimeStringToTicks(editor.value);
		if (!parsedValue) {
			success(undefined);
			return;
		}
        success(parsedValue);
    }

    editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    //return the editor element
    return editor;
};

export const minutesDurationEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any){
    const editor = document.createElement("input");

    editor.setAttribute("type", "number");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = `${cell.getValue() / 10000 / 1000 / 60}`;

    onRendered(function(){
        editor.focus();
		editor.style.height = "100%";
    });

    function successFunc(){
		const parsedValue = parseInt(editor.value) * 10000 * 1000 * 60;
		if (!parsedValue) {
			success(undefined);
			return;
		}
        success(parsedValue);
    }

    editor.addEventListener("change", successFunc);
    editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    //return the editor element
    return editor;
};

export const inputTextEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any) {
    const editor = document.createElement("input");

    editor.setAttribute("type", "text");

    editor.style.padding = "3px";
    editor.style.width = "100%";
    editor.style.boxSizing = "border-box";

    editor.value = cell.getValue() || '';

    onRendered(function(){
		editor.focus();
		editor.style.height = "100%";
    });

    function successFunc(){
		if (!editor.value) {
			success(undefined);
			return;
		}
		success(editor.value);
    }

	editor.addEventListener("change", successFunc);
	editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				successFunc();
				break;
			default:
				return;
		}
	});

    return editor;
};

export const checkboxEditor = function(cell: any, onRendered: any, success: any, cancel: any, editorParams: any) {
	const editor = document.createElement("input");

	editor.setAttribute("type", "checkbox");

	editor.style.marginLeft = "8px";
	editor.style.boxSizing = "border-box";
	editor.style.cursor = "pointer";
	editor.style.scale = "1.2";

	editor.checked = cell.getValue();

	onRendered(function() {
		editor.focus();
		editor.style.height = "100%";
    });

	function successFunc() {
		success(editor.checked);
    }

	editor.addEventListener("change", successFunc);
	editor.addEventListener("blur", successFunc);

	editor.addEventListener("keydown", function(e) {
		switch (e.key) {
			case 'Tab':
				e.stopPropagation();
				break;
			case 'Escape':
				cancel();
				break;
			case 'Enter':
				success(!editor.checked);
				break;
			default:
				return;
		}
	});

    return editor;
};

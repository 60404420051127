import { useState, useCallback, useMemo } from 'react';
import ColorPickerOpener from '../Opener/ColorPickerOpener';
import colorPickerStyles from '../colorPicker.module.scss';
import styles from './simpleColorPicker.module.scss';
import Cover from '../Panel/Cover';
import { ControlsCommonProps } from '../../../fields';
import DefaultColors from '../Panel/DefaultColors';

export type SimpleColorPickerProps = ControlsCommonProps<string> & {
	colors?: string[]
}

export const SimpleColorPicker = (props: SimpleColorPickerProps) => {
	const {
		value, onChange,/* onBlur,*/ disabled,
		colors
	} = props;

	const [isOpened, setIsOpened] = useState(false);

	const onCoverClick = useCallback(
		() => setIsOpened(false),
		[]
	)

	const onOpenerClick = useCallback(
		() => {
			const newIsOpened = disabled ? false : !isOpened;
			setIsOpened(newIsOpened);
		},
		[isOpened, disabled]
	)

	const onChangeCompleteCallback = useCallback(
		(newColor) => {
			onChange && onChange(newColor);
			setIsOpened(false);
		},
		[onChange]
	);

	const wrapperClassName = useMemo(
		() => disabled ? colorPickerStyles.color_picker_wrapper + ' ' + colorPickerStyles.color_picker_disabled : colorPickerStyles.color_picker_wrapper,
		[disabled]
	);

	return (
		<div className={wrapperClassName}>
			<ColorPickerOpener color={value} onClick={onOpenerClick} />
			{isOpened &&
				<>
					<Cover onClick={onCoverClick} />
					<div className={styles.panel}>
						<DefaultColors
							colors={colors}
							onChange={onChangeCompleteCallback}
						/>
					</div>
				</>
			}
		</div>
	)
}

import { useEffect } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import lightThemeStyles from 'assets/styles/themes/light.module.scss';
import darkThemeStyles from 'assets/styles/themes/dark.module.scss';
import { RootState } from 'base/reducer/reducer';
import { useSelector } from 'react-redux';
import { ThemeEnum } from "features/Theme";
import amchartCustomTheme from './amchartCustomTheme.js'

type Props = {
	children: any
}

const ThemeProvider = ({ children }: Props) => {
	const theme = useSelector((state: RootState) => state.theme);

	document.body.classList.add(darkThemeStyles.theme);

	useEffect(
		() => {
			let className: string;

			switch (theme) {
				case ThemeEnum.Light:
					className = lightThemeStyles.theme;
					am4core.unuseAllThemes();
					am4core.useTheme(amchartCustomTheme);
					break;
				case ThemeEnum.Dark:
					className = darkThemeStyles.theme;
					am4core.unuseAllThemes();
					// for dark interface - lines, labels, etc.
					am4core.useTheme(am4themes_dark);
					// for now, the colors pallete
					am4core.useTheme(amchartCustomTheme);
					break;
				default:
					className = darkThemeStyles.theme;
			}

			// for animations
			am4core.useTheme(am4themes_animated);

			document.body.classList.remove(lightThemeStyles.theme, darkThemeStyles.theme);
			document.body.classList.add(className);
		},
		[theme]
	)

	return (
		<>
			{children}
		</>
	)
};

export default ThemeProvider;

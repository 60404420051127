import { ProjectConfigOrderDeltaRequest, ProjectIsActiveResponse, ScopeDepartmentClient } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingProjectItemsActionType, PersistProjectItemsActionType } from 'features/Persist/actionTypes';

export const getDepartmentsAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ScopeDepartmentClient();
	return client.getAll(tenantId, projectId);
}

export const updateDepartmentsAction = (projectId: number, delta: ProjectConfigOrderDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScopeDepartmentClient();
	return client.update(tenantId, projectId, delta);
}

export const persistDepartmentsAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedProjectTeam.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setDepartmentsFetchingAction(true, projectId));

	const bindedAction = getDepartmentsAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success && response.items) {
		store.dispatch(setDepartmentsAction(projectId, response.items || []));
	} else {
		store.dispatch(setDepartmentsFetchingAction(false, projectId));
	}
}

// persist departments redux

export const DEPARTMENTS_FETCHING = 'DEPARTMENTS_FETCHING';
export const DEPARTMENTS_SET = 'DEPARTMENTS_SET';

const setDepartmentsFetchingAction = (fetching: boolean, projectId: number): FetchingProjectItemsActionType => {
	return {
		type: DEPARTMENTS_FETCHING,
		fetching,
		projectId
	}
}

const setDepartmentsAction = (projectId: number, items: ProjectIsActiveResponse[]): PersistProjectItemsActionType<ProjectIsActiveResponse> => {
	return {
		type: DEPARTMENTS_SET,
		items,
		projectId
	}
}

import { Subtract } from 'utility-types';
import { TimePicker, TimePickerProps } from '../controls';
import { Field, ControlsCommonProps, FieldProps } from './Field';

export const TimeField = (props: Subtract<TimePickerProps, ControlsCommonProps<number | undefined>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={TimePicker}
		/>
	)
}

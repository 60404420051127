import { getItemBySemantic } from 'features/Semantics/semanticsHelper';
import { BaseResponseModel } from "services/tenantManagementService";

export class StatusResponse {
    id!: number;
    name!: string;
    description?: string | undefined;
    isActive!: boolean;
    isReadonly!: boolean;
    color!: string;
    isInitial!: boolean;
	nextStates?: number[] | undefined;
	semantics: any

    constructor(data?: StatusResponse) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (this as any)[property] = (data as any)[property];
            }
        }
	}
}

export class StatusResponseItemsResponseModel extends BaseResponseModel {
    items?: StatusResponse[] | undefined;
}

export const getInitialStatus = <P extends StatusResponse>(statuses: P[]) => {
	for (const status of statuses) {
		if (status.isInitial) {
			return status;
		}
	}
}

export const getNextStatuses = <P extends StatusResponse>(id: number, statuses: P[]) => {
	if (!id) {
		return [];
	}
	// find status model by id
	const status = statuses.find((item) => item.id === id);

	if (!status) {
		return [];
	}

	const nextStates = status.nextStates;

	if (nextStates && nextStates.length === 0) {
		// no next statuses
		return [status];
	} else if (!nextStates) {
		// all statuses are next statuses
		return statuses;
	} else {
		// there are next statuses
		const nextStatuses: P[] = [status];
		for (const nextStateId of nextStates) {
			const nextStatus = statuses.find((item) => item.id === nextStateId);
			nextStatus && nextStatuses.push(nextStatus);
		}
		return nextStatuses;
	}
}

export const getStatusBySemantic = <P extends StatusResponse>(semanticEnum: any, statuses: P[]) => {
	return getItemBySemantic(semanticEnum, statuses);
}

export const isNextStatus = <P extends StatusResponse>(semanticEnum: any, previousStatusId: number | undefined, statusesMap: { [id: string]: P | undefined }) => {
	if (!semanticEnum || !previousStatusId) {
		return false;
	}

	const previousStatus = statusesMap[previousStatusId];

	if (!previousStatus) {
		return false;
	}

	for (const nextState of previousStatus.nextStates || []) {
		const nextStatus = statusesMap[nextState];
		if (nextStatus && nextStatus.semantics === semanticEnum) {
			return true;
		}
	}

	return false;
}

export const isStatusBySemantic = <P extends StatusResponse>(semanticEnum: any, statusId: number | string | undefined, statusesMap: { [id: string]: P | undefined}) => {
	if (!semanticEnum || !statusId) {
		return false;
	}

	const status = statusesMap[statusId];

	if (!status) {
		return false;
	}

	return status.semantics === semanticEnum;
}

import store from 'base/reducer/store';
import { FetchingProjectValueActionType, PersistProjectValueActionType } from 'features/Persist/actionTypes';
import { getTenantId } from 'features/Tenant/tenantUtils';
import { ScheduleConfigurationClient, ScheduleConfigurationRequest, ScheduleConfigurationResponse } from 'services/tenantManagementService';
import { tryCatchJsonByAction } from 'utils/fetchUtils';

export const getScheduleConfigurationAction = (projectId: number) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleConfigurationClient();
	return client.get(tenantId, projectId);
}

export const updateScheduleConfigurationAction = (projectId: number, model: ScheduleConfigurationRequest) => {
	const tenantId = getTenantId()!;
	const client = new ScheduleConfigurationClient();
	return client.update(tenantId, projectId, model);
}

export const persistScheduleConfigurationAction = async (projectId: number) => {
	const state = store.getState();

	if (state.persistedScheduleConfiguration.projectMap[projectId]?.fetching) {
		return;
	}

	store.dispatch(setScheduleConfigurationFetchingAction(true, projectId));

	const bindedAction = getScheduleConfigurationAction.bind(null, projectId);
	const response = await tryCatchJsonByAction(bindedAction);

	if (response.success) {
		store.dispatch(setScheduleConfigurationAction(projectId, response.value || new ScheduleConfigurationResponse()));
	} else {
		store.dispatch(setScheduleConfigurationFetchingAction(false, projectId));
	}
}

// redux

export const SCHEDULE_CONFIGURATION_FETCHING = 'SCHEDULE_CONFIGURATION_FETCHING';
export const SCHEDULE_CONFIGURATION_SET = 'SCHEDULE_CONFIGURATION_SET';

const setScheduleConfigurationFetchingAction = (fetching: boolean, projectId: number): FetchingProjectValueActionType => {
	return {
		type: SCHEDULE_CONFIGURATION_FETCHING,
		fetching,
		projectId
	}
}

const setScheduleConfigurationAction = (projectId: number, value: ScheduleConfigurationResponse): PersistProjectValueActionType<ScheduleConfigurationResponse> => {
	return {
		type: SCHEDULE_CONFIGURATION_SET,
		value,
		projectId
	}
}

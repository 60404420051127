import { Subtract } from 'utility-types';
import { MapSinglePicker, MapSinglePickerProps } from '../controls';
import { Field, FieldProps, ControlsCommonProps } from './Field';

export const MapSingleField = (props: Subtract<MapSinglePickerProps, ControlsCommonProps<number | undefined>> & FieldProps) => {
	return (
		<Field
			{...props}
			Control={MapSinglePicker}
		/>
	)
}

import { StatusDeltaRequest, TaskStatusClient, TaskStatusResponse } from 'services/tenantManagementService';
import { getTenantId } from 'features/Tenant/tenantUtils';
import store from 'base/reducer/store';
import { tryCatchJsonByAction } from 'utils/fetchUtils';
import { FetchingActionType, PersistItemsActionType } from 'features/Persist/actionTypes';

// api

export const getTaskStatusesAction = () => {
	const tenantId = getTenantId()!;
	const client = new TaskStatusClient();
	return client.getAll(tenantId);
}

export const updateTaskStatusesAction = (model: StatusDeltaRequest) => {
	const tenantId = getTenantId()!;
	const client = new TaskStatusClient();
	return client.update(tenantId, model);
}

export const persistTaskStatusesAction = async () => {
	const state = store.getState();

	if (state.persistedTaskStatus.fetching) {
		return;
	}

	store.dispatch(setFetchingAction(true));

	const response = await tryCatchJsonByAction(getTaskStatusesAction);
	if (response.success) {
		store.dispatch(setAction(response.items || []));
	} else {
		store.dispatch(setFetchingAction(false));
	}
}

// redux

export const TASK_STATUS_FETCHING = 'TASK_STATUS_FETCHING';
export const TASK_STATUS_SET = 'TASK_STATUS_SET';

const setFetchingAction = (fetching: boolean): FetchingActionType => {
	return {
		type: TASK_STATUS_FETCHING,
		fetching
	}
}

const setAction = (items: TaskStatusResponse[]): PersistItemsActionType<TaskStatusResponse> =>  {
	return {
		type: TASK_STATUS_SET,
		items
	}
}
